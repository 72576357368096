.keywords-tags {
	width: 100%;
	line-height: 0;

  .bootstrap-tagsinput {
    background-color: #fff;
    border: 1px solid #5F6368;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    display: inline-block;
    margin-bottom: 15px;
    color: #555;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
    width: 100%;
  }
  .bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: 100%;
    height: 50px;
    max-width: inherit;
  }
  .bootstrap-tagsinput.form-control input::-moz-placeholder {
    color: #777;
    opacity: 1;
  }
  .bootstrap-tagsinput.form-control input:-ms-input-placeholder {
    color: #777;
  }
  .bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
    color: #777;
  }
  .bootstrap-tagsinput input:focus {
    border: none;
    box-shadow: none;
  }
  .bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: #000;
    margin-bottom: 5px;
    &.label {
      display: inline-flex;
      width: auto;
      padding: 0 5px;
      border-top: none;
      cursor: text;
      background-color: #009fff;
      border-radius: 10px;
      margin: 5px;
      color: white;
      font-weight: 600;
      padding-left: 10px;
    }
  }
  .bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
    opacity: 0;
  }
  .bootstrap-tagsinput .tag:hover [data-role="remove"] {
    opacity: 1;
  }
  .bootstrap-tagsinput .tag [data-role="remove"]:after {
    content: "x";
    padding: 0px 2px;
  }
  .bootstrap-tagsinput .tag [data-role="remove"]:hover {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  .tag {
    margin: 5px;
    color: #000;
    &.label {
      display: inline-flex;
      width: auto;
      padding: 5px 10px;
      border-top: none;
      cursor: text;
      border: 2px solid #009fff;
      color: #009fff;
      border-radius: 10px;
      margin: 5px;
      font-weight: 300;
    }
  }
}
