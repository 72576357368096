.rotated-card-ribbon {
  position: absolute;
  //background: #08769b;
  color: white;
  //box-shadow: 0 0 0 999px #08769b;
  clip-path: inset(0 -100%);
  font-size: 12px;
  text-transform: uppercase;
  inset: 0 auto auto 0;
  transform-origin: 100% 0;
  transform: translate(-29.3%) rotate(-45deg);
}
.rotated-card-ribbon.dark-red-ribbon { // hot
  background: #f50422;
  box-shadow: 0 0 0 999px #f50422;
}
.rotated-card-ribbon.orange-ribbon { // warm
  background: #e86224;
  box-shadow: 0 0 0 999px #e86224;
}
.rotated-card-ribbon.blue-ribbon { // cold
  background: #14adc6;
  box-shadow: 0 0 0 999px #14adc6;
}
.rotated-card-ribbon.default-ribbon-color {
  background: #c3c5c2;
  box-shadow: 0 0 0 999px #c3c5c2;
}

/*
  Modification: text and background are same. that's why text is not visible so change the text color to white
  By: Masum Raja
  Date: 29/03/2024
*/

.rotated-card-ribbon.dark-red-text-ribbon { // hot
  color: #fff;
}
.rotated-card-ribbon.orange-text-ribbon { // warm
  color: #fff;
}
.rotated-card-ribbon.blue-text-ribbon { // cold
  color: #fff;
}

/*
  Modification: Added new font size and top
  By: Masum Raja
  Date: 19/10/2023
*/

.folded-card-ribbon-container {
  .folded-card-ribbon {
      width: auto;
      height: 0;
      border-left: 14px solid transparent;
      font-size: 14px;
      padding: 0 5px;
      position: relative;

      .folded-card-ribbon-text {
        //color: white;
        display: flex;
        position: relative;
        top: -10px;
        padding-left: 1px;
        font-weight: 300;
      }

  }

  .folded-card-ribbon.red-ribbon { // adhaar
    border-bottom: 7px solid #d53d4f;
    border-top: 7px solid #d53d4f;
    color: #fff;
  }
  .folded-card-ribbon.yellow-ribbon { // lead
    border-bottom: 7px solid #facc28;
    border-top: 7px solid #facc28;
    color: #000;
  }
  /*
    Modification: change width 75 to 95
    by: Masum Raja
    Date: 17/11/2023
  */
  .folded-card-ribbon.yellow-2-ribbon { // lead
    border-bottom: 8px solid #FBC505;
    border-top: 8px solid #FBC505;
    color: #000;
    width: 95px;
    .folded-card-ribbon-text {
      font-weight: 500!important;
    }
  }
  .folded-card-ribbon.light-brown-ribbon { // rto
    border-bottom: 7px solid #753638
    border-top: 7px solid #753638;
    color: #fff;
  }
  .folded-card-ribbon.green-ribbon {
    border-bottom: 7px solid #76b947;
    border-top: 7px solid #76b947;
    color: #fff;
  }
  .folded-card-ribbon.orange-ribbon {
    border-bottom: 7px solid #e86224;
    border-top: 7px solid #e86224;
    color: #fff;
  }
  .folded-card-ribbon.default-ribbon-color {
    border-bottom: 7px solid #c3c5c2;
    border-top: 7px solid #c3c5c2;
    color: #fff;
  }
  .folded-card-ribbon.dark-red-ribbon {
    border-bottom: 7px solid #f50422;
    border-top: 7px solid #f50422;
    color: #fff;
  }
}
.folded-card-ribbon-container.primary-ribbon {
  top: 0;
  right:0;
  z-index: 1;
}
.folded-card-ribbon-container.primary-case-ribbon {
  top: 4px;
  right:0;
  z-index: 1;
}
.folded-card-ribbon-container.primary-task-ribbon {
  top: 16px;
  right:8px;
  z-index: 1;
}
.folded-card-ribbon-container.secondary-ribbon {
  top: 17px;
  right:0;
  z-index: 1;
}
.folded-card-ribbon-container.tertiary-ribbon {
  top: 34px;
  right:0;
  z-index: 1;
}
.folded-card-ribbon-container.bottom-right-ribbon {
  bottom: 0;
  right:0;
  z-index: 1;
}

.rotated-sm-card-ribbon {
  position: absolute;
  //background: #08769b;
  color: #fff;
  //box-shadow: 0 0 0 999px #08769b;
  clip-path: inset(0 -100%);
  font-size: 8px;
  text-transform: uppercase;
  inset: 0 auto auto 0;
  transform-origin: 100% 0;
  transform: translate(-2.3%) rotate(-45deg);
}

.rotated-sm-card-ribbon.dark-red-ribbon { // hot
  background: #f50422;
  box-shadow: 0 0 0 999px #f50422;
}

.rotated-sm-card-ribbon.dark-red-text-ribbon { // hot
  color: #f50422;
}
/*
  Modification: Added new color for ribbon
  By: Masum Raja
  Date: 05/02/2024
*/

.folded-card-ribbon.blue-ribbon { // adhaar
  border-bottom: 7px solid #88b7d2;
  border-top: 7px solid #88b7d2;
  color: #fff;
}
