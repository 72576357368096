.cu-dependencies__tasks-container.waiting {
  border: 1px solid #ffcc0033;
  border-left: 2px solid #ffcc00;
  background: #ffcc000d;
}
.cu-dependencies__tasks-container.block {
  border: 1px solid #f74d4b33;
  border-left: 2px solid #f74d4b;
  background: #f74d4b0d;
}

.cu-dependencies__tasks-container {
  padding: 20px;
  margin-bottom: 10px;
}

.cu-dependencies__tasks-name {
  color: var(--text-black);
  font-size: 14px;
  padding-top: 10px;
  padding-left: 10px;
}

.cu-dependencies__tasks-container.waiting .cu-dependencies__tasks-container-title {
  color: #ffcc00;
}
.cu-dependencies__tasks-container.block .cu-dependencies__tasks-container-title {
  color: #f74d4b;
}
.cu-dependencies__tasks-container-title.task-num {
  margin-bottom: 10px;
}

.cu-dependencies__tasks-container-title {
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: #343434;
  display: flex;
  align-items: center;
}
.cu-dependencies__tasks-container.waiting .cu-dependencies__tasks-container-title-icon {
  fill: #ffcc00;
}
.cu-dependencies__tasks-container.block .cu-dependencies__tasks-container-title-icon {
  fill: #f74d4b;
}
.cu-dependencies__tasks-container-title-icon {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  //width: 100%;
  //height: 100%;
}
.icon svg {
  display: block;
  width: 100%;
  height: 100%;
}
.cu-dependencies__tasks-container-description {
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  color: #343434;
  font-style: italic;
  margin-bottom: 10px;
  opacity: .4;
}
