.rocket-loader { // Moving particles
  animation: moveParticles 6s linear infinite;
  background: linear-gradient(90deg, gray, transparent 10%) 0 20%/180% 0.2rem repeat-x, linear-gradient(90deg, gray, transparent 20%) 0 80%/150% 0.2rem repeat-x, linear-gradient(90deg, gray, transparent 5%) 0 65%/100% 0.2rem repeat-x, linear-gradient(90deg, gray, transparent 5%) 0 40%/220% 0.2rem repeat-x, linear-gradient(0, white, white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  box-shadow: inset 0 0 60px 0 rgba(0, 0, 0, 0.1);
  height: 125px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 125px;

  &::before {
    animation: blink 1s infinite;
    bottom: 6%;
    content: "Loading...";
    font-size: 12px;
    left: 0;
    position: absolute;
    right: 0;
  }
}

@keyframes moveParticles {
  100% {
    background-position-x: -500rem;
  }
}

.rocket {
  animation: moveRocket 2s linear infinite;
  background: lightgray;
  background: linear-gradient(darken(red, 20), red, darken(red, 20));
  border-left: 3px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%/30%;
  height: 15%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 35%;

  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before { // Rocket fin
    animation: rotateFins 1s infinite;
    background: darken(lightgray, 10);
    background: linear-gradient(darken(red, 20), red, darken(red, 20));
    border: 2px solid transparent;
    border-radius: 0 50% 50% 0;
    height: 140%;
    top: 50%;
    transform: translate(0, -50%);
    left: 6px;
    width: 20%;
  }

  &::after { // Rocket tip
    border: 7px solid transparent;
    border-left: 14px solid rgba(0, 0, 0, 0.4);
    border-radius: 15%;
    right: -16px;
    top: 2px;
  }

  &-extras { // Rocket body mark
    animation: moveExtras 1s infinite;
    background: rgba(0, 0, 0, 0.4);
    height: 2px;
    left: 12px;
    margin: -2px 0 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 10px;

    &::before,
    &::after {
      content: "";
      position: absolute;
    }

    &::before { // Rocket eye
      background: white;
      border-radius: 50%;
      height: 5px;
      right: -7px;
      top: -1px;
      width: 5px;
    }

    &::after { // Rocket 3rd small fin
      background: darken(red, 10);
      border-top: 1px solid darken(red, 30);
      height: 1px;
      left: -10px;
      top: 1px;
      width: 6px;
    }
  }
}

@keyframes moveRocket {
  0%, 100% {
    transform: translate(-50%, calc(-50% - 1rem));
  }

  50% {
    transform: translate(-50%, calc(-50% + 1rem));
  }
}

@keyframes rotateFins {
  0%, 100% {
    height: 140%;
  }

  50% {
    border-top: 2px solid darken(red, 30);
    border-bottom: 2px solid darken(red, 30);
    height: 110%;
  }
}

@keyframes moveExtras {
  0%, 100% {
    transform: translate(0, calc(-50% + 0.1rem));
  }

  50% {
    transform: translate(0, calc(-50% - 0.1rem));
  }
}

.jet {
  height: 10px;
  left: -10px;
  position: absolute;
  top: calc(50% - 5px);
  width: 10px;

  &::before, &::after, span {
    animation: moveSmoke 0.3s infinite;
    background: darken(orange, 6);
    border-radius: 50%;
    content: "";
    filter: blur(2px);
    height: 8px;
    left: -6px;
    opacity: 1;
    position: absolute;
    transform: translate(0, 0) scale(1);
    top: 1px;
    width: 15px;
  }

  &::after {
    animation-delay: 0.1s;
  }

  span {
    animation-delay: 0.2s;
  }
}

@keyframes moveSmoke {
  100% {
    filter: blur(3px);
    opacity: 0;
    transform: translate(-40px, 0) scale(2);
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}


.CarLoading
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%,-50%) scale(2)
  svg
    polyline
      fill: none
      stroke-width: 2
      stroke-linecap: round
      stroke-linejoin: round
      &#back
        stroke: rgba(#6E7BF2,.3)
      &#front
        stroke: #6E7BF2
        stroke-dasharray: 12, 36 //Dash 12 & Gap 36
        stroke-dashoffset: 48
        animation: dash 1s linear infinite

@keyframes dash
  62.5%
    opacity: 0
  to
    stroke-dashoffset: 0

.carLoader {
  position: absolute
  top: 25%
  left: 30%
  .text {
    display: flex;
    justify-content: center;
    margin-top: -65px;
    margin-left: 13px;
    font-size: 1.2rem;
    color: #2E8994;
  }
  .img {
    display: block;-webkit-user-select: none;
    margin: auto;
    width: 70%;
  }
}
