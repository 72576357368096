/* Begin callingFloat */

.callingFloat {
  //position: fixed;
  //bottom: 50px;
  //left: 8px;
  text-align: center;
  z-index: 1000;
  height: 50px;
}

.callingFloat .trigger {
  border-radius:50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  position:absolute;
  background:rgb(0, 57, 132);
  color:#fff;
  height:40px;
  width:40px;
  vertical-align:middle;
  animation: 1.5s linear 0s infinite normal pulse;
  transition: ease-in-out 0.2s;
  cursor: pointer;
  left: 113px;
}

.callingFloat .trigger:hover{
  transform:scale(1.1);
}

.callingFloat.open .trigger .fa::before{
  content: "\f00d";
}

.callingFloat .trigger .fa::after{
  transition: ease-in-out 1s;
}

.fab.open .trigger i{
  transition: all 0.4s ease;
  transform: translateY(5px) rotate(360deg);
}

.callingFloat i{
  //font-size: 15px;
  //line-height: 61px;
  //margin-left: 15px;
  //-ms-transform: skewX(18deg);
  //transform: skewX(18deg);
  font-size: 15px;
  z-index: 99999;
  position: absolute;
  right: 4px;
  bottom: 1px;
  background-color: rgb(0 0 0 / 73%);
  padding: 3px;
  border-radius: 22px;
  width: 19px;
  height: 19px;
}

.callingFloat .container {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 0;
  border-radius: 50%;
  background: #faebd70a;
}

.callingFloat .textSpan {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  top: 9px;
  text-align: center;
}

.callingFloat .avatarImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.callingFloat .actions{
  opacity:0;
  transition: opacity 0.4s ease;
}

.callingFloat.open .actions{
  opacity:1;
}

.callingFloat .p-info{
  position: absolute;
  opacity:0;
  transition: transform 0.4s ease;
  transition: all 0.4s ease;
  transform: translateY(-156px);
  right:60px;
  width:100%;
  height:auto;
}

.callingFloat.open .p-info{
  opacity:1;
  position: absolute;
  transition: transform 0.4s ease;
  transition: all 0.4s ease;
  transform: translateY(-186px);
  right:60px;
  width:40px;
  height:40px;

}

.callingFloat .p-phone a{
  position: absolute;
  top: 0;
  background: #fff;
  color: #333;
  height: 60px;
  width: 60px;
  line-height: 40px;
  transition: transform 0.4s ease;
  transform: translateY(50px);
  transition: all 0.4s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  right:-50px;
  opacity:0
  transition-delay:0.2s;
}

.callingFloat.open .p-phone a{
  position: absolute;
  top: 0;
  background: #fff;
  color: #333;
  height: 60px;
  width: 60px;
  line-height: 40px;
  transition: transform 0.4s ease;
  transform: translateY(50px);
  transition: all 0.4s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  right:10px;
  transition-delay:0.2s;
}

.callingFloat.open .p-phone a:hover{
  background:#25d366;
  color:white;
}

.callingFloat .p-whatsapp a{
  position: absolute;
  opacity:0;
  background: #fff;
  color: #333;
  height: 60px;
  width: 60px;
  line-height: 40px;
  transition: transform 0.4s ease;
  right: 80px;
  transform: translateY(50px);
  transition: all 0.4s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  right:-50px;
  transition-delay:0.3s;
  transform-origin: right center 0;
}

.callingFloat.open .p-whatsapp a{
  position: absolute;
  top: 0;
  opacity:1;
  background: #fff;
  color: #333;
  height: 60px;
  width: 60px;
  line-height: 40px;
  transition: transform 0.4s ease;
  right: 80px;
  transform: translateY(50px);
  transition: all 0.4s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  transition-delay:0.2s;
}

.callingFloat.open .p-whatsapp a:hover{
  background:#25d366;
  color:white;

}

.callingFloat .p-image{
  top:100px;
  position: absolute;
  right:-65px;
  transition: all 0.4s ease;
  transition: transform 0.4s ease;
  border-radius:50%;
}

.callingFloat.open .p-image{

}

.callingFloat .p-image img{
  top:0;
  position: absolute;
  right:3px;
  transition: all 0.4s ease;
  border-radius:50%;
  transform: translateY(20px);
}

.callingFloat.open .p-image img{
  top:0;
  position: absolute;
  right:3px;
  transition: all 0.4s ease;
  border-radius:50%;
  transform: translateY(-50px);
}

.callingFloat .p-tooltip {
  position: absolute;
  right:0;
  transition-delay:0.3s;
  transform-origin: right center 0;
  transition: all 0.8s ease;
  transform: translateY(50%) scale(0);
  top: 0;
  right: -50px;
  width: 25vw;
  line-height:5px;
  border-radius:25px;
}

.callingFloat.open .p-tooltip{
  right:155px;
  font-size: 16px;
  line-height:5px;
  opacity:1;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width:25vw;
  height:55px;
  border-radius:25px;
  transform:translateY(52px);
   transition-delay:0.3s;
}

.callingFloat .p-tooltip p{
  font-size:16px;
  font-weight:bold;
}

.callingFloat.open .p-tooltip p{
  font-size:16px;
  font-weight:bold;
}



.callingFloat .actions .p-info:nth-child(1){
 transform: translateY(-190px)
}

.callingFloat .actions .p-info:nth-child(2){
 transform: translateY(-260px)
}

.callingFloat .actions .p-info:nth-child(3){
 transform: translateY(-330px)
}

.callingFloat .actions .p-info:nth-child(4){
 transform: translateY(-400px)
}

.callingFloat .actions .p-info:nth-child(5){
 transform: translateY(-470px)
}

.callingFloat .actions .p-info:nth-child(6){
 transform: translateY(-540px)
}

/* Animations */
@keyframes pulse {
    0% {
      box-shadow: 0px 1px 0px 3px #ab1a1a30, 0px 0px 0px 0px #ef242450;
    }
    30% {
      box-shadow: 0px 1px 0px 3px #ab1a1a30, 0px 0px 0px 5px #ef242450;
    }
    70% {
      box-shadow: 0px 1px 0px 3px #ab1a1a30, 0px 0px 0px 15px #ef242410;
    }
    100% {
      box-shadow: 1px 1px 2px 1px #ab1a1a30, 0px 0px 0px 20px transparent;
    }
  }
