.search {
    width: 100%;
    position: relative;
    display: flex;
}

.searchTerm {
    width: 100%;
    border: 3px solid #00B4CC;
    border-right: none;
    padding: 5px;
    height: 20px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: grey;
}

.searchTerm:focus{
  color: #00B4CC;
}

.searchButton {
    width: 36px;
    height: 36px;
    border: 1px solid #00B4CC;
    background: #00B4CC;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
    width: 100%;
    //position: relative;
    //margin:0 auto;
    //width: 192px;
    //z-index: 99999;
    background: aliceblue;
    //position: absolute;
}
.grey-on-hover {
  position: relative;
  cursor: pointer;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #c3c3c387;
    border-radius: 3px;
    height: 100%;
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 2;
    margin-left: -6px;
  }

  &:hover:before {
    opacity: 0.3;
    padding:10px;
  }
}

/*Styling Selectbox*/
.dropdown-filter-container {
  max-height: 20vh;
  overflow: auto;
  width: 100%;
  padding: 0;
}
.dropdown-menu {
    //position: absolute;
    background-color: #fff;
    width: 100%;
    left: 0;
    margin-top: 1px;
    box-shadow: 0 1px 2px rgb(204, 204, 204);
    border-radius: 0 1px 2px 2px;
    overflow: hidden;
    display: none;
    max-height: 144px;
    overflow-y: auto;
    z-index: 9
}
.dropdown-menu span:first-child {
    padding: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer
}
.dropdown .dropdown-menu span:first-child:hover {
    background-color: #f2f2f2
}
.dropdown .dropdown-menu span:first-child:active {
    background-color: #e2e2e2
}
