/*
  BY: Prasannadatta Kawadkar
  ON: 13 March 2024
  Modification: color changed for the leftInfo Panel and Right Panel
*/
.rightPanel-tab {
  width: 50px;
  //border-left: 1px solid #e2e2e2;
  //background: linear-gradient(180deg, #002C5F 0%, #002C5F 24.81%, #1275B0 100%);
  background: #04364A;
  height: 100vh;
}

.call-notify-span {
  //display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  position: absolute;
  background-color: #3cad1c;
  top: 6px;
  right: 0px;
  z-index: 1;
  border: 1px solid #fffc;
}

.call-notify-span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  //z-index: -1;
  animation: ripple 1.5s ease-out infinite;
  animation-delay: 0.2s;
  top: 0px;
  right: 0px;
}

.rightPanel-menu-active {
  background-color: rgba(255,255,255,0.722) !important;
}

.mobile-rightPanel-tab {
  width: 50px;
  //border-left: 1px solid #e2e2e2;
  background: #002C5F;
  height: 100vh;
}



@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}


/* [Media Queries]
--------------------------------------------------------------------------------
*/

@media (max-width:428px){
	/* smartphones, iPhone, portrait 480x320 phones */
    .rightPanel-tab{
      //display:none;
    }
}

/* modified by Vipin
   modified at 07/11/2022
   modification : Hide Right Navigation Panel on mobile devices(width below 428px)
*/
