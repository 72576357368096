.breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumbs li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  float: left;
  letter-spacing: 1px;
  font-size: 13px;
  //text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 20px;
  color: #000;
}

.breadcrumbs li a {
  display: block;
  padding: 0 25px 0 0px;
  color: #808080;
  text-decoration: none;
  height: 20px;
  position: relative;
  perspective: 700px;
}

.breadcrumbs li a:after {
  content: '';
  width: 10px;
  height: 10px;
  border-color: #808080;
  border-style: solid;
  border-width: 1px 1px 0 0;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  position: absolute;
  //right: 10px;
  left: -13px;
  top: 5px;
  -webkit-transition: all .15s ease;
  -moz-transition: all .15s ease;
  -ms-transition: all .15s ease;
  transition: all .15s ease;
  -webkit-transform: rotateZ(225deg) skew(10deg, 10deg);
  -moz-transform: rotateZ(225deg) skew(10deg, 10deg);
  -ms-transform: rotateZ(225deg) skew(10deg, 10deg);
  transform: rotateZ(225deg) skew(10deg, 10deg);
}


.breadcrumbs li a:hover:after {
  right: 15px;
  -webkit-transform: rotateZ(225deg) skew(-10deg, -10deg);
  -moz-transform: rotateZ(225deg) skew(-10deg, -10deg);
  -ms-transform: rotateZ(225deg) skew(-10deg, -10deg);
  transform: rotateZ(225deg) skew(-10deg, -10deg);
}

.breadcrumbs li.active {
  color: #808080;
}



.arrows { white-space: nowrap; }
.arrows li {
  display: inline-block;
  line-height: 26px;
  margin: 0 9px 0 -10px;
  padding: 0 20px;
  position: relative;
}
.arrows li::before,
  .arrows li::after {
    border-right: 1px solid #666666;
    content: '';
    display: block;
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    transform: skewX(-45deg);
}
.arrows li::before {
  bottom: 0;
  top: auto;
  transform: skewX(45deg);
}

.arrows li:last-of-type::before,
  .arrows li:last-of-type::after {
    display: none;
}

.arrows li a {
  letter-spacing: -1px;
  text-decoration: none;
}

.arrows li:nth-of-type(1) a { color: hsl(0, 0%, 70%); }
.arrows li:nth-of-type(2) a { color: hsl(0, 0%, 65%); }
.arrows li:nth-of-type(3) a { color: hsl(0, 0%, 50%); }
.arrows li:nth-of-type(4) a { color: hsl(0, 0%, 45%); }


.breadrcrumb_arrows {
  white-space: nowrap;
  width: max-content;
  background: #fff;
  border-radius: 5px;
}
.breadrcrumb_arrows li {
  display: inline-block;
  line-height: 26px;
  margin: 0 0 0 -10px;
  padding: 0 20px;
  position: relative;
}
.breadrcrumb_arrows li::before,
  .breadrcrumb_arrows li::after {
    border-right: 1px solid #666666;
    content: '';
    display: block;
    height: 20%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    //z-index: -1;
    transform: skewX(-45deg);
    margin: 8px;
}
.breadrcrumb_arrows li::before {
  bottom: 0;
  top: auto;
  transform: skewX(45deg);
}

.breadrcrumb_arrows li:last-of-type::before,
  .breadrcrumb_arrows li:last-of-type::after {
    display: none;
}

.breadrcrumb_arrows li a {
  letter-spacing: -1px;
  text-decoration: none;
}

.breadrcrumb_arrows li:nth-of-type(1) a { color: hsl(0, 0%, 70%); }
.breadrcrumb_arrows li:nth-of-type(2) a { color: hsl(0, 0%, 65%); }
.breadrcrumb_arrows li:nth-of-type(3) a { color: hsl(0, 0%, 50%); }
.breadrcrumb_arrows li:nth-of-type(4) a { color: hsl(0, 0%, 45%); }


// Below classes for inverted arrows
.breadrcrumb_arrows_inverted {
  white-space: nowrap;
  width: max-content;
  background: #fff;
  border-radius: 5px;
}
.breadrcrumb_arrows_inverted li {
  display: inline-block;
  line-height: 26px;
  margin: 0 0 0 -10px;
  padding: 0 20px;
  position: relative;
}
.breadrcrumb_arrows_inverted li::before,
  .breadrcrumb_arrows_inverted li::after {
    border-right: 1px solid #666666;
    content: '';
    display: block;
    height: 20%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    //z-index: -1;
    transform: skewX(45deg);
    margin: 8px;
}
.breadrcrumb_arrows_inverted li::before {
  bottom: 0;
  top: auto;
  transform: skewX(-45deg);
}

.breadrcrumb_arrows_inverted li:last-of-type::before,
  .breadrcrumb_arrows_inverted li:last-of-type::after {
    display: none;
}

.breadrcrumb_arrows_inverted li a {
  letter-spacing: -1px;
  text-decoration: none;
}

.breadrcrumb_arrows_inverted li:nth-of-type(1) a { color: hsl(0, 0%, 70%); }
.breadrcrumb_arrows_inverted li:nth-of-type(2) a { color: hsl(0, 0%, 65%); }
.breadrcrumb_arrows_inverted li:nth-of-type(3) a { color: hsl(0, 0%, 50%); }
.breadrcrumb_arrows_inverted li:nth-of-type(4) a { color: hsl(0, 0%, 45%); }
