{/*
	Modified By: Vihang
	Modified On: 3 Feb 2022
	Modification: background image added on authentication pages
*/}
.login-container {
  //background: #f9faff;
  // background: #f0f8ff;
  background: url(/assets/images/loginbg.jpg) no-repeat center;
  background-size: cover;
  color: #3a3c47;
  line-height: 1.6;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100vh;
  margin: 0 auto;
  padding: 0;

  h1 {
    //margin-top: 48px;
    font-family: 'CenturygothicRegular', sans-serif;
    font-size: 1.8rem;
    line-height: 1.25;
    font-weight: 600;
    color: #123d52;
    padding: 0 0 4px 0;
  }
	.auth-container-form-overlay-transition {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(255,255,255,0.6);
	}
	.loader-bar {
		height: 4px;
		width: 170px;
		top: 0;
		animation: marquee 0.4s linear infinite;
		position: absolute;
		background: #003468;
		z-index: 1;

		opacity: 1;
		right: 0;
		/*&.no-color {
			opacity: 0;
		}
		&.start {
			right: 0;
			opacity: 1;
		}*/
	}
	@keyframes marquee {
	  0% {
	    left: -170px;
	  }
		//25% {
	  //  left: 200px;
	  //}
		//50% {
	  //  left: 100px;
	  //}
		//75% {
	  //  left: 0px;
	  //}
	  100% {
	    left: 340px;
	  }
	}

	.welcome-block {
		width: 100%;
		min-height: 70px;
		display: flex;
		align-items: flex-end;
		position: relative;
		margin-bottom: 15px;
		line-height: 1.2;
		font-size: 10px;
		p {
			position: relative;
			font-size: 16px;
			color: #000;
			//font-family: 'IBMPlexSansBold', sans-serif;
			font-weight: 600;
		}
	}
	.description-block {
		width: 100%;
		min-height: 55px;
		margin-bottom: 10px;
		p {
			font-size: 12px;
			color: #000;
			//font-family: 'PTSerif', sans-serif;
			line-height: 1.3;
		}
	}

	.input-field {
		margin-bottom: 20px;
		position: relative;
		input {
			height: 44px;
			padding-left: 30px;
			padding-right: 34px;
			//border: none;
			//border: 1px solid #d3d3d3;
			background-position: 86% 69% !important;
			&::placeholder {
				color: #4c4c4c;
				font-size: 12px;
			}
			&:active , &:focus {
				//border: 2px solid #000;
				&::placeholder {
					//color: transparent;
				}
			}
		}
		.email-green-check {
			width: 15px;
			position: absolute;
			top: 16px;
			right: 10px;
		}
		.email-svg-input {
			width: 14px;
			position: absolute;
			left: 0;
			top: 16px;
		}
		.lock-svg-input {
			width: 11px;
			position: absolute;
			left: 0;
			top: 16px;
			&.confirm-password {
				top: 55px !important;
			}
		}

		.open-eye-svg-login {
			width: 15px;
			position: absolute;
			top: 16px !important;
			right: 12px;
			height: 15px;
			//top: 18px;
			//&.center-eye {
			//	top:15px !important;
			//}
			&.confirm-password {
				top: 59px !important;
			}
		}
		.unseen-eye-svg-login {
			width: 15px;
			position: absolute;
			top: 16px !important;
			right: 12px;
			height: 15px;
			//&.center-eye {
				//top: 8 px !important;
			//}
			&.confirm-password {
				top: 59px !important;
			}
		}
		svg.email-svg-input-focus {
			width: 15px;
			position: absolute;
			left: 8px;
			top: 18px;
			transform: scale(1.2);
		}
		svg.lock-svg-input-focus {
			width: 11px;
			position: absolute;
			left: 10px;
			top: 16px;
			transform: scale(1.3);
			&.center-lock {
				top: 17px !important;
			}
			&.confirm-password-focus {
				top: 59px !important;
			}
		}
	}
	.input-field-value {
		margin-bottom: 5px;
		position: relative;
		&.m-b-0 {
			margin-bottom: 0 !important;
		}
		p {
			padding-left: 28px;
			color: #4c4c4c;
			display: inline-block;
			font-size: 14px;
			width: 285px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		em {
			position: absolute;
			left: 0px;
			top: 2px;
			font-size: 18px;
			color: #808080;
		}
		.email-svg-text {
			width: 14px;
			position: absolute;
			left: 9px;
			top: 6px;
		}
		svg.email-svg-input-focus {
			width: 15px;
			position: absolute;
			left: 8px;
			top: 7px;
			transform: scale(1.2);
		}
	}

	.login-logo {
	  width: 150px;
	  margin: 25px 32px 0px
	}

	.login-footer {
	  align-self: center;
	  height: 100%;
	  display: flex;
	  justify-content: center;
	  align-items: flex-end;
	  margin-bottom: 10px;

	  span {
	    font-size: 0.9rem;
	    line-height: 1.33333;
	    font-weight: 600;
	    color: rgba(0,0,0,0.6);
	    margin: 8px;
	    cursor: pointer

	    &:hover {
	      border-bottom: 1px solid rgba(0,0,0,0.6);
	    }
	  }

	  h6 {
	    font-size: 0.7rem;
	    line-height: 1.33333;
	    font-weight: 600;
	    color: rgba(0,0,0,0.6);
	    margin: 8px 5px 8px 8px;
	    cursor: pointer

	    &:hover {
	      border-bottom: 1px solid rgba(0,0,0,0.6);
	    }
	  }
	}
	/*
		Modification: change class
		By: Komal
		Date: 03/02/2023
	*/
	.login-form {
	    background: #ededed;
	    width: 340px;
			/*
				Modification: Updated padding (ref from COMP)
				By: Devang
				Date: 11/07/2023
			*/
	    // padding: 64px 32px 64px 32px;
			padding: 24px 32px 32px;
	    align-self: flex-end;
	    border: 1px solid #e1e2f0;
	    border-radius: 40px;
	    box-shadow: 0 4px 12px #000;
	    transition: all 0.3s ease;
	    margin-right: 70px;
			/*
				Modification: Updated margin
				By: Devang
				Date: 11/07/2023
			*/
			//margin-top: 32px;
	    margin-top: 12px;
	}

	.login-row {
	  display: flex;
	  flex-direction: column;
	  margin-bottom: 24px;
	}

	.login-label {
	  font-size: 12px !important;
	  color: #003468 !important;
	}

	.login-title {
	  font-size: 16px !important;
	  color: #003468 !important;
	}

	.login-input {
	  flex: 1;
	  padding: 10px 0 !important;
	  //border: 1px solid #d6d8e6 !important;
	  border-radius: 4px;
	  font-size: 12px;
	  transition: all 0.2s ease-out;
	  height: auto !important;
	}

	/*
		Changed height for button-block div.
		Modified On: 12 May 2020
		Modification: login input border width to 1 px
	*/
	.button-block {
		height: 10px;
		margin-top: 10px;
		.question {
			font-size: 12px;
			color: #000;
		}
	}
	.login-input:focus {
	  outline: none;
	  //border: 1px solid #003468 !important;
	  //box-shadow: inset 2px 2px 5px 0 rgba(42, 45, 48, 0.12);
	}

	.login-input::placeholder {
	  //color: #C8CDDF;
	  font-size: 12px !important;
	}

	.login-button {
		width: auto;
		float: right;
		padding: 6px 4px;
		height: auto;
		font-weight: normal;
		font-size: 14px;
		background: #003468;
		color: #fff;
		border: none;
		border-radius: 5px;
		cursor: pointer;
		margin-top: 5px;
		transition: background 0.2s ease-out;
	}

	.login-button:hover {
	  background: #003468a3;
	}

	.courierImg,
	.catalogueImgUpdate{
		position:relative;
		max-width: 140px;
		max-height:140px
	}


	.crossTip,
	.crossTipUpdate {
		cursor: pointer;
		display: none;
	  background-color: #000;
	    color: #fff;
	    text-align: center;
	    border-radius: 6px;
	    padding: 0px 5px;
	    position: absolute;
	    right: 5%;
	    top: 4%;
	}


	.crossTipNone{
		display: none;
	  background-color: transparent;
	  color: #fff;
	  text-align: center;
	  border-radius: 6px;
	  padding:3px 5px;
	  position: absolute;
	  right: 50%;
	    top: 0;
	}

	.courierImg:hover .crossTip,
	.catalogueImgUpdate:hover .crossTipUpdate {
	  display: block;
	}

	.error-message {
		color: #ff0000;
		font-size: 10px;
		font-weight: 600;
		em {
			font-size: 8px;
			font-weight: normal;
		}
	}
	.success-message {
		font-size: 12px;
		font-weight: 600;
		color: #6bb562;
	}
}

@media(max-width: 458px) {
  .login-container {
    //margin: 0 18px;
    justify-content: flex-start;

		.login-form {
	    //background: #f9faff;
	    border: none;
	    box-shadow: none;
	    align-self: center;
	    margin: 0 10px;
	  }
  }
}

@media only screen and (max-width: 769px) {
	.login-container {
		.login-form {
	    width: 100%;
	    max-width: 416px;
	  }

	  .login-logo {
	    margin: 25px 5px 0
	  }

	  .login-footer {
	    display: none;
	  }
	}
}
