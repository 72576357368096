/*
  modified : Ashutosh G
  modified : 01/03/2024
  modification : Chatbot CSS added
*/
/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  background: #E3F2FD;
}
.chatbot-toggler {
  position: fixed;
  bottom: 30px;
  right: 5px;
  outline: none;
  border: none;
  height: 50px;
  width: 50px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
  z-index: 150;
}
body.show-chatbot .chatbot-toggler {
  transform: rotate(90deg);
}
.chatbot-toggler span {
  color: #fff;
  position: absolute;
}
.chatbot-toggler span:last-child,
body.show-chatbot .chatbot-toggler span:first-child  {
  opacity: 0;
}
body.show-chatbot .chatbot-toggler span:last-child {
  opacity: 1;
}
.chatbot {
  position: fixed;
  right: 40px;
  bottom: 90px;
  width: 420px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.5);
  transform-origin: bottom right;
  box-shadow: 0 0 128px 0 rgba(0,0,0,0.1),
              0 32px 64px -48px rgba(0,0,0,0.5);
  transition: all 0.1s ease;
  z-index: 150;
}
body.show-chatbot .chatbot {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}
.chatbot header {
  padding: 16px 0;
  position: relative;
  text-align: center;
  color: #fff;
  background: #002c5f;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}
.chatbot header span {
  position: absolute;
  right: 15px;
  top: 50%;
  display: none;
  cursor: pointer;
  transform: translateY(-50%);
}
header h2 {
  font-size: 1.4rem;
}
.chatbot .chatbox {
  overflow-y: auto;
  height: 510px;
  padding: 30px 20px 100px;
}
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar {
  width: 6px;
}
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 25px;
}
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}
.chatbox .chat {
  display: flex;
  list-style: none;
}
.chatbox .outgoing {
  margin: 20px 0;
  justify-content: flex-end;
}

.chatbox .incoming {
  margin: 20px 0;
}

.chatbox .incoming span {
  width: 32px;
  height: 32px;
  color: #fff;
  cursor: default;
  text-align: center;
  line-height: 32px;
  align-self: flex-end;
  //background: #002c5f;
  //border-radius: 4px;
  margin: 0 10px 7px 0;
}
.chatbox .chat p {
  white-space: pre-wrap;
  //padding: 12px 16px;
  //border-radius: 10px 10px 0 10px;
  //max-width: 75%;
  //color: #fff;
  font-size: 0.95rem;
  //background: #002c5f;
  //margin-bottom: 5px;
}

.selectable {
  cursor: pointer;
  padding: 3px 0px;
}

.selectable:hover {
  background: #002c5f !important;
  color: #fff !important;
  padding: 3px 5px;
  border-radius: 5px;
}

.additional-element {
    display: none;
}

.selectable:hover .additional-element {
    display: block;
}

.selectable:hover table {
  border: none;
}

.selectable:hover td {
  color: #fff;
}

.chatBot-MessageContainer {
  position: relative;
  width: 75%;
  overflow: hidden;
  padding: 12px 16px 20px;
  background: #f2f2f2;
}

//.chatbox .incoming p {
//  border-radius: 10px 10px 10px 0;
//}

.incomingMsg {
 border-radius: 10px 10px 10px 0;
 color: #000;
 background: #f2f2f2;
}

.outgoingMsg {
 border-radius: 10px 10px 0 10px;
 background: #002c5f;
 color: #fff;
}

.chatbox .chat p.error {
  color: #721c24;
  background: #f8d7da;
}
//.chatbox .incoming p {
//  color: #000;
//  background: #f2f2f2;
//}
.chatbot .chatbot-chat-input {
  display: flex;
  gap: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 3px 20px;
  border-top: 1px solid #ddd;
}
.chatbot-chat-input textarea {
  height: 55px;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  max-height: 180px;
  padding: 15px 15px 15px 0;
  font-size: 0.95rem;
}
.chatbot-chat-input span {
  align-self: flex-end;
  //color: #002c5f;
  cursor: pointer;
  height: 55px;
  display: flex;
  align-items: center;
  visibility: hidden;
  font-size: 1.35rem;
}
.chatbot-chat-input textarea:valid ~ span {
  visibility: visible;
}

.mobilechatbot-cancel-btn {
  display: none;
  position: absolute;
  top: 23px;
  right: 16px;
  color: #fff;
}

.chatbot-header {
  padding: 16px 0px;
  background: #002c5f;
}

.chatbox-messageTime {
  position: absolute;
  right: 6px;
  bottom: 2px;
  font-size: 11px !important;
}

.date-divider-chatbot {
  font-size: 12px;
  text-align: center;
  background: #002c5f;
  color: #fff;
  width: 125px;
  padding: 3px;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 10px;
}

@media (max-width: 490px) {
  .chatbot-toggler {
    right: 20px;
    bottom: 9%;
  }
  .chatbot {
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 0;
    width: 100%;
  }
  .chatbot .chatbox {
    height: 90%;
    padding: 25px 15px 100px;
  }
  .chatbot .chatbot-chat-input {
    padding: 5px 15px;
  }
  .chatbot header span {
    display: block;
  }
  .mobilechatbot-cancel-btn {
    display: block;
  }
  .chatBot-MessageContainer {
    width: 84%;
  }
}
