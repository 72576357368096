
// Color
// color-initial ?= #fff
// color-primary ?= #9b4dca
// color-secondary ?= #606c76
// color-tertiary ?= #f4f5f6
// color-quaternary ?= #d1d1d1
// color-quinary ?= #e1e1e1
// color-primary ?= #2196F3


color-initial ?= #fff
color-primary ?= #2c2d96
color-secondary ?= black
color-tertiary ?= #f4f5f6
color-quaternary ?= #d1d1d1
color-quinary ?= #e1e1e1

color-danger ?= #f44336
color-warning ?= #2c2d96
color-success ?= #4caf50
color-tag-delete ?= rgba(10, 10, 10, 0.2)

color-logo-orange ?= #f7941d
color-logo-blue ?= #333333

border-darker-alt ?= #dfe1e5;
border-darker ?= #e1e3e8;
border-lighter ?= #e9ecf0;

.enquiry_bg {
  background: #D78e26 !important;
}
.booking_bg {
  background: #2D9596 !important;
}
.retail_bg {
  background: #11698e !important;
}
.lead_bg {
  background: #43506c !important;
}
.lost_bg {
  background: #be3144 !important;
}
.delivery_bg {
  background: #7e6b8f !important;
}
.postDelivery_bg {
  background: #43506c !important;
}
.ammendments_bg {
  background: #d27069 !important;
}
/*
  BY: Prasannadatta Kawadkar
  On: 22 March 2024
  Modification: changed the names of classes everywhere as the bug was induced by dynamicProperties_color
*/
.enquiryColor {
  color: #D78e26 !important;
}
.bookingColor {
  color: #2D9596 !important;
}
.retailColor {
  color: #11698e !important;
}
.leadColor {
  color: #43506c !important;
}
.lostColor {
  color: #be3144 !important;
}
.deliveryColor {
  color: #7e6b8f !important;
}
.postdeliveryColor {
  color: #43506c !important;
}
.ammendmentsColor {
  color: #d27069 !important;
}
/*
  BY: Prasannadatta Kawadkar
  On: 22 March 2024
  Modification: changed the color to make it same as the entity color
*/
.lead_active {
  border: 1px solid #d78e26 !important;
  background: rgba(145,199,231,0.071) !important;
  color: #d78e26 !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.enquiry_active {
  border: 1px solid #D78e26 !important;
  background: rgba(145,199,231,0.071) !important;
  color: #D78e26 !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.booking_active {
  border: 1px solid #2D9596 !important;
  background: rgba(145,199,231,0.071) !important;
  color: #2D9596 !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.retail_active {
  border: 1px solid #11698e !important;
  background: rgba(145,199,231,0.071) !important;
  color: #11698e !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.lead_active {
  border: 1px solid #43506c !important;
  background: rgba(145,199,231,0.071) !important;
  color: #43506c !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.lost_active {
  border: 1px solid #be3144 !important;
  background: rgba(145,199,231,0.071) !important;
  color: #be3144 !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.delivery_active {
  border: 1px solid #7e6b8f !important;
  background: rgba(145,199,231,0.071) !important;
  color: #7e6b8f !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.postDelivery_active {
  border: 1px solid #43506c !important;
  background: rgba(145,199,231,0.071) !important;
  color: #43506c !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.ammendments_active {
  border: 1px solid #d27069 !important;
  background: rgba(145,199,231,0.071) !important;
  color: #d27069 !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/*
  BY: Prasannadatta Kawadkar
  ON: 22 March 2024
  Modification: added new class for contacts tile
*/
.contacts_active {
  border: 1px solid #503C3C !important;
  background: rgb(80, 60, 60, 0.2) !important;
  color: #503C3C !important;
  /*
    Bug: T1687 - 2 - Notification icon - On landing in the CRM view/back office view. it is hard for the user to understand which tile is selected. We have done colour coding to make it clear, but because the selected tile turns white on selection the colour is lost
    Modification: Added heavy box-shadow on active tiles
    By: Devang
    Date: 11/04/2024
  */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
