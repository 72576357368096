.auth-container {
	login-transition() {
		-webkit-transition: all 0.6s ease;
		-moz-transition: all 0.6s ease;
		-o-transition: all 0.6s ease;
		-ms-transition: all 0.6s ease;
		transition: all 0.6s ease;
	}
  //background: #f9faff;
  // background: #f0f8ff;
  background: url(/assets/images/loginbg.jpg) no-repeat center;
  background-size: cover;
  color: #3a3c47;
  line-height: 1.6;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100vh;
  margin: 0 auto;
  padding: 0;

  .auth-logo {
	  width: 150px;
	  margin: 25px 32px 0px
	}

	.auth-footer {
	  align-self: center;
	  height: 100%;
	  display: flex;
	  justify-content: center;
	  align-items: flex-end;
	  margin-bottom: 10px;

	  span {
	    font-size: 0.9rem;
	    line-height: 1.33333;
	    font-weight: 600;
	    color: rgba(0,0,0,0.6);
	    margin: 8px;
	    cursor: pointer

	    &:hover {
	      border-bottom: 1px solid rgba(0,0,0,0.6);
	    }
	  }

	  h6 {
	    font-size: 0.7rem;
	    line-height: 1.33333;
	    font-weight: 600;
	    color: rgba(0,0,0,0.6);
	    margin: 8px 5px 8px 8px;
	    cursor: pointer

	    &:hover {
	      border-bottom: 1px solid rgba(0,0,0,0.6);
	    }
	  }
	}

  h1 {
    //margin-top: 48px;
    font-family: 'CenturygothicRegular', sans-serif;
    font-size: 1.8rem;
    line-height: 1.25;
    font-weight: 600;
    color: #123d52;
    padding: 0 0 4px 0;
  }
	.login-dark-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background: #00000047;
		z-index: 0;
	}
	.auth-container-form-overlay-transition {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(255,255,255,0.6);
		z-index: 8;
	}
	.auth-top-loader-bar {
		height: 4px;
		width: 170px;
		top: 0;
		animation: marquee 0.4s linear infinite;
		position: absolute;
		background: #003468;
		z-index: 9;

		opacity: 1;
		right: 0;
		/*&.no-color {
			opacity: 0;
		}
		&.start {
			right: 0;
			opacity: 1;
		}*/
	}
	@keyframes marquee {
	  0% {
	    left: -170px;
	  }
	  100% {
	    left: 340px;
	  }
	}

	.welcome-block {
		width: 100%;
		min-height: 70px;
		display: flex;
		align-items: flex-end;
		position: relative;
		margin-bottom: 15px;
		line-height: 1.2;
		font-size: 10px;
		p {
			position: relative;
			font-size: 16px;
			color: #000;
			//font-family: 'IBMPlexSansBold', sans-serif;
			font-weight: 600;
		}
	}
	.description-block {
		width: 100%;
		min-height: 55px;
		margin-bottom: 10px;
		p {
			font-size: 12px;
			color: #000;
			//font-family: 'PTSerif', sans-serif;
			line-height: 1.3;
		}
	}

	.input-field {
		margin-bottom: 20px;
		position: relative;
		input {
			height: 44px;
			padding-left: 30px;
			padding-right: 34px;
			//border: none;
			//border: 1px solid #d3d3d3;
			background-position: 86% 69% !important;
			&::placeholder {
				color: #4c4c4c;
				font-size: 12px;
			}
			&:active , &:focus {
				//border: 2px solid #000;
				&::placeholder {
					//color: transparent;
				}
			}
		}
		.email-green-check {
			width: 15px;
			position: absolute;
			top: 16px;
			right: 10px;
		}
		.email-svg-input {
			width: 14px;
			position: absolute;
			left: 0;
			top: 16px;
		}
		.lock-svg-input {
			width: 11px;
			position: absolute;
			left: 0;
			top: 16px;
			&.confirm-password {
				top: 55px !important;
			}
		}

		.open-eye-svg-login {
			width: 15px;
			position: absolute;
			top: 16px !important;
			right: 12px;
			height: 15px;
			//top: 18px;
			//&.center-eye {
			//	top:15px !important;
			//}
			&.confirm-password {
				top: 59px !important;
			}
		}
		.unseen-eye-svg-login {
			width: 15px;
			position: absolute;
			top: 16px !important;
			right: 12px;
			height: 15px;
			//&.center-eye {
				//top: 8 px !important;
			//}
			&.confirm-password {
				top: 59px !important;
			}
		}
		svg.email-svg-input-focus {
			width: 15px;
			position: absolute;
			left: 8px;
			top: 18px;
			transform: scale(1.2);
		}
		svg.lock-svg-input-focus {
			width: 11px;
			position: absolute;
			left: 10px;
			top: 16px;
			transform: scale(1.3);
			&.center-lock {
				top: 17px !important;
			}
			&.confirm-password-focus {
				top: 59px !important;
			}
		}
	}
	.input-field-value {
		margin-bottom: 5px;
		position: relative;
		&.m-b-0 {
			margin-bottom: 0 !important;
		}
		p {
			padding-left: 28px;
			color: #4c4c4c;
			display: inline-block;
			font-size: 14px;
			width: 285px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		em {
			position: absolute;
			left: 0px;
			top: 2px;
			font-size: 18px;
			color: #808080;
		}
		.email-svg-text {
			width: 14px;
			position: absolute;
			left: 9px;
			top: 6px;
		}
		svg.email-svg-input-focus {
			width: 15px;
			position: absolute;
			left: 8px;
			top: 7px;
			transform: scale(1.2);
		}
	}

	.auth-container-form {
    background: #ededed;
    width: 340px;
    align-self: flex-end;
    border: 1px solid #e1e2f0;
    border-radius: 40px;
    box-shadow: 0 4px 12px #000;
    transition: all 0.3s ease;
    margin-right: 70px;
    margin-top: 12px;
    position: relative;
    overflow: hidden;

		.go-back {
			z-index: 7;
			top: 16px;
			left: 18px;
			position: absolute
			cursor: pointer;
			em {
				font-size: 7px;
			}
			p {
				display: inline-block;
				font-size: 14px;
			}
		}

		.auth-form-inner-container {
			login-transition();
			position: absolute;
			padding: 62px 32px 32px;
			width: 100%;
			height: 100%;
			&.email-form-container {
				z-index: 1;
			 background: #ededed;
				&.show-container {
					left: 0;
				}
				&.hide-container {
					/*
						Bug: On completing form, it should get hide, but it is shown a little from left side on main form view
						Modification: T1684 - Set left to -100%, commented setting it to -340px
						By: Devang
						Date: 9/12/2024
					*/
					// left: -340px;
					left: -100%;
				}
			}
			&.password-form-container {
				&.show-container {
					right: 0;
				}
				&.hide-container {
					/*
						Bug: On completing form, it should get hide, but it is shown a little from left side on main form view
						Modification: T1684 - Set left to -100%, commented setting it to -340px
						By: Devang
						Date: 9/12/2024
					*/
					// right: -340px;
					right: -100%;
				}
				&.hide-container-from-forgot {
					right: 340px;
				}
				&.hide-container-from-login {
					right: 680px;
				}
			}
			&.error-form-container {
				&.show-container {
					right: 0;
				}
				&.hide-container {
					right: -440;
				}
			}
			&.forgot-form-container {
				&.show-container {
					left: 0;
				}
				&.hide-container {
					/*
						Modification: T1684 - Set left to -100%, commented setting it to -340px
						By: Devang
						Date: 9/12/2024
					*/
					// left: -340px;
					left: -100%;
				}
			}
      &.set-password-form-container {
        &.show-container {
          left: 0;
        }
        &.hide-container {
					/*
						Modification: T1684 - Set left to -100%, commented setting it to -340px
						By: Devang
						Date: 9/12/2024
					*/
					// left: -340px;
          left: -100%;
        }
      }
      &.set-password-confirm-form-container {
        &.show-container {
          left: 0;
        }
        &.hide-container {
					/*
						Modification: T1684 - Set left to -100%, commented setting it to -340px
						By: Devang
						Date: 9/12/2024
					*/
					// left: -340px;
          left: -100%;
        }
      }
		}
	}

	.login-row {
	  display: flex;
	  flex-direction: column;
	  margin-bottom: 24px;
	}

	.login-label {
	  font-size: 12px;
	  color: #003468 !important;
	}

	.login-title {
	  font-size: 16px !important;
	  color: #003468 !important;
	}

	.login-input {
	  flex: 1;
	  padding: 10px 0 !important;
	  //border: 1px solid #d6d8e6 !important;
		/*
			Modification: T1684 - Removed border-radius on input form as only bottom border is shown
			By: Devang
			Date: 9/12/2024
		*/
	  // border-radius: 4px;
	  font-size: 12px;
	  transition: all 0.2s ease-out;
	  height: auto !important;
	}

	/*
		Changed height for button-block div.
		Modified On: 12 May 2020
		Modification: login input border width to 1 px
	*/
	.button-block {
		height: 10px;
		margin-top: 10px;
		.question {
			font-size: 12px;
			color: #000;
		}
	}
	.login-input:focus {
	  outline: none;
	  //border: 1px solid #003468 !important;
	  //box-shadow: inset 2px 2px 5px 0 rgba(42, 45, 48, 0.12);
	}

	.login-input::placeholder {
	  //color: #C8CDDF;
	  font-size: 12px !important;
	}


	/*
		Modification: T1684 - Added .login-button-container & .verify-pass-btn classes to handle button full widht view on mobile view
		By: Devang
		Date: 9/12/2024
	*/
	.login-button-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.verify-pass-btn {
		position: absolute;
		top: 0;
		right:0;
	}

	.login-button {
		width: auto;
		float: right;
		padding: 6px 4px;
		height: auto;
		font-weight: normal;
		font-size: 14px;
		background: #003468;
		color: #fff;
		border: none;

		/*
			Modification: T1684 - Updated border-radius and margin-top to even values
			By: Devang
			Date: 9/12/2024
		*/
		border-radius: 4px;
		cursor: pointer;
		margin-top: 4px;
		transition: background 0.2s ease-out;
	}

	.login-button:hover {
	  background: #003468a3;
	}

	.courierImg,
	.catalogueImgUpdate{
		position:relative;
		max-width: 140px;
		max-height:140px
	}


	.crossTip,
	.crossTipUpdate {
		cursor: pointer;
		display: none;
	  background-color: #000;
	    color: #fff;
	    text-align: center;
	    border-radius: 6px;
	    padding: 0px 5px;
	    position: absolute;
	    right: 5%;
	    top: 4%;
	}


	.crossTipNone{
		display: none;
	  background-color: transparent;
	  color: #fff;
	  text-align: center;
	  border-radius: 6px;
	  padding:3px 5px;
	  position: absolute;
	  right: 50%;
	    top: 0;
	}

	.courierImg:hover .crossTip,
	.catalogueImgUpdate:hover .crossTipUpdate {
	  display: block;
	}

	.error-message {
		color: #ff0000;
		font-size: 10px;
		font-weight: 600;
		em {
			font-size: 8px;
			font-weight: normal;
		}
	}
	.success-message {
		font-size: 12px;
		font-weight: 600;
		color: #6bb562;
	}

	.auth-settings-block {
    padding: 0 18px;
    left: 0;
		z-index: 1;
    position: absolute;
    width: 100%;
    bottom: 12px;
    p {
      display: inline-block;
      color: #777;
      &:first-child {
        float: left;
        //font-family: 'PTSerif', sans-serif;
        font-size: 10px;
      }
      &:last-child {
        float: right;
        font-size: 10px;
        span {
          display: inline-block;
          height: 15px;
          width: 15px;
          border: 1px solid #777;
          border-radius: 50%;
          text-align: center;
          line-height: 14px;
        }
      }
    }
    em {
      line-height: 1.9;
      margin-left: 5px;
      display: none;
    }
  }

  .inner-container-form {
      //padding: 0 32px;
  }
  .password-checker {
  	//position: absolute;
  	//left: 32px;
    //bottom: 0;
  	font-size: 10px;
  	ul {
  		list-style-type: none;
  		li {
  			font-size: 10px;
  			margin-bottom: 0;
  			.list-dot {
  				display: inline-block;
  				width: 5px;
  				height: 5px;
  				border: 1px solid #4c4c4c;
  				border-radius: 50%;
  				margin-right: 10px;
  			}
  			.red-cross {
  				width: 6px;
  				margin-right: 10px;
  			}
  			.green-check {
  				width: 6px;
  				margin-right: 10px;
  			}
  		}
  	}
  }
	/*
		Bug: FROM COMP - T348 - BG_UI - Email - Reset password, Set new password - Confirm Your Password page - Confirm button - Button disabled issue
		Modification: Added attribute to make invalid-auth-btn to show it invalid by not-allowed cursor and non-response to hover
		By: Devang
		Date: 04/06/2024
	*/
  .invalid-auth-btn {
    background-color: #243f7e;
		cursor: not-allowed;
		&:hover {
			background-color: #243f7e;
		}
  }
}

@media(max-width: 458px) {
  .auth-container {
    //margin: 0 18px;
    justify-content: flex-start;

		.auth-container-form {
	    //background: #f9faff;
	    border: none;
	    box-shadow: none;
	    align-self: center;
	    margin: 0 10px;
	  }
  }
}

@media only screen and (max-width: 769px) {
	.auth-container {
		.auth-container-form {
	    width: 100%;
	    max-width: 416px;

			/*
				Modification: T1684 - Set border-radius of the white-background auth container for mobile
				By: Devang
				Date: 9/12/2024
			*/
			border-radius: 0;
	  }

		/*
			Modification: T1684 - Added .login-button-container, .verify-pass-btn & .login-button classes to handle button full widht view on mobile view
			By: Devang
			Date: 9/12/2024
		*/
		.login-button-container {
			display: grid;
			gap: 4px;
			justify-content: unset;
			align-items: unset;
		}

		.verify-pass-btn {
			position: unset;
			top: unset;
			right: unset;
			margin-bottom: 8px;
		}

		.login-button {
			width: 100%;
		}

	  .auth-logo {
	    margin: 25px 5px 0
	  }

	  .auth-footer {
	    display: none;
	  }
	}
}
