/*
  BY: Prasannadatta Kawadkar
  ON: 20 March 2024
  Modification: added styles for slideUp counter effect
*/

.counter {
  font-size: 24px;
  position: relative;
  overflow: hidden;
}

.counter:before {
  content: attr(data-value);
  position: absolute;
  top: 100%;
  animation: slideUp 1s forwards;
}

@keyframes slideUp {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
