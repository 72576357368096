.pc-tab > input,
.pc-tab section > div {
  display: none;
}

#tab1:checked ~ section .tab1,
#tab2:checked ~ section .tab2,
#tab3:checked ~ section .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5 {
  display: block;
  height: auto;
}

#tab1:checked ~ .nav .tab1,
#tab2:checked ~ .nav .tab2,
#tab3:checked ~ .nav .tab3,
#tab4:checked ~ .nav .tab4,
#tab5:checked ~ .nav .tab5 {
  color: red;
}
.pc-tab {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
.pc-tab ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pc-tab ul li label {
  float: left;
  //padding: 15px 25px;
  //border: 1px solid #ddd;
  border-bottom: 0;
  background: #e1e1e1;
  color: #808080;
  font-weight: normal;
  height: 26px;
  padding: 4px 10px 0px 10px;
  font-size: 12px;
}
.pc-tab ul li label:hover {
  background: #dddddd;
}
.pc-tab ul li label:active {
  background: #ffffff;
}
.pc-tab ul li:not(:last-child) label {
  border-right-width: 0;
}
.pc-tab section {
  clear: both;
}
.pc-tab section div {
  padding: 4px;
  background: #fff;
  border-radius: 2px;
}
.pc-tab section div h2 {
  margin: 0;
  letter-spacing: 1px;
  color: #34495e;
}

#tab1:checked ~ .nav .tab1 label,
#tab2:checked ~ .nav .tab2 label,
#tab3:checked ~ .nav .tab3 label,
#tab4:checked ~ .nav .tab4 label,
#tab5:checked ~ .nav .tab5 label {
  background: white;
  color: #808080;
  position: relative;
  font-weight: 300;
}
#tab1:checked ~ .nav .tab1 label:after,
#tab2:checked ~ .nav .tab2 label:after,
#tab3:checked ~ .nav .tab3 label:after,
#tab4:checked ~ .nav .tab4 label:after,
#tab5:checked ~ .nav .tab5 label:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
  left: 0;
  bottom: -1px;
}
