.stepper-header {
  //background-color: #000;
  border-bottom: 1px solid #e2e2e2;
  color: #000;
  padding: 7px 20px !important;
  font-size: 14px;
  p {
    display: inline-block;
    margin: 0;
  }
}

.stepper {
  display: block !important;
  margin: 10px !important;
  width: 50px;
  &::before {
    width: 2px !important;
    height: 100% !important;
    top: 0 !important;
    left: 14px !important;
    //background-color: #707070 !important;
    border: 2px dashed #707070;
  }
  .step {
    margin-bottom: 15px;
    font-size: 13px;
    background-color: #e3e3e3 !important;
    color: #fff;
    font-weight: 600;
    &.current {
      background-color: #7a7a7a !important;
    }
  }
}

.stepper-button {
  height: 28px !important;
  line-height: 27px !important;
  background-color: #03bf52 !important;
  color: #fff !important;
}

.text-container {
  padding: 10px;
  font-size: 14px;
  a {
    margin-top: 20px;
    //display: block;
  }
}

.text-header {
  background-color: #e2e2e2;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 14px;
}
