/* Materializecss Stepper - By Kinark 2016
// https://github.com/Kinark/Materialize-stepper
// CSS v2.1.3
*/

/*Validate.js FIX*/
label.invalid {
   font-size: 12.8px;
   font-size: 0.8rem;
   font-weight: 500;
   color: red !important;
   top: 50px !important;
}

label.invalid.active {
   -webkit-transform: translateY(0%) !important;
   transform: translateY(0%) !important;
}
/*Validate.js FIX*/

ul.stepper {
   counter-reset: section;
   overflow-y: auto;
   overflow-x: hidden;
   width: 100%
   //max-width: 800px;
}

.card-content ul.stepper {
   margin: 1em -24px;
   padding: 0 24px;
}

@media only screen and (min-width: 993px){
   ul.stepper.horizontal {
      position: relative;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex !important;
      -ms-flex-pack: justify;
      -webkit-box-pack: justify;
      justify-content: space-between;
      //min-height: 458px;
   }

   .card-content ul.stepper.horizontal {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      overflow: hidden;
   }

   .card-content ul.stepper.horizontal:first-child {
      margin-top: -24px;
   }

   ul.stepper.horizontal:before {
      //content: '';
      background-color: transparent;
      width: 100%;
      //min-height: 84px;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
      position: absolute;
      left: 0;
   }
}

ul.stepper .wait-feedback {
   left: 0;
   right: 0;
   top: 0;
   z-index: 2;
   position: absolute;
   width: 100%;
   height: 100%;
   text-align: center;
   display: -ms-flexbox;
   display: -webkit-box;
   display: flex;
   -ms-flex-pack: center;
   -webkit-box-pack: center;
   justify-content: center;
   -ms-flex-align: center;
   -webkit-box-align: center;
   align-items: center;
}

ul.stepper .step {
   position: relative;
}

ul.stepper .step.feedbacking .step-content>*:not(.wait-feedback) {
   opacity: 0.1;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

@media only screen and (min-width: 993px){
   ul.stepper.horizontal .step {
      position: static;
      margin: 0;
      width: 100%;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex !important;
      -ms-flex-align: center;
      -webkit-box-align: center;
      align-items: center;
      height: 28px !important;
   }

   ul.stepper.horizontal>.step:last-of-type, ul.stepper.horizontal>.step[data-last="true"] {
      width: auto !important;
   }

   ul.stepper.horizontal .step:not(:last-of-type):after, ul.stepper.horizontal>.step.active:not(:last-of-type):after{
      content: '';
      position: static;
      display: inline-block;
      width: 100%;
      height: 1px;
   }
}

ul.stepper>li:not(:last-of-type) {
   margin-bottom: 10px;
   -webkit-transition: margin-bottom 0.4s;
   transition: margin-bottom 0.4s;
}

ul.stepper .step:not(:last-of-type).active {
   //margin-bottom: 36px;
}


ul.stepper .step:before {
     position: absolute;
     top: 12px;
     counter-increment: section;
     content: counter(section);
     height: 28px;
     width: 28px;
     color: white;
     background-color: rgba(0,0,0,0.3);
     border-radius: 50%;
     text-align: center;
     line-height: 28px;
     font-weight: 400;
}

@media screen and (max-width: 425px) {
  ul.stepper .mobile-step:before {
     display:none;
  }
}

ul.stepper .step.active:before, ul.stepper .step.done:before {
   background-color: var(--button-primary);
}

ul.stepper .step.done:before {
   content: '\e5ca';
   font-size: 16px;
   font-family: 'Material Icons';
   background-color: #008000 !important;
}

ul.stepper .step.wrong:before {
   content: '\e001';
   font-size: 24px;
   font-family: 'Material Icons';
   background-color: red !important;
}

/*
  modified : Ashutosh G
  modified : 05/12/2023
  modification : added width: 100% for stepper tab as per KDMS Flow Walkthrough
*/
ul.stepper .step-title {
   margin: 0 -24px;
   cursor: pointer;
   padding: 15.5px 44px 24px 64px;
   display: block;
   width: 100%;
}

@media only screen and (min-width: 993px){
   ul.stepper.horizontal .step.active .step-title:before, ul.stepper.horizontal .step.done .step-title:before {
      background-color: var(--button-primary);
   }
   ul.stepper.horizontal .step .step-active {
      position: relative;
   }
   ul.stepper.horizontal .step .step-active:before {
     border-bottom: 2px solid #002c5f;
     position: absolute;
     content: '';
     left: 0;
     border-radius: 0;
     width: 100%;
     height: 1px;
     bottom: -5px;
   }

   ul.stepper.horizontal .step.done .step-title:before {
      content: '\e5ca';
      font-size: 16px;
      font-family: 'Material Icons';
      background-color: #008000 !important;
   }

   ul.stepper.horizontal .step.wrong .step-title:before {
      content: '\e001';
      font-size: 24px;
      font-family: 'Material Icons';
      background-color: red !important;
   }

   ul.stepper.horizontal .step-title {
      line-height: 32px;
      //height: 84px;
      margin: 0;
      padding: 0 25px 0 65px;
      //padding-left: clamp(30px, calc(20px + 4vw), 65px);
      display: inline-block;
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      color: #002C5F;
      font-size: 10px;
      //border-left: 1px solid #bfbfbf;
      //border-bottom: 1px solid #bfbfbf;
      //border-top: 1px solid #bfbfbf;

   }

   ul.stepper.horizontal .step:before {
      content: none;
   }

   ul.stepper.horizontal .step .step-title:before {
      position: absolute;
      //top: 28.5px;
      top: 5px;
      left: 19px;
      counter-increment: section;
      content: counter(section);
      height: 24px;
      width: 24px;
      color: white;
      background-color: rgba(0,0,0,0.3);
      border-radius: 50%;
      text-align: center;
      line-height: 25px;
      font-weight: 400;
   }
}

/*.card-content ul.stepper.horizontal .step .step-title:first-child {
margin-left: -20px;
padding-left: 85px;
}

.card-content ul.stepper.horizontal .step .step-title:first-child::before {
left: 39px;
}

.card-content ul.stepper.horizontal .step .step-title:last-child {
margin-right: -20px;
padding-right: 45px;
}

.card-content ul.stepper.horizontal .step .step-title:last-child::before {
left: 39px;
}*/

ul.stepper .step-title:after {
   content: attr(data-step-label);
   display: block;
   position: absolute;
   font-size: 12.8px;
   font-size: 0.8rem;
   color: #424242;
   font-weight: 400;
}

@media only screen and (min-width: 993px){
   ul.stepper.horizontal .step-title:after {
      top:15px;
   }
}

ul.stepper .step-title:hover {
   background-color: rgba(0, 0, 0, 0.06);
}

ul.stepper .step.active .step-title {
   font-weight: 500;
}

ul.stepper .step-content {
   position: relative;
   display: none;
   //height: calc(100% - 132px);
   width: inherit;
   overflow-y: auto;
   overflow-x: hidden;
   //margin-left: 41px;
   //margin-right: 24px;
}

@media only screen and (min-width: 993px){
   ul.stepper.horizontal .step-content {
      position: absolute;
      //height: calc(100% - 84px);
      top: 36px;
      left: 0;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 0;
      //padding: 0px 20px 76px 20px;
      padding: 0px 20px 115px 20px;
   }

   .card-content ul.stepper.horizontal .step-content {
      padding-left: 40px;
      padding-right: 40px;
   }
}

ul.stepper>.step:not(:last-of-type):after {
   content: '';
   position: absolute;
   top: 50px;
   left: 13.5px;
   width: 1px;
   height: 40%;
   height: calc(100% - 38px);
   background-color: rgba(0,0,0,0.1);
   -webkit-transition: all 0.4s;
   transition: all 0.4s;
}

ul.stepper>.step.active:not(:last-child):after {
   height: 93%;
   height: calc(100% - 12px);
}

ul.stepper>.step[data-last="true"]:after {
   height: 0;
   width: 0;
}

ul.stepper>.step[data-last="true"] {
   margin-bottom: 0;
}

ul.stepper .step-actions {
   padding-top: 16px;
   -webkit-display: flex;
   -moz-display: flex;
   -ms-display: flex;
   display: -webkit-box;
   display: flex;
   -ms-flex-pack: start;
   -webkit-box-pack: start;
   justify-content: flex-start;
}

ul.stepper .step-actions .btn:not(:last-child), ul.stepper .step-actions .btn-flat:not(:last-child), ul.stepper .step-actions .btn-large:not(:last-child) {
   margin-right:5px;
}

@media only screen and (min-width: 993px){
   ul.stepper.horizontal .step-actions .btn:not(:last-child), ul.stepper.horizontal .step-actions .btn-flat:not(:last-child), ul.stepper.horizontal .step-actions .btn-large:not(:last-child) {
      margin-left:5px;
      margin-right:0;
   }

   ul.stepper.horizontal .step-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background-color: #fff;
      -ms-flex-direction: row-reverse;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      flex-direction: row-reverse;
   }

   .card-content ul.stepper.horizontal .step-actions {
      padding-left: 40px;
      padding-right: 40px;
   }
}

ul.stepper .step-content .row {
   margin-bottom: 7px;
}
.stepper .step {
  /* margin-bottom: 15px; */
  font-size: 13px;
  background-color: #fff !important;
  color: #808080 !important;
  /* font-weight: 600; */
}

.noactions-before:before{
  height:0 !important;
}
