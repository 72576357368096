.static-gif {
	position: absolute;
	background: white;
}
.static-gif:hover {
  opacity: 0;
}
.active-gif:hover {
	//opacity: 1;
}
