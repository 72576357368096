.comboBox-search-box {
  display: flex;
  align-items: baseline;
  padding: 7px 10px;
  height: 50px;
  background: white;
}

.comboBox-search-container {
  display: flex;
  background: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  border-radius: 5px;
  width: 100% !important;
  height: 100% !important;
  outline: 0.5px solid #dadada;
}

.comboBox-search-input {
  height: 2.2rem !important;
  border: none !important;
  outline: none !important;
  border-bottom: none !important;
}

.comboBox-search-container:hover {
  outline: 0.5px solid #8f8383;
}

.comboBox-search-suggestion {
  position: absolute;
  top: 38px;
  left: 0px;
  z-index: 999;
  background: #fff;
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  max-height: 200px;
  transition: transform 5s ease;
}
