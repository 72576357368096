//By: Devang
//Date: 03/09/2022
.switch {
	position: relative;
	display: block;
	vertical-align: top;
	width: 40px;
	height: 15px;
	padding: 3px;
	border-radius: 18px;
	cursor: pointer;
	box-sizing:content-box;
}
.switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing:content-box;
}
.switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #eceeef;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
	box-sizing:content-box;
}
.switch-label:before, .switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing:content-box;
}
.switch-label:before {
	content: attr(data-off);
	right: 11px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
	content: attr(data-on);
	left: 11px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.switch-input:checked ~ .switch-label {
	background: #E1B42B;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
	opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
	opacity: 1;
}
.switch-handle {
	position: absolute;
	top: 6px;
	left: 5px;
	width: 10px;
	height: 10px;
	background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
	background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
	border-radius: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
	content: "";
	position: absolute;
	top: 58%;
	left: 58%;
	margin: -6px 0 0 -6px;
	width: 10px;
	height: 10px;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);

	border-radius: 6px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.switch-input:checked ~ .switch-handle {
	left: 30px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-label, .switch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}

.switch-left-right .switch-label {
	overflow: hidden;
}
.switch-left-right .switch-label:before, .switch-left-right .switch-label:after {
	width: 20px;
	height: 15px;
	top: 4px;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 4px 0 0 0;
	text-indent: -12px;
	border-radius: 20px;
	box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
}
.switch-left-right .switch-label:before {
	background: #fbc12d;
	text-align: left;
	padding-left: 28px;
}
.switch-left-right .switch-label:after {
	text-align: left;
	text-indent: 9px;
	background: #37B37E;
	left: -100px;
	opacity: 1;
	width: 100%;
}
.switch-left-right .switch-input:checked ~ .switch-label:before {
	opacity: 1;
	left: 100px;
}
.switch-left-right .switch-input:checked ~ .switch-label:after {
	left: 0;
}
.switch-left-right .switch-input:checked ~ .switch-label {
	background: inherit;
}
