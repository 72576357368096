.chip {
  display: inline-flex !important;
  padding: 0 25px;
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  background-color: transparent !important;
  border: 1px solid #e2e2e2;
}
