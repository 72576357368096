.skeleton.is-loading {
  opacity: 1 !important
  .msgContainer {
    //background: #eee;
    //background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background: linear-gradient(110deg, #ececec 3%, #f5f5f5 11%, #ececec 25%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}
