.filter-horizontal-container {
  display: flex;
  align-items: center;
  align-content: center;
  width: calc(100% - 45px);
  overflow-x: auto;
  margin-right: 5px;
}


  .filter-tags {
    //Modification: Commented out margin-bottom 10px
    //By: Devang
    //Date: 12/07/2022

    //margin-bottom: 10px;

    margin-right: 10px;
    align-items: center;
    align-content: center;
    position: relative;
    padding-right: 25px;
    min-width: fit-content;

    p {
      font-size: 10px;
      text-transform: capitalize;
    }

    &:hover {
      .cross {
        cursor: pointer;
        height: 24px;
        width: 25px;
        position: absolute;
        right: 0;
        top: 0;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

.filter-button {
  min-width: 25px;
  min-height: 25px;
  width: 25px;
  height: 25px;
  background: var(--theme-default-colour);
  border: 1px solid #eff1f2;

  svg {
    fill: #fff !important;
    path {
     fill: #fff !important;
    }

  }

  &:hover {
    background-color: var(--theme-default-colour) !important;
    svg {
      fill: var(--text-grey) !important;
      path {
         fill: var(--text-grey) !important ;
      }
    }
  }
}

.filter-footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.reset-filter-footer {
  width: 87%;
  height: 40px;
  bottom: 10px;
  position: absolute;
}
