.collapse-tab-left {
  position: absolute;
  width: 25px;
  cursor: pointer;
  top: 4px;
  z-index: 999;
  background-color: #002c5f !important;
  border: 1px solid #e5dcd3;
  height: 25px;
  border-radius: 4px 0px 0px 4px;
  svg {
    fill: var(--text-grey);
    path {
      fill: var(--text-grey);
    }
  }

  &:hover {
    background-color: #002c5f !important;
    svg {
      fill: #fff !important;
      path {
        fill: #fff !important;
      }
    }
  }
}
.collapse-tab-right {
  position: absolute;
  width: 25px;
  cursor: pointer;
  top: 4px;
  z-index: 999;
  background-color: #cfd2cf !important;
  height: 25px;
  border-radius: 0px 4px 4px 0px;
  svg {
    fill: var(--text-white);
    path {
      fill: var(--text-white);
    }
  }

  &:hover {
    background-color: #22618c1a !important;
    svg {
      fill: #2872a4 !important;
      path {
        fill: #2872a4 !important;
      }
    }
  }
}

/* [Media Queries]
--------------------------------------------------------------------------------
*/

@media (max-width:428px){
	/* smartphones, iPhone, portrait 480x320 phones */
    .collapse-tab-left,.collapse-tab-right{
      display:none;
    }
}

/* modified by Vipin
   modified at 07/11/2022
   modification : Hide collapse arrow icon on mobile devices(width below 428px)
*/
