.user-info-title-pop-up {
    color: #656f7d;
    border: 1px solid #d6d9de;
    background: #f7f8f9;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
    width: 300px;
    height: auto;
    padding: 23px;
    border-radius: 12px;
    border: 1px solid rgba(0,0,0,.01);
    box-shadow: 0 1px 10px #00000040;
    position: absolute;
    will-change: transform;
    margin-top: 15px;
    z-index: 1000;
    opacity: 1;

    .pop-up-name {
      color: #2a2e32;
    }

    .online-logged-in, .offline-logged-out {
      padding: 5px 8px;
      height: fit-content;
      border-radius: 4px;
    }

    .online-logged-in {
      color: #24b04b;
      background: #edf9f1;
    }

    .offline-logged-out {
      color: #656f7d;
      background: #f0f1f3;
    }

    .user-info-title-pop-up-secondary-info p {
      color: #4f5762 !important;
      font-weight: 400;
    }
}
