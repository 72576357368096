.analytics {
  font-family: 'Poppins', sans-serif;

  .filter-single-box {
    width: 150px;
    margin-right: 12px;

    .filter-list-box-container {
      width: 100%;

      .filter-list-header {
        position: relative;
        cursor: pointer;

        .filter-title {
          width: 100%;
          border: 1px solid #DFDFDF;
          border-radius: 5px;
          height: 28px;
          text-transform: uppercase;

          color: #3C3C3C;

          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
        }
        .filter-count-bar {

          background: #DFDFDF;
          border-radius: 3.18359px;

          flex: 0 0 auto;
          height: 4px;
          width: 100%;
          background: #DFDFDF;
        }
      }

      .filter-buttons {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 1;
      }
      .filter-list-box {
        overflow-y: auto;
        z-index: 21;
        box-sizing: border-box;
        position: absolute;
        width: 181px;
        max-height: 184px;
        left: 0px;
        top: 35px;

        background: #FFFFFF;
        border: 1px solid #BFBFBF;
        border-radius: 0px 0px 5px 5px;

        .confirm-filter-btn {
          width: fit-content;
          min-width: fit-content;
          margin: 0 4px;
          background: #CBE1E4;
          border-radius: 2px;
          padding: 4px 6px;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #808080;
        }

        ul {
          li:first-child {
            border-bottom: 1px solid #DFDFDF;
          }
          li, label {
            border: none;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #808080;
          }
        }

        .analytics-filter-label {
          padding-left: 22px !important;
          height: fit-content;
          &:before, &:after {
            width: 12px;
            height: 12px;
            margin: 2px;
          }
        }
        [type="checkbox"]:checked+label:before {
          width: 10px !important;
          height: 13px !important;
          margin: 2px;
        }
      }
    }

    .analytics-filter-tag {
      width: 100%;
      height: 29px;
      padding: 8px;

      background: #FFFFFF;
      border: 1px solid #47AF2C;
      border-radius: 27.0605px;

      color: #47AF2C;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;

    }

    .analytics-clear-filter-tag {
      width: 115px;
      height: 29px;

      background: rgba(239, 239, 239, 0.5);
      border: 1px solid #BFBFBF;
      border-radius: 6.36717px;

      color: #3C3C3C;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
    }
  }

  .qv-object-content-container {
    flex: 1 1 auto;
    position: relative;
    background-color: inherit;
    .qv-object-content {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: inherit;
      .qv-viz-link {
        cursor: pointer;
        box-sizing: border-box;
        border: 2px solid transparent;
      }
    }
    .kpi-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .object-and-panel-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    .object-wrapper {
      height: 100%;
      width: 100%;
      position: relative;
      .qv-object {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        background: #fff;
        .header {
          font-size: 0.723077em;
          color: rgb(128, 128, 128);
        }
      }
      qv-object-kpi-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      .kpi-data {
        display: flex;
        //flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        .text {
          color: rgb(68, 119, 170);
          font-size: 1.1rem;
        }
        .count {
          font-size: 2.3rem;
        }
      }
    }
  }

  .analytics-header-tile {
    height: 53.57px;
    background: linear-gradient(180deg, rgba(121, 198, 193, 0.125) 0%, rgba(121, 198, 193, 0.5) 100%);
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #002C5F;
  }
  .analytics-count-tile {
    height: 92px;
    border-radius: 10.1891px;
    color: #FFFFFF;
    text-shadow: 5.09453px 5.09453px 5.09453px rgba(0, 0, 0, 0.25);

    .analytics-count {
      font-family: Poppins;
      font-size: 36px;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: center;
    }
    .analytics-header {
      font-family: Poppins;
      font-size: 10px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}

.filter-buttons {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}
.filter-list-box {

  .confirm-filter-btn {
    width: fit-content;
    min-width: fit-content;
    margin: 0 4px;
    background: #CBE1E4;
    border-radius: 2px;
    padding: 4px 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #808080;
  }

  ul {
    li:first-child {
      border-bottom: 1px solid #DFDFDF;
      &:hover {
        background-color: #fff;
    	}
    }
    li, label {
      border: none;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #808080;
    }
  }

  .analytics-filter-label {
    padding-left: 22px !important;
    height: fit-content;
    &:before, &:after {
      width: 12px;
      height: 12px;
      margin: 2px;
    }
  }
  [type="checkbox"]:checked+label:before {
    width: 10px !important;
    height: 13px !important;
    margin: 2px;
  }
}
