.popup{
	background:white;
	position:absolute;
	top:20% !important;
	left:30%;
	min-height:400px;
	width:700px;
	padding:20px;
  border-radius:5px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  //background:rgba(219, 238, 245,0.149)
  //backdrop-filter: blur( 4.5px );
  background-color: rgb(32, 33, 36);
  -webkit-backdrop-filter: blur( 6.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  z-index:99999;
}
.popup-content{
	display: flex;
	width:100%;
	justify-content:space-between;
	padding-bottom:10px;
  //border-bottom:1px solid gray;

}
.popup-content h6{
  color:white;
  font-weight:700;
  text-align:center;
  width:100%;
}
.hide-popup{
	display:none;
}
.show-popup{
	display:block;
}

.popup-body{
	min-height:200px;
	padding:10px;
  background-color:white;
  border-radius:10px;
  margin-top:10px;
}
.searchMobileField{
	display:flex;
	flex-direction:column;
	align-items:center;
	width:100%;
}
.searchMobileField input{
	height:70px;
	letter-spacing:1rem;
	font-size:2rem;
	padding:20px;
	margin-top:10px;
  letter-spacing:15px;
}
.searchMobileField button{
	width:100px;
	margin-top:30px;
  border:1px solid gray;
  background-color:#64b2d1;
  border-color:1px solid #64b2d1;
  color:white;
  transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
  text-transform:uppercase;
  height:39px;
  padding:10px;
}
.searchMobileField button:hover, .newUserFields button:hover , .userFoundFieldgoBackBtn button:hover{
  background-color:#3eddff;
}
.newUserFields button , .userFoundFieldgoBackBtn button{
  margin-top:10px;
  background-color:#64b2d1;
  border-color:1px solid #64b2d1;
  color:white;
  transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
  text-transform:uppercase;
  padding:5px;
  font-size:0.8rem;
}
.newUserFields button:nth-child(2){
  margin-left:5px;
}
.newUserFields a{
  text-transform:uppercase;
  padding:10px;
  background-color:transparent;
  border-radius:3px;
  font-size:0.9rem;
  border:1px solid #64b2d1;
  color:#64b2d1;
  font-weight:600;
  transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
}
.newUserFields a:hover{
  background-color:rgba(	59,	200,	245, .2);
}

.newUserFields{
  .delete-icon{
    margin-top: 25px;
    margin-left:8px;
  }
  span {
    cursor:pointer
  }
  select {
    cursor:pointer
  }
}

.userFoundField label{
	margin-top:20px;
  color:#535c69;
  font-weight:300;
}
.userFoundField span{
	color:#2067b0;
  font-weight:300;
}
.userFoundFieldgoBackBtn{
  margin-top:20px;
  width:100%
  display:flex;
  justify-content:center;
}




@media screen and (min-width:1023px) and (max-width:1279px)
{
	.popup{
		position:absolute;
		left:18%;
	}
}
@media screen and (min-width:1280px) and (max-width:1365px)
{
	.popup{
		position:absolute;
		left:23%;
	}
}
@media screen and (min-width:1366px) and (max-width:1599px)
{
	.popup{
		position:absolute;
		left:25%;
	}
}
@media screen and (min-width:1600px) and (max-width:1919px)
{
	.popup{
		position:absolute;
		left:30%;
	}
}
