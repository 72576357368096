.accordion-table-v2 {
  position: relative;

  .accordion-table-head {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .accordion-table-head-1 {
    height: 28px;
    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 5px;

    .accordion-table-item {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;

      color: #FF7A00;
    }
  }
  .accordion-table-head-2 {
    height: 43px;

    .accordion-table-item {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;

      color: rgba(27, 166, 209, 0.5);
    }
  }

  .no-children-arrow-field {
    padding-left: 49px;
  }

  .main-arrow {
    min-width: 49px;
    max-width: 49px;
  }

  .accordion-table-head-1, .accordion-table-head-2 {
    .arrow-field {
      padding-left: 49px;
    }
  }
  .accordion-table-row {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);

    //.accordion-table-header {
    //  //background: #DBDBDB;
    //  //font-weight: 600;
    //  border-bottom: 4px #eef2f4 solid;
    //  color: #808080;
    //}

    //.accordion-table-header,
    .accordion-table-data {
      //display: flex;
      //align-items: flex-end;
      overflow: hidden;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;

      color: var(--theme-default-colour);
    }

    .arrow-field span, .accordion-table-header span, accordion-table-head span, .accordion-table-data span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 8px;
    }

    .arrow-field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      svg {
        transition: all .25s linear;
      }
    }
  }
  .accordion-table-row-1 {
    border-radius: 5px;
    height: 36px;
  }
  .accordion-table-row-2 {
    height: 45px;
  }

  [type="checkbox"]+label {
    padding: 0 !important;
  }

  ul {
    margin: 0 !important;
  }

  .clickable {
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }

  .accordion-table-body {
    //overflow-y: scroll;
  }

  .accordion-table-item-list {
    //background: var(--cd-color-1);
    //box-shadow: var(--shadow-lg);
    [type="checkbox"]:checked+label:before {
      content: none;
    }
    [type="checkbox"],[type="checkbox"]+label:before, [type="checkbox"]:not(.filled-in)+label:after {
      content: none;
      position: absolute;
      left: -9999px;
      opacity: 0;
    }
  }

  .accordion-table__animated .accordion-table__label::before {
    transition: transform .3s;
  }

  .accordion-table__sub {
    display: none; // by default hide all sub menus
    overflow: hidden;
  }

  .accordion-table__sub--is-visible {
    display: block;
  }

  .accordion-table__item {
    user-select: none;
  }

  .accordion-table__input { // hide native checkbox
    position: absolute;
    opacity: 0;
  }

  .accordion-table__label {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--space-sm) var(--space-md);
    //background: var(--cd-color-1);
    //--color-shadow: lightness(var(--cd-color-1), 1.2);
    box-shadow: inset 0 -1px var(--color-shadow);
    color: var(--color-white);

    span {
      //order: 3;
    }

    &:hover {
      //background: lightness(var(--cd-color-1), 1.1);
    }
  }

  .accordion-table__label-2 {
    cursor: default !important;
  }

  // icons
  .accordion-table__label::after, .accordion-table__label--icon-folder::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    //background-image: url('../img/cd-icons.svg');
    background-repeat: no-repeat;
    margin-right: var(--space-xxxs);
  }

  .accordion-table__label--icon-folder::before {
    order: 1;
  }

  .accordion-table__label::after {
    order: 2;
  }

  .accordion-table__label--icon-folder {
    &::before { // arrow icon
      background-position: 0 0;
      transform: rotate(-90deg);
    }

    &::after { // folder icon
      background-position: -16px 0;
    }
  }

  .accordion-table__label--icon-img::after { // image icon
    background-position: -48px 0;
  }

  .accordion-table__input:checked + .accordion-table__label::before { // rotate arrow
    transform: rotate(0);
  }

  .arrow-field svg {
    transform: rotate(-90deg);
  }
  .accordion-table__input:checked + .accordion-table__label .arrow-field {
    svg {
      transform: rotate(0deg);
    }
  }
  .accordion-table__input:checked + .accordion-table__label .accordion-table-row-1{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .accordion-table__input:checked + .accordion-table__label::after { // show open folder icon if item is checked
    background-position: -32px 0;
  }

  .accordion-table__input:checked ~ .accordion-table__sub { // show children when item is checked
    display: block;
  }

  .accordion-table__sub--l1 {
    .accordion-table__label {
      //background: lightness(var(--cd-color-1), 0.65);
      //--color-shadow: lightness(var(--cd-color-1), 0.85);
      box-shadow: inset 0 -1px var(--color-shadow);
      padding-left: calc(var(--space-md) + 16px);

      &:hover {
        //background: lightness(var(--cd-color-1), 0.75);
      }

      .arrow-field {
        padding-left: 49px;
      }
    }
    .accordion-table-row {
      background: #FFFFFF;
    }
  }

  .accordion-table__item:last-child .accordion-table__label {
    box-shadow: none;
  }

  .accordion-table__sub--l2 {
    .accordion-table__label {
     padding-left: calc(var(--space-md) + var(--space-xxxs) + 32px);
    }

    .arrow-field {
      padding-left: 36px !important;
    }
    .accordion-table-row {
      background: #cecece !important;
    }
  }

  .accordion-table__sub--l3 .accordion-table__label {
    padding-left: calc(var(--space-md) + var(--space-xxxs) + 48px);
  }

  .info-progress-bar-container {
    height: 8px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin-right: 8px;
  }
  .info-progress-bar {
    height: inherit;
    background: #5ED700;
  }

  .redeem-button {
    border: 1px solid #5ED700;
    border-radius: 5px;
    cursor: pointer;
    height: 34.5px;
    min-width: 80px;
    p {
      padding: 0 15px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #5ED700;
    }
  }

  .redeemed-button {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid #808080;
    border-radius: 5px;
    height: 34.5px;
    min-width: 80px;
    p {
      padding: 0 15px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      text-align: center
      color: #808080;
    }
  }

  .expired-button {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid red;
    border-radius: 5px;
    height: 34.5px;
    min-width: 80px;
    p {
      padding: 0 15px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      text-align: center
      color: red !important;
    }
  }

  .disabled-row {
    p, span {
      color: rgba(0, 0, 0, 0.5) !important;
    }
    .redeem-button {
      border: 1px solid rgba(0, 0, 0, 0.5) !important;
    }
  }

}
