.info-modal-container-v2 {
  background-color: #f0f8ff;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  height: 100vh !important;
  width: auto !important;
  margin: 0 !important;
  min-width: auto !important;
  min-height: 100% !important;
  overflow: auto;

  .info-modal {
    flex: 1;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .info-header-container {
    min-height: 50px;
    box-sizing: border-box;
    position: relative;
  }

  .info-sub-header-container {
    box-sizing: border-box;
    position: relative;
  }

	.info-content-container {
    // Modification: Changed min-height 100vh-205px from 100vh-75px due to uneccessary scroll
    // By: Devang
    // Date: 03/09/2022

    // Modification: Changed min-height 100vh-205px to auto due to uneccessary space in mobile view
    // By: Masum Raja
    // Date: 09/05/2023
    min-height: auto;
    background: #fff;
    overflow: hidden;
  }

  .warning-box {
    position: absolute;
    top: 0px;
    background: #ff7f00;
    border: 1px solid #d2691e;
    color: black;
    padding: 8px;
  }

  // Modification: Added media query to hide payment details card for mobile view only
  // By: Masum Raja
  // Date: 09/05/2023
  @media (max-width: 600px) {
    .info-payment-details {
      display:none;
    }
  }
}
