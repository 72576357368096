.skeleton-card {
  .is-loading {
    opacity: 1 !important;

    .default-card-loader {
      //background: #eee;
      //background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background: linear-gradient(110deg, #ececec 3%, #f5f5f5 11%, #ececec 25%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
    .yellow-card-loader {
      background: linear-gradient(110deg, #ececec 3%, #f5f5f5 11%, #ececec 25%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
    .orange-card-loader {
      background: linear-gradient(110deg, #FF8F00 3%, #ffa836 11%, #FF8F00 25%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
    .blue-card-loader {
      background: linear-gradient(110deg, rgba(88, 161, 169, .25) 3%, rgba(150, 196, 201, .5) 11%, rgba(88, 161, 169, .25) 25%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  }

  @keyframes shine {
  	to {
  		background-position-x: -200%;
  	}
  }

}
