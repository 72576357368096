.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  //bottom: 150%;
  //left: 50%;
  top: -10px;
  left: 175px;
  margin-left: -150px;
  height: max-content;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  //top: 100%;
  //left: 50%;
  top: 0;
  left: 0;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
