.nuclear-task-main {
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  		color: #8095AF !important;
  		opacity: 1;
      font-weight: 400;
   }
  input[type=text]  {
    padding-left: 8px !important;
  	color: #8095AF !important;
    font-weight: 400;
  }
  input[type=text]:focus {
    color: #002C5F !important;
  }
  textarea:focus-visible, textarea:focus {
    border: 1px solid #bfbfbf !important;
    outline: none;
  }
}

.select-nuclear {
	color: #3C3C3C !important;
	font-weight: 400;

	option {
		color: black !important;
	}
	option:first-child {
		color: gray !important;
	}
  &:focus {
    outline: none;
    border-bottom: 1px solid var(--text-input-border);
  }
}

.select-grey {
	color: #8095AF !important;

	option {
		color: black !important;
	}
	option:first-child {
		color: gray !important;
	}
  &:focus {
    outline: none;
    border-bottom: 1px solid var(--text-input-border);
  }
}

.nuclearTask-task-type {
  input[type=text] {
    border: none;
    padding-top: 4px;
    color: #002C5F !important;
    padding-left: 2px !important;
    font-weight: 400;

  }
  input[type=text]:focus {
    border: none !important;
  }
}

.nuclearTask-save-btn {
  min-width: 40px;
  font-size: 9px;
  padding: 2px;
  border-radius: 5px;
}

.nuclearTask-checklist-edit {
  left: 5px;
  top: 5px;
  font-size: 10px;
  padding: 4px;
  padding-left: 8px;
  background: white;
}

.checklist-edit-show {
    transform: scaleY(1);
    transform-origin: top;
    transition: 0.2s transform ease;
}

.checklist-edit-hide {
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.2s transform ease;
}

.process-tag-pos {
  top: 47px;
  left: 8px;
  background: #526F95 !important;
  border-radius: 5px;
}

.stage-tag-pos {
  left: 24px;
  top: 47px;
  background: #88B7D2 !important;
  border-radius: 5px;
}

.assignee-tag-pos {
  top: 86px;
  left: 8px;
  background: white;
  width: fit-content;
  height: 24px;
  color: #3C3C3C;
}

.assigneeType-tag-pos {
  top: 86px;
  left: 24px;
  background: white;
  width: fit-content;
  height: 24px;
  color: #3C3C3C;
}

.task-tag-pos {
  top: 2px;
  left: 8px;
  background: white;
  width: fit-content;
  height: 24px;
  color: #3C3C3C;
}

#datepicker-nuclearTask, #timepicker-nuclearTask {
  padding-top: 5px;
  border: none;
  font-weight: 400;
}
.nuclearTask-fieldInput {
  .fieldInput-2 {
    input[type=text]  {
      padding-left: 12px !important;
    }
  }
}

.nuclearTask-show {
    transform: scaleY(1);
    transform-origin: top;
    transition: 0.3s transform ease;
}

.nuclearTask-hide {
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.3s transform ease;
}
