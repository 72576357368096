.business-pulse {
  height: 100vh;
  overflow: hidden;

  .tabular-space {
    height: 100vh;
  }
  .graphical-space {
    border-left: 1px solid #cacaca;
    height: 100vh;

    .graphical-space-header {
    }
    .graphical-space-body {
      height: calc(100vh - 47.5px);
      overflow: scroll;

      .graphical-space-filters {
        background: #bcd2d2;
        .team-month-year {}

        input[type="month"] {
            position: relative;
        }

        /* create a new arrow, because we are going to mess up the native one
        see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
        input[type="month"]:after {
            content: "\25BC";
            color: #555;
            padding: 0 5px;
        }

        /* change color of symbol on hover */
        input[type="month"]:hover:after {
            color: #bf1400;
        }

        /* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native monthpicker*/
        input[type="month"]::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }

        /* adjust increase/decrease button */
        input[type="month"]::-webkit-inner-spin-button {
            z-index: 1;
        }

         /* adjust clear button */
         input[type="month"]::-webkit-clear-button {
             z-index: 1;
         }

        select {
      		border-radius: 3px;
      		cursor: pointer;
      		font-size: 12px !important;
          height: auto;
          border: 1px solid #c6c6c6 !important;
      		color: #808080 !important;
          padding: 4px;
      		background-color: #fff;
      		font-weight: 500;
      	}
      }
    }
  }
  .display-month {
    padding: 4px;
    border-radius: 4px;
    background: #e2e2e2;
  }
  .donut-chart-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #cacaca;
    padding-top: 8px;
    h4 {
      margin: 0;
      color: #373d3f;
    }
    p {
      font-weight: 500;
    }
  }
}
