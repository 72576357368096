/*
  BY: Prasannadatta Kawadkar
  ON: 15 March 2024
  Modification: commented color
*/
.chat-container {
  //color: rgb(54, 65, 82);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  //border-radius: 8px;
  overflow: hidden;
  //border: 1px solid rgba(224, 224, 224, 0.596);
  //background-color: rgb(248, 250, 252);
  background-color: #F8FAFC;
}

.avatar-name {
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  //border-radius: 50%;
  align-items: center;
  background: #90caf9;
  justify-content: center;
}

.chat__stages_collaborator {
  background: #f8f8f8;
  color: #002c5f !important;
  position: absolute;
  top: 30px;
  font-weight: normal !important;
  font-size: 14px;
}

.chat__message_modal {
  color: #002c5f !important;
  background: #f8f8f8;
  position: absolute;
  bottom: 100%;
  font-weight: normal !important;
  font-size: 14px;
}

.collaborator-search-box {
  display: flex;
  align-items: baseline;
  padding: 7px 10px;
  height: 50px;
  background: white;
  position: relative;
}

.collaborator-search-container {
  display: flex;
  background: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  border-radius: 5px;
  width: 100% !important;
  height: 100% !important;
  outline: 0.5px solid #dadada;
  z-index: 100;
}

.collaborator-search-input {
  height: 2.2rem !important;
  border: none !important;
  outline: none !important;
  border-bottom: none !important;
}

/*
  Suggestion(10): T1692 - Scenario - Mobile
  Description: Font size is very small and font colour need to more dark.
  By: Arun Singh
  Date: 16/04/2024
*/

.collaborator-search-input::placeholder {
  color: gray;
}

.collaborator-search-container:hover {
  outline: 0.5px solid #8f8383;
}

.collaborator-search-suggestion {
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: -1;
  background: #fff;
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  max-height: 200px;
  transition: 'transform 5s ease'
}


.roomTitle {
    color: rgb(0, 0, 0);
    font-size: 100%;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chat-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  align-items: center;
  height: 100%;
}

.chat-details {
  flex-grow: 1;
  margin-left: 10px;
}

.chat-input {
  border:1px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  margin: 0px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;

  &:focus {
    border: 1px solid #555 !important;
  }
}

.collaborator-send-button {
  min-width: 45px;
  background-color: rgb(248, 250, 252);
}

.date-divider {
  font-size: 12px;
  text-align: center;
  //background: #f8f8f8;
  background: #fff;
  width: 125px;
  margin: auto;
  padding: 3px;
  border-radius: 5px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.chat__attach_collaborator {
    display: flex;
    flex-direction: column;
    position: absolute;
    /*
      Bug: T1692 - 47 - Desktop - Collaborator - Edit icon, plus icon, Initiate chat icon - dropdown - While scrolling the data is displayed behind the drop-down modal
      Modification: Changed bottom 50 to 40
      By: Devang
      Date: 13/04/2024
    */
    bottom: 40px;
    background: #f8f8f8;
    max-height: 400px;
    overflow: auto;
}

.unReadCir {
    display: inline-block;
    //background-color: rgb(37, 211, 102);
    background: #009de2;
    border-radius: 18px;
    padding: 0px 6px;
    vertical-align: middle;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 700;
    color: white !important;
}

.status_pill {
  border: 0.5px solid #e74c7d;
  border-radius: 9px;
  height: 18px;
  color: #e64c7d;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 8px;
  line-height: 1;
  background: #fff;
  font-size: 10px;
}

.sticky-bottom-footer {
  z-index: 99;
  position: sticky !important;
  bottom: 0;
}

.sticky-collaborator-pill-header {
  position: sticky;
  z-index: 99;
  top: 50px;
  background: white;
}

.timeline_container {
  padding: 0 25px;
  margin: 0 0 0 80px;
  ul{
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      height: 100%;
      //border: 1px dashed #aaa;
    }
  }
}

.timeline_box {
  padding: 8px;
  width: 100%;
  min-height: 70px;
  background: #ffff;
  position: relative;
  border-radius: 5px;
  //letter-spacing: 2px;
  box-sizing: border-box;
  margin: 16px 0 30px 0px;
  border: 1px solid #dfdfdf;
  //box-shadow: 1px 1px 3px rgba(100, 100, 100, 0.3);
  >span{
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    height: 100%;
    border: 1px solid #ccc;
    &:before,
    &:after{
      content: "";
      position: absolute;
      left: -5px;
      width: 10px;
      height: 10px;
      border: 2px solid #ccc;
      background: #01579B;
      border-radius: 100%;
    }
    &:before{
      top: -12px;
    }
    &:after{
      top: 100%;
    }
  }//end span
  .time{
    span{
      position: absolute;
      &:first-child{
        top: -10px;
        left: -95px;
      }
      &:last-child{
        //top: 96%;
        left: -95px;
      }
    }
  }
}

.form-input {
  position: relative;
  width: 28%;
}

.form-input select {
  width: 100%;
  height: 38px;
  font-size: 11px;
  padding: 8px;
  outline: none;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.form-input label {
  position: absolute;
  left: 10px;
  top: 45%;
  transform: translateY(-50%);
  font-size: 11px;
  color: gray;
  letter-spacing: 1px;
   transition: .3s;
  font-weight: 400;
  pointer-events: none;
}

.form-input select:focus + label,
.form-input select option:not([value=""]) + label
{
  top: 0;
  font-size: 11px;
  color: gray;
  background: #F8FAFC;
  padding: 4px;
}

.labelOnTop {
  top: 0 !important;
  font-size: 11px;
  color: gray;
  background: #F8FAFC;
  padding: 4px;
}

.form-input select:focus {
  border-bottom: none;
  border: 2px solid #e1e1e1;
}
.waybt-count-tab {
  width: 100%;
  position: relative;
  border-radius: 5px;
  border: 1px solid #818CF8;
}
.waybt-count-tab-title {
  top: -8px;
  color: #818CF8;
  padding: 0 3px;
  font-weight: 500;
  //margin: 0 0 0 7px;
  width: fit-content;
  font-size: 0.75rem;
  position: absolute;
  background: #fff;
}
.waybt-icon-container {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  font-weight: 600;
}
.waybt-searchbar {
  font-size: 14px;
  color: #202124;
  display: flex;
  z-index: 3;
  height: 45px;
  background: white;
  border: 1px solid #dfe1e5;
  box-shadow: none;
  border-radius: 24px;
  //margin: 0px 10px 0px 10px;
  width: auto;
}
.waybt-searchbar:hover {
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223,225,229,0);
}
.waybt-searchbar-wrapper-mob {
  flex: 1;
  display: flex;
  padding: 0 14px;
}
.waybt-searchbar-wrapper {
  flex: 1;
  display: flex;
  padding: 5px 8px 0 14px;
}
.waybt-searchbar-left {
  font-size: 14px;
  color: #202124;
  display: flex;
  align-items: center;
  padding-right: 13px;
  margin-top: -5px;
}
.waybt-search-icon {
  color: #9aa0a6;
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.waybt-searchbar-center {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
}
.waybt-searchbar-input {
  background-color: transparent;
  border: none !important;
  word-wrap: break-word;
  display: flex;
  flex: 100%;
  max-width: 100%;
}
.waybt-voice-search {
  flex: 1 0 auto;
  display: flex;
  cursor: pointer;
  align-items: center;
  border: 0;
  background: transparent;
  outline: none;
  padding: 0 8px;
  width: 2.8em;
}
.quick-tab-option-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}

  @keyframes blink-mic {
    0%, 49% {
      fill: white;
    }
    50%, 100% {
      fill: yellow;
    }
  }

  .loader-blink-mic g {
    animation: blink-mic 1s infinite;
}
