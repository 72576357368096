.non-excel-list-table {
  .non-excel-list-table-head { // copied from .listcard-thead
    border: none;
    //border-bottom: 2px solid #e1e1e1;
    background: #fff;
    z-index: 1;
    padding-top:0px;
    font-size: 12px;
    position: sticky;
    top:0;
    background-color: white;
    border-bottom: 2px solid #e1e1e1;
    z-index:6;

    .select-task-all {
      position: absolute;
      top: -9px;
      bottom: 0px;
      left: -30px;
      right: 0;
      display:none;
    }
    .select-task-all-active {
      position: absolute;
      top: -9px;
      bottom: 0px;
      left: -30px;
      right: 0;
      display:block;
    }
    .select-all-active{
      display:none;
    }

    .non-excel-list-table-header-cell:first-child {
      position: sticky;
      left:0;
    }


    .col-head-active {
      background-color: #e8eaed !important;
      border-radius: 3px;

      .hover-icon-1 {
        left:-18px;
        bottom:0;
        opacity:1 !important;
      }

      .hover-icon-2 {
        right:-18px;
        bottom:0;
        opacity:1 !important;
      }
    }

    .stickyTableHead {
      position: sticky !important;
      left: 0;
      z-index: 7;
      background-color: #ffffffd6 !important;
    }


    .non-excel-list-table-header-cell:hover {
      background-color: #e8eaed;
      border-radius: 3px;
      gap:1px;

      .hover-icon-1 {
        left:-18px;
        bottom:0;
        opacity:1 !important;
      }

      .hover-icon-2 {
        right:-18px;
        bottom:0;
        opacity:1 !important;
      }
    }

    .non-excel-list-table-header-cell,span,div {
      font-weight:400 !important;
      //color: #a0a7b3 !important;
      color: #646464 !important;
      text-transform: uppercase;
      padding-top: 4px;
      padding-bottom: 2px;
      transition: 0.2s background ease;
      background-color: transparent;
      font-size: 11px;
      border: none;
      text-align : center;
      // cursor : pointer;
      flex-shrink: 0;
      line-height:1;
    }

    .non-excel-list-table-row {
      [type="checkbox"] + label::before{
        top: 3px;
        left: 8px;
        width: 13px;
        height: 13px;
      }

      [type="checkbox"]:checked + label::before {
        width: 9px;
        height: 16px;
        left:4px;
        top:-1px;
      }
    }
  }

  .non-excel-list-table-body { // copied from .listcard1-container

    .expand-task { // not used in the component
      left:180px;
      width: 20px;
      height: 20px;
      background-color: #e8eaed;
      display:none;
      align-items: center;
      justify-content: center;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .delegate-task { // not used in the component
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      //background-color: #e8eaed;
      display:none;
      align-items: center;
      justify-content: center;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
    .always-open-delegate-task.delegate-task {
      opacity: 1;
      display: flex;
    }
    .non-excel-list-table-data-cell:first-child {
      text-align: left;
      position: sticky !important;
      left:0;

      .primary-table-cell-data {
        width:356px;
      }

      .secondary-table-cell-data {
        color:tomato !important;
        width:356px;
        font-weight: 500 !important;
      }
    }

    .non-excel-list-table-row {
      &:hover {
        background-color: #f7f8f9 !important;
        .expand-task {
          display:flex;
        }
        .delegate-task {
          display:flex;
        }
        .select-task {
          display:block;
         }
         //Modification:  When hovered on the table row, serial number goes blank
         //By: Manohar
         //Date: 21/08/2023
        .select-task-no {
          //display:none;
        }
      }

      .non-excel-list-table-data-cell {
        font-size: 13px;
        /*
          Modification: Changed height to min-height property-name
          By: Devang
          Date: 30/11/2023
        */
        min-height: 38px !important;
        font-weight: 400;
        color: #3b3b3b;
        text-align: left;
        padding:0 !important;
        background-color: transparent;
        border: none;
        flex-shrink: 0 !important;
        //overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .unSelectable {
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
          padding: 8px;
        }
        span {
          //overflow: hidden;
          text-overflow: ellipsis;
          // cursor: pointer;
          white-space: nowrap;
        }

        span, div {
         font-weight: 400;
         color: #3b3b3b;
        }
      }

       [type="checkbox"] + label::before{
         top: 3px;
         left: 8px;
         width: 13px;
         height: 13px;
       }

       [type="checkbox"]:checked + label::before {
         width: 9px;
         height: 16px;
         left:4px;
         top:-1px;
       }
    }

    .stickyTableCell {
      position: sticky !important;
      left: 0;
      background-color: #ffffffd6 !important;
      z-index: 5;
    }

    @keyframes growOutUserInfoTitle {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .assigned-user {
      position: relative;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: space-around;

      .cross {
        position: absolute;
      }

      .assigned-user-pop-up {
        display: none;
      }

      &:hover {
        .cross {
          cursor: pointer;
          height: 16px;
          width: 16px;
          right: -8px;
          top: -6px;
          background-color: white;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 99px;
          z-index: 1;
          &:hover {
            background-color: #ede4e4;
          }
        }

        .assigned-user-pop-up {
          display: flex;
          opacity: 0;
          animation: 0.5s ease-out 0.5s 1 growOutUserInfoTitle forwards;
        }
      }
    }

    .status-button {
      width: 100%;
      height: 100%;
      border-radius: 0;
      font-weight: 400 !important;
    }

    .positive-status-button {
      background: #6bc950;
      border-radius: 2px;
      color: white;
      transition: all 0.25s linear;
      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }

    .negative-status-button {
      background: #d3d3d3;
      border-radius: 2px;
      //color: white;
      color: #3b3b3b;
      transition: all 0.25s linear;
      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
  }
}
