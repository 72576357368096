/*
  BY: Prasannadatta Kawadkar
  ON: 14 March 2024
  Modification: created this page for sales home
*/
.hoverEffectBox {
  opacity: 1;
}
.hoverEffectBox:hover {
  opacity: 0.9 !important;
}

.hoverEffectBox2 {
  opacity: 1;
}
.hoverEffectBox2:hover {
  opacity: 0.7 !important;
}

.main-content-container {
  gap: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.content-welcome-contianer {
  //height: 135px;
  width: 100%;
  //border: 1px solid green
}
.content-wedges-contianer {
  //height: 200px;
  width: 100%;
}
.main-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.main-single-grid-container {
  padding: 10px;
  position:relative;
  border-radius: 5px;
  border: 1px solid #ccc;
  //background-color: #f2f2f2;
}
.main-widget-container {
  height: 121px;
  //border: 1px solid red;
}

.ancestor-container {
 height: 100%;
 padding: 18px;
 border-radius: 5px;
 //border: 1px solid green;
}
.highcharts-container {
  padding-top: 20px;
}
.highcharts-title {
  font-size: 14px !important;
}
