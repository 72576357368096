.basic-tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  min-height: 0px;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  transition: all ease 0.3s;

  &:hover {
    //border-bottom: 1px solid #41635e;
    .basic-tab-container {
      color: #000;
    }
  }

  .basic-tab-container {
    display: flex;
    align-items: center;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin: 0 45px 0 0;
    padding: 4px 6px;
    min-height: 50px;
    max-height: 86px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    transition: all ease 0.3s;

    .basic-tab-text {
      display: inline-block;
      min-width: 0;
      height: 30px;
      //color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
      font-family: 'Roboto', sans-serif;
      height: fit-content;
    }
  }
}
.basic-tab.active {
  border-bottom: 1px solid #408b8b;
  .basic-tab-container {
    .basic-tab-text {
      color: #408b8b !important;
    }
  }
}
