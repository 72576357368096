/* The Modal (background) */
.orgChartModal {
  .org-chart-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    //padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content */
   .org-chart-width {
      width: 62%;
   }
   .org-chart-full-width {
      width: 98%;
   }




  .mobile-org-chart-modal-content {
      top: -574% !important;
      height: 82vh;
    }

  /*
    modified : Ashutosh G
    modified : 24/07/2023
    modification : commented code to remove animation from modal
  */
  .org-chart-modal-content {
    //position: relative;
    position: absolute;
    //top: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fefefe;
    margin: auto;
    //padding: 0;
    padding: 30px 30px;
    border: 1px solid #888;
    //width: 40%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    // -webkit-animation-name: animatetop;
    // -webkit-animation-duration: 0.35s;
    // animation-name: animatetop;
    // animation-duration: 0.35s;
    // animation-timing-function: linear;
    border-radius: 12px;
  }
  //Modification: Remove animations from all modals
  //By: Manohar
  //Date: 21/08/2023
  .org-chart-modal-content-close {
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 0.4s;
    animation-name: animatebottom;
    animation-duration: 0.4s
  }
  .show-formPopover {
    display:block !important;
  }

  .hide-formPopover {
    display:none !important;
  }

  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  /* Add Animation */
@-webkit-keyframes animatebottom {
  from {top:0; opacity:1}
  to {top:-300px; opacity:0}
}

@keyframes animatebottom {
  from {top:0px; opacity:1}
  to {top:-300px; opacity:0}
}

  /* The Close Button */
  .org-chart-close {
    //color: white;
    float: right;
    font-size: 28px;
    font-weight: 100;
  }

  .org-chart-close:hover,
  .org-chart-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .org-chart-modal-header {
    //padding: 2px 16px;
    //background-color: #5cb85c;
    box-sizing: border-box;
    color: #80868e;
    //display: inline-block;
    font-size: 14px;
    font-weight: bold;
    max-width: 100%;
    line-height: 21px;
    overflow: hidden;
    padding: 0 10px 0 10px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .org-chart-modal-body {
    padding: 10px;
    //background-color: #eef2f4;
    margin: 0px 10px;
    }

  .org-chart-modal-footer {
    padding: 2px 16px;
    //background-color: #5cb85c;
    //color: white;
  }
  label {
    font-size: 0.9rem;
    font-weight: 600;
  }


/* Modified By: Vihang
Modified On:23 - 25 May 2022
Modification: all the forms modal mobile responsive changes
*/



  @media (max-width: 480px) {
   .formModal {
      width:100% !important;
      height:100% !important;
      .right-drawer-label{
        top: 0;
        left: 0;
        transform: rotate(180deg);
      }
   }
    .enquiryForm {
      .formHeader , input , select {
        font-size:14px;
      }
      .formLabel {
        font-size:12px !important;
      }
      label {
        font-size: 10px !important;
        padding:0 5px;
      }
      input {
        margin:0;
      }
      input, select {
        height:40px;
      }
      input[type='month'], select {
        width:100%;
        border: 0.1rem solid #d1d1d1;
        border-radius: 0.4rem;
      }
      input[type='date'], input[type='time'] {
        width:50% !important;
      }
      .formRadioButtons, .formCheckbox {
          flex-direction:column;
      }
      .qty-input {
        input, button {
            width:2rem;
            height:2rem;
        }
        display:flex;
        align-items:center
      }
      .carCart {
        .viewCart {
          p {
            display:none;
          }
        }
      }
      .quotationCarSummary {
        font-size:12px;
        button {
          font-size:12px;
        }
        .whatsAppButton {
          padding:0;
        }
        .waitingPeriodText, .financeText {
          border-left:none !important;
          padding-left:0;
        }
        .lastGeneratedText {
          border-left:none !important;
        }
      }
    }
  }
  label {
    font-size: 0.9rem;
    font-weight: 600;
  }
  .org-chart-buttons {
    text-align: center;
    padding: 20px 0 10px;
    position: relative;
  }
  .org-chart-button-accept, .org-chart-button-create {
    background: #bbed21 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #535c69;
  }
  .org-chart-button {
    display: inline-block;
    height: 39px;
    line-height: 39px;
    margin: 0 12px 0 0;
    background: #ecedef;
    border-radius: 2px;
    cursor: pointer;
    color: #7a818a;
    font-size: 12px;
    font-weight: normal;
    outline: 0;
    padding: 0 18px;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    white-space: nowrap;
    -webkit-box-shadow: 0 0 0 1px #c6cdd3 inset;
    box-shadow: 0 0 0 1px #c6cdd3 inset;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: background-color .2s linear,color .2s linear;
    -o-transition: background-color .2s linear,color .2s linear;
    transition: background-color .2s linear,color .2s linear;
  }
  .org-chart-button:last-child {
    margin-right: 0;
  }
  .org-chart-button-link-cancel {
    color: #f1361b;
    border-bottom-color: #ffb4a9;
    background: #fff;
    border: 0 !important;
    box-shadow: none !important;
  }
  .org-chart-button-link {
    border-bottom: 1px solid #c0c2c5;
    border-radius: 0;
    color: #535c69;
    cursor: pointer;
    height: 17px;
    line-height: 17px;
    -webkit-transition: border-bottom-color .15s linear;
    -o-transition: border-bottom-color .15s linear;
    transition: border-bottom-color .15s linear;
    padding: 0;
  }


  /*
        modified by Vihang
        modified at 26/05/2022
        modification : scroll button adjustment for desktop and inline styles added to the formModalContainer
  */
  .formModalContainer {
      position:relative;
      padding-left:30px;
      padding-right:30px;
      padding-top:10px;
      padding-bottom:10px;
      border-top:1px solid lightgrey;
      overflow:hidden auto;
      //height:75vh;
      height:72vh;
      //box-shadow: 0px -2px 3px 0px rgb(133 133 133 / 10%) inset;
  }
  .scrollButton {
    //bottom:120px;
    //bottom:35px;
    bottom: 60px;
    right:5px;
  }


  /* Modified By: Vihang
  Modified On: 24 May 2022
  Modification: all the forms modal mobile responsive changes
  */

  .formModal {
      .enquiryForm {
        select,input {
          height:40px;
        }
      }
  }

  /* Modified By: Manohar
     Modification On: 15-07-2022
     Modification: #1165 Bug | Quotation Task selected model cost strip overlaps Next and Previous button
  */
  .carCart {
    position: absolute;
    top: 0;
    background: rgb(247 247 247);
    padding: 15px;
    color: #828282;
    width: 60%;
    margin: 0 auto;
    left: 30%;

    .viewCart {
      font-size:15px;
      font-weight:500;
      margin-right:5px;
      display:flex;
      align-items:center
    }
    .carCost {
      font-size:15px;
      font-weight:500;
      margin-right:5px;
    }
    .cartValue {
      font-size:20px;
      font-weight:500;
      margin-right:5px;
      margin-left:5px;
    }
    .selectedText {
      font-size:15px;
      font-weight:500;
      margin-right:5px;
    }
    .selectedCarCount {
      font-size:20px;
      font-weight:500;
      margin-right:5px;
    }
  }
  .noteDiv {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: rgb(247 247 247);
    padding: 15px;
    color: #808080;
  }
}

/*
      modified by Vihang
      modified at 26/05/2022
      modification : scroll button adjustment for mobile
*/
@media screen and (max-width: 550px) {
  .org-chart-width {
     width: 90% !important;
   }
   .org-chart-product {
      padding: 10px 30px 10px 10px !important;
   }
   .orgChartModal {
     .scrollButton {
         bottom:30px
     }
   }
}
