.accountability-info-card {
  min-width: 200px;
  width: 200px;
  height: 225.88px;

  background: #EFEFEF;
  border: 1px solid #DFDFDF;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Poppins';

  .accountability-info-card-head {
    // background: #BCD3E1;
    background: linear-gradient(90deg, rgba(230, 174, 174, 0.5) 0%, #BCD3E1 100%);
    border-radius: 8px 8px 0px 0px;
    height: 36px;


    .accountability-info-card-head-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #002C5F;
    }

    .accountability-info-card-head-sub-text {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #002C5F;
    }

    .accountability-info-card-options-card {
      position: absolute;
      right: 0;
      top: 0;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      display: grid;
      gap: 8px;

      font-weight: 400;
      font-size: 10px;
      line-height: 20px;
      color: #3C3C3C;
    }
  }

  .accountability-info-card-body {
    height: calc(100% - 36px);

    .accountability-info-card-information {
      padding: 0 16px;
      .accountability-info-card-information-primary {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #002C5F;
        margin-bottom: 2px;
      }
      .accountability-info-card-information-secondary {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #808080;
      }
    }

    .accountability-info-card-assignee {
      padding: 12px 16px;
    }

    .accountability-info-card-nudge {
      background: linear-gradient(90deg, rgba(237, 178, 178, 0.5) 18.75%, rgba(245, 234, 234, 0.5) 100%);
      box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
      border-radius: 2px;
      height: 24px;
      //padding: 0 17px;
    }
    .no-nudge-card {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.02) 47.92%, rgba(255, 255, 255, 0) 100%);
    }
    .no-nudge-card, .accountability-info-card-nudge {
      .static-gif {
        background: none;
      }
      .active-gif {
        opacity: 0;
      }

      &:hover {
        .static-gif {
          display: none;
        }
        .active-gif {
          opacity: 1;
        }
      }
    }

    .accountability-info-card-primary-button {
      // padding: 8px 16px;
      cursor: pointer;
      margin: 0 16px 16px;
      height: 33.89px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #DFDFDF;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: rgba(0, 44, 95, 0.5);

      path.accountability-info-edit-note {
        stroke: #BFBFBF;
      }
      g.custom-search-icon {
        fill: #BFBFBF !important;
      }

      &:hover {
        color: #FFFFFF;
        background: #88B7D2;

        path.accountability-info-edit-note {
          stroke: #FFFFFF;
        }
        g.custom-search-icon {
          fill: #FFFFFF !important;
        }
      }
    }
  }
}

.accountability-info-card {
  font-size: 12px;

  &:hover {
    // background: #F5F5F5;
    // border-color: rgba(0,44,95,0.6);
    // cursor: pointer !important;

    //.static-gif {
    //  background:#f1f2f3 !important;
    //}
  }

  .accountability-info-card-col  {
      padding-top: 8px;
      padding-bottom: 8px;
  }

  .accountability-info-card-priority {
      width: 16px;
      border: 1px solid rgba(0, 44, 95, 0.25);
      height: 100%;
      border-radius: 5px 0px 0px 5px;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .accountability-info-card-priority-p1 {
      background: #f87a53;
  }
  .accountability-info-card-priority-p2 {
      background: #ffee59;
  }
  .accountability-info-card-priority-p3 {
      background: #05e700;
  }

  .accountability-info-card-priority-lead {
      background: #f8a67b;
  }
  .accountability-info-card-priority-inProcess {
      background: #98c1d9;
  }
  .newList-edit-btn:hover {
    span{
        svg {
          path {
            stroke: #5f81ff !important;
          }
        }
      }
    }

  .newList-add-btn:hover {
    span{
      svg {
        path {
          fill: #5f81ff !important;
        }
      }
    }
  }

  .accountability-info-card-col {

    .accountability-info-card-pill {
        border: 0.5px solid #E74C7D;
        border-radius: 9px;
        height:18px;
        color: #E64C7D;
        width: fit-content;
        padding: 3px 8px;
        line-height: 1;

    }
    .accountability-info-card-businessImpact-pill {
        border: 0.5px solid #728BCE;
        border-radius: 9px;
        height:18px;
        color: #fff;
        background: #728BCE;
        width: fit-content;
        padding: 3px 8px;
        line-height: 1;

    }
    .accountability-info-card-processImpact-pill {
        border: 0.5px solid #C9F57E;
        border-radius: 9px;
        height:18px;
        color: #002c5f80;
        background: #C9F57E;
        width: fit-content;
        padding: 3px 8px;
        line-height: 1;

    }
    .accountability-info-card-notSelected-pill {
        border: 0.5px solid #002c5f;
        border-radius: 9px;
        height:18px;
        color: #002c5f;
        width: fit-content;
        padding: 3px 8px;
        line-height: 1;
    }
    .accountability-info-card-pill2 {
        border: 0.5px solid #002C5F;
        border-radius: 5px;
        width: 21px;
        height: 21px;
        border-radius:50%;
        color: #002C5F;
        padding: 0 6px;
        display: flex;
        justify-content: center;
        align-items:center;

    }
  }


  .reminder-transition-on {
    transition: 0.2s transform ease;
    transform: scaleY(1);
    transform-origin: top;
  }
  .reminder-transition-off {
    transition: 0.2s transform ease;
    transform: scaleY(0);
    transform-origin: top;
  }

  .reminder-container {
    width: 200px;
    height: 112px;
    border-radius: 5px;
    background: white;
    border: 1px solid #bfbfbf;
    position: absolute;
    right: 0;
    top: 27px;
    z-index: 3;
    padding: 8px;

    .reminder-save-btn {
      background: #d9d9d9;
      border-radius: 5px;
      max-width: 36px;
      height: 20px;
      font-size: 10px;
      padding: 0;
      min-width: 32px;
      margin-left: auto;
      margin-top: 2px;
      color: #3C3C3C;
    }

    .reminder-svg {
      right: 13px;
      bottom: 33px;
      position: absolute;
    }
  }
}

.accountability-info-card-priority:hover, .accountability-info-card-processImpact:hover, .accountability-info-card-businessImpact:hover {
  .list-edit-btn {
    display: flex !important;
  }
  .priority-stuck {
    display: none !important;
  }
}
