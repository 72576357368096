/*
  Modification: T1646 - Redefined CSS UI of the fileViewer
  By: Devang
  Date: 02/04/2024
*/
/*
  Modification: The comment indicates that a left margin of -25px was applied, causing the modal to be more shifted to the left.
  By: Arun Singh
  Date: 21/06/2024
*/
.modal-outer-container{
    width:100vw;
    height: 100vh;
    //z-index: 4;
     z-index: 1000;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0px;
    //left: -25%;
}

.modal-inner-container{
    width: 90%;
    height: 90%;
    background: #FFFFFF;
    top: 50%;
    // Modification: Commented left: 58% and added left: 50% for making inner container center
    // Date: 29/08/2022
    // By: Devang
    //left: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 4px;
    //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.modal-inner-container-40{
    width: 90%;
    height: 90%;
    background: #FFFFFF;
    top: 50%;
    // Modification: Commented left: 58% and added left: 50% for making inner container center
    // Date: 29/08/2022
    // By: Devang
    //left: 58%;
    left: 40%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 4px;
    //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.modal-top-styler{
    width: 100%;
    height: 4.22px;
    background-color: #C4C4C4;
    border-radius: 4px 4px 0 0;
}

.modal-inner-container .modal-inner-body{
    width: 100%;
    height: 100%;
    padding: 8px 16px;
}

.modal-top-bar{
    width: 100%;
}

.modal-file-txt{
    font-size: 14px;
    color:#5F6368;
}

/*
  Bug: T1692 - 14 - Mobile - Task View - Case Documents - Cross icon - There is no cross icon to close the view document modal
  Modification: Added class to handle visibility of close button
  By: Devang
  Date: 14/04/2024
*/
.file-viewer-close-button {
  display: none;
}

.modal-file-subtext{
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 14px;
    color:#5F6368;
}

.modal-body{
    width: 100%;
    height: 85%;
}
.modal-body-file-viewer {
    width: 100%;
    height: 100%;
}

.file-list-container{
    width: 20%;
    height: 100%;
    /*
      Modification: Fixed gutter width
      By: Devang
      Date: 06/04/2024
    */
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    //box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.file-top-styler{
    width: 100%;
    height: 4px;
    //background-color: #68ABDD;
    border-radius: 4px 4px 0 0;
}

.file-list-scroll-view{
    border: 0.2px solid #526f95;

    width: 100%;
    direction: rtl;
    //min-height: calc(100% - 100px);
    height: calc(100% - 136px);
    overflow-y: auto;
    //margin-top: 5px;
}


.file-list-title-container{
    direction: ltr;
    margin: 8px;
}

.file-list-title{
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #5F6368;
}

.file-checkbox{
    background: #EDEDED;
    //box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    //width: 12px;
    //height: 12px;
    width: 14px;
    height: 14px;
}

.file-title-container{
    width: 98%;
    direction: ltr;
    cursor: pointer;
    padding: 4px 0;
    margin: 0 2px 6px 0;
    .selected-arrow {
      visibility: hidden;
    }
}

.file-title-container:hover{
    background-color: #f5f5f5 !important;
    .selected-arrow {
      visibility: visible;
    }
}

.file-title-container.active{
    background-color: #F2F2F2 !important;
    .selected-arrow {
      visibility: visible;
    }
}

.upload-file-name{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: 4px;
}

.title-under-score{
    border-bottom: 0.5px solid #DBDBDB;
    height: 9px;
    margin-left: 5px;
}

.file-list-footer{
    width: 100%;
    height: 20px;
    background-color: #F2F2F2;
    margin-top: 6px;
    border-radius: 0 0 4px 4px;
    align-items: center;
}

.file-viewer-container{
    /*
      Modification: Fixed gutter width
      By: Devang
      Date: 06/04/2024
    */
    width: calc(100% - 20% - 16px);
    height: 100%;
    border-radius: 4px;
    border: 0.2px solid #e1e1e1;
    //box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
}
//By:Yash
//Date:12th April 2022
//Modification:Changed color to white
.file-viewer-topbar{
    width: 100%;
    height: 30px;
    background: #FFFFFF;
    //box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0px 0px;
    border: 0.2px solid #e1e1e1;
    box-sizing: border-box;
    align-items: center;
}

.modal-preview-img-container {
  width: 100%;
  text-align:center;
  overflow: hidden;
  height: 100%;

  .modal-preview-image {
    max-width:100%;
    max-height:100%;
    height: auto;
    width: auto;
    object-fit: cover;
  }
}

.button-icon-container{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    cursor: pointer;
}

.button-icon-container:hover{
    // border: 1px solid #757575;
    background-color: #FAFAFA;
}

.button-icon-container:hover .button-icon{
    width: 14px;
    height: 14px;
    transition: 0.2s;
}

.file-viewer-button-icon-container{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    cursor: pointer;
}

.file-viewer-button-icon-container:hover{
    //border: 1px solid #757575;
    //background-color: #FFFFFF;
}

.file-viewer-button-icon-container:hover .file-viewer-button-icon{
    width: 18px;
    height: 18px;
    transition: 0.1s;
}

.file-name-viewer{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #454545;
}


.file-viewer-body{
    width: 100%;
    height: calc(100% - 80px);
    border-radius: 5px;
}
//By:Yash
//Date:12th April 2022
//Modification:Changed color to white
.file-viewer-bottombar{
    width: 100%;
    height: 30px;
    background: #FFFFFF;
    //box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 4px 4px;
    border: 0.2px solid #e1e1e1;
    box-sizing: border-box;
}

.file-viewer-message{
    font-size: 12px;
}

.file-viewer-empty-message {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #AEAEAE;
}


.file-viewer-mob{
  z-index: 1001;
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  height: 92vh;
  background: #F2F2F2;
  padding: 8px;
  transition: 0.2s;
  border-radius: 4px 4px 0 0;
  display: none;
  animation-duration: 0.2s;
	animation-iteration-count: 1;
	animation: upSlider 0.2s;
}

@keyframes upSlider{
    0%{
        transform: translate(0px, 10px);
		opacity: 0.8;
    }

    100%{
        transform: translate(0, 0);
		opacity: 1;
    }
}

.file-name-mob{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #454545;
}

.file-view-container-mob{
    width: 100%;
    height: 88%;
    margin: 8px auto;
}


.file-viewer-docs {
  width: 80%;
  height: 100%;
  display: flex;
  position: relative;
  padding: 0;
  margin: auto;
	//filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	//box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}

.file-viewer-docs-arrows {
	width: 25px;
	height: 25px;
	position: absolute;
	transform: translateY(-50%);
	cursor: pointer;
	bottom: 50px;
	&.left-arrow {
		right: 70px;
	}
	&.right-arrow {
		right: 38px;
	}
}

/*
  Bug: T1692 - 14 - Mobile - Task View - Case Documents - Cross icon - There is no cross icon to close the view document modal
  Modification: Added class to handle visibility of close button
  By: Devang
  Date: 14/04/2024
*/
@media screen and (min-width:0px) and (max-width:799px) {
  .file-viewer-close-button {
    display: flex;
  }
}

@media screen and (min-width:0px) and (max-width:550px) {
    .modal-inner-container{
        width: 100%;
        height: 92vh;
        background: #FFFFFF;
        top: unset;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        position: absolute;
        border-radius: 4px;
        //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .file-list-scroll-view{
        //margin-left: -6px;
        //min-width: 102%;
        //max-width: 102%;
    }

    .file-list-footer{
        position: absolute;
        width: 100%;
        height: 40px;
        bottom:0;
        left:0;
        background: #F5F5F5;
    }

    .button-icon-container{
        margin-left: 12px !important;
        margin-right: 4px !important;
    }

    .modal-inner-container .modal-inner-body{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 10px;
    }

    .modal-file-txt{
        color: #5F6368;
        font-weight: 500;
    }

    .modal-top-styler{
        display: none;
    }

    .file-list-title-container{
        direction: ltr;
        margin: 4px;
    }

    .file-list-container{
        width: 100%;
        margin-right: 0;
        justify-content: flex-start;
        //box-shadow: none;
    }

    .file-top-styler{
        display: none;
    }

    .file-viewer-container{
        display: none;
    }

    .modal-outer-container{
        // z-index: 7;
        left: 0;
    }

    .file-viewer-mob{
        display: block;
    }

    .file-viewer-docs {
      width: 100%;
      height: max-content !important;
      margin: 0;
    }

    .svg-file-viewer-icon {
      width: 20px;
      height: 20px;
      margin-right: 9px;
    }

    .svg-file-viewer-tick {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .file-checkbox {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .selected-arrow {
      margin-top: 2px;
    }

    .responsive-index-modal {
      width: 90% !important;
    }
}

@media screen and (min-width:551px) and (max-width:799px) {
    .modal-inner-container{
        width: 100%;
        height: 92vh;
        background: #FFFFFF;
        top: unset;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        position: absolute;
        border-radius: 4px 4px 0 0;
        //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .file-list-scroll-view{
        //margin-left: -6px;
        //min-width: 102%;
        //max-width: 102%;
        //min-height: calc(62% - 100px) !important;
        //height: calc(62% - 100px) !important;
    }

    .file-list-footer{
        position: absolute;
        width: 100%;
        height: 40px;
        bottom:0;
        left:0;
        background: #F5F5F5;
    }

    .button-icon-container{
        margin-left: 12px !important;
        margin-right: 4px !important;
    }

    .modal-inner-container .modal-inner-body{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 10px;
    }

    .modal-file-txt{
        color: #5F6368;
        font-weight: 500;
    }

    .modal-top-styler{
        display: none;
    }

    .file-list-title-container{
        direction: ltr;
        margin: 4px;
    }

    .file-list-container{
        width: 100%;
        margin-right: 0;
        justify-content: flex-start;
        //box-shadow: none;
    }

    .file-top-styler{
        display: none;
    }

    .file-viewer-container{
        display: none;
    }

    .modal-outer-container{
        // z-index: 7;
    }

    .file-viewer-mob{
        display: block;
    }

    .file-viewer-docs {
      width: 100%;
      height: max-content !important;
      margin: 0;
    }

    .svg-file-viewer-icon {
      width: 20px;
      height: 20px;
      margin-right: 9px;
    }

    .svg-file-viewer-tick {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .file-checkbox {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .selected-arrow {
      margin-top: 2px;
    }

    .responsive-index-modal {
      width: 90% !important;
    }
}
