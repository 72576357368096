.header-tab {

  .search-content-head {
    width: 36vw;
    //padding: 1em;
    //box-shadow: 0 0 1em rgba(0,0,0,.15);
    position: relative;
    margin: 0 auto 4px auto;
  }

  .search-head {
    --size: 30px;
    border: 1px solid #d0e7f7;
    display: flex;
    border-radius: 100px;
    overflow: hidden;
    font-size: 1.25em;
    position: relative;
    width: var(--size);
    height: var(--size);
    // margin-left: auto;
    transition: width 350ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    padding: 3px;
    width: 100%;

      button {
        min-width:22px;
        padding:3px;
      }
}

  #search__input-head {
      border: 0;
      padding: .25em 1em !important;
      flex-grow: 1;
      //outline: 0;
      z-index: 2;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background: transparent;
      //opacity: 0;
      cursor: pointer;
      color:white !important;
    }

    .search__submit-head {
      font-size: 1.5rem;
      margin-left: auto;
      background: 0;
      border: 0;
      cursor: pointer;
      border-radius: 50%;
      transition: background 200ms ease-out;
      width: calc(var(--size) - 10px);
      height: calc(var(--size) - 10px);
      opacity:0;
    }

    .search-head:focus-within {
      // box-shadow: 0 0 5px var(--clr-primary);
      width: 100%;
      border: 1px solid #d0e7f7;


    #search__input-head {
      opacity: 1;
      z-index: initial;
      cursor: initial;
      width: calc(100% - var(--size));
      color:white !important;
      text-decoration:none;
    }

    .search__submit-head {
      //background: var(--clr-primary);
      color: white;
      opacity:1;
      //box-shadow: 0 0 10px rgba(0,0,0,.15);
      // transition: transform 500ms ease-out;

    }
    .search__submit-head:hover,
    .search__submit-head:focus {
      outline: 0;
      // transform: rotate(1turn);
      box-shadow: 0 0 10px rgba(0,0,0,.65);
    }
    *:focus{
    outline:0;
    }
  }



  height:50px !important;
  background: #fbfbfb00
  //width: 65px;
  //text-align: center;
  position: relative;
  //padding-top: 10px;

  .header-tab-container {
    width: 120px;
    padding: 10px 7px 7px 7px;
    border-bottom: 1px solid rgba(0,0,0,0.051);
  }
  .companyName {
    padding: 11px 5px 5px 10px;
    font-size: 1.2rem;
    color: #fff;
  }
}
.task-floating-button, .view-deal-button, .view-timeline-button {
	position: absolute;
	box-shadow: 0px 2px 5px #666;
	cursor: pointer;
	width: max-content;
	height: 32px;
	padding: 6px;
	background: #408b8b;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
}
.view-deal-button {
  left: 1000px;
  top: 10px;
	bottom: 20px;
	//right: 119px;
	z-index: 3;
}
.view-timeline-button {
  left: 900px;
  top: 10px;
	bottom: 20px;
	//right: 205px;
	z-index: 4;
}
.arrow-icon{
  margin-left: 194px;
  margin-top: 8px;
}

@media screen and (max-width: 425px) {

   .mobile-header1{
    display:flex;
    align-items:center;
    padding: 0.6rem 0 0 0.6rem !important;
    font-size: 1rem !important;
    font-weight:500;
    letter-spacing:1px;
    justify-content: flex-start !important;
    background-color:#002c5f;
    width: 100%;
    }
    .mobile-headerTitle{
    //letter-spacing: 1.5px;
    //font-weight: 200 !important;
    }

}
