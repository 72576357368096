.items-toolbar {
  width: 100%;
  background: #2b2d34;
  color: #d4d5d6;
  padding: 4px 8px;
  z-index: 999;
  font-size: 14px;

  animation: 0.5s ease-out 0s 1 slideInFromLeft;

  .toolbar-display-radio-input:checked+label:after {
    border: 1px solid #64696d !important;
    background-color: #2a2e34 !important;
    outline: 2px solid #ffffff;
    outline-offset: -3px;
  }

  label:after, label:before {
    margin: 0 !important;
  }

  .dismiss-btn {
    font-size: 11px;
    border-radius: 16px;
    background: #2b2d34;
    padding: 4px 12px;
    right: 0;
    bottom: 100%;
    border: 1px solid #828588;

    svg {
      transition: all 0.25s linear;
    }

    &:hover {
      border-color: #5f81ff;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
