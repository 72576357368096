.kanban {
  .kanban-column {
    padding: 10px;
    padding-bottom: 50px;
    //border-radius: 2px;
    margin-left: 10px;
    min-width: 16vw;
    width: max-content;
    height: 100%;
    border-radius: 12px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .kanban-column-header {
    // position: sticky;
    // top:0;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #121926;
    background: #f8fafc;
    border-radius: 8px;
    //position: absolute;
    z-index: 1;
  }

  .kanban-card {
    border-radius: 12px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    max-width: 352px;
    min-width: 312px;
    font-weight: 500;
    border: 1px solid #fff;
    cursor: grab;
    color: #252525;
    transition: margin-top 5s ease;
  }

  .kanban-card :after {
    margin-top: 40px;
  }
}

.dragging {
  opacity: 0.5;
}

.kanban-card-pill {
  border: 1px solid #808080;
  width: fit-content;
  padding: 1px 4px;
  border-radius: 8px;
}
