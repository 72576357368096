.add-contact {
	&:hover {
		g {
			fill: #5f81ff;
		}
	}
}

.delegate-contact {
	&:hover {
		path {
			stroke: #5f81ff;
		}
	}
}
