.grouped-items-accordion {
  //overflow: hidden;
  .accordion-table-main {
    //width: 100%;
    display: flex;
    font-size: 12px;
    flex-direction:row;
  }
  .accordion-table-row {
    //width: 100%;
    display: flex;
    font-size: 12px;
    flex-direction:row;
    padding: 4px 0;

    .accordion-table-header {
      //background: #DBDBDB;
      //font-weight: 600;
      padding: 4px;
      color: #adb3bd;
      font-size: 10px;
      text-transform: uppercase;
      padding-bottom: 0px !important;
    }

    .accordion-table-data {
      font-size: 13.5px;
      color: #65676acc;
      padding: 0 4px;
    }

    .accordion-table-header, .accordion-table-data {
      display: flex;
      //align-items: flex-end;
      //overflow: hidden;
    }

    .arrow-field span, .accordion-table-header span, .accordion-table-data span, .accordion-table-data .main-col span {
      width: 100%;
      white-space: nowrap;
      //overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 2px;
    }

    .arrow-field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .accordion-table-main > .down-arrow-field > .down-arrow-container > svg {
    transition: all .25s linear;
    transform: rotate(-90deg);
  }

  [type="checkbox"]+label {
    padding: 0 !important;
  }

  ul {
    margin: 0 !important;
  }

  .clickable {
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }

  .accordion-heading-main {
    padding: 4px 8px;
    font-weight: 400;
  }

  .accordion-category-heading {
    padding: 4px;
  }

  .accordion-heading-main, .accordion-category-heading {
    //border-radius: 20px;
    color: white;
    font-size: 11px;
    font-wieght: 400;
  }

  .cd-container-container {
    //overflow: hidden !important;
    padding: 0px;
  }

  .cd-accordion {
    //background: var(--cd-color-1);
    @include fontSmooth;
    //box-shadow: var(--shadow-lg);
    [type="checkbox"]:checked+label:before {
      content: none;
    }
    [type="checkbox"],[type="checkbox"]+label:before, [type="checkbox"]:not(.filled-in)+label:after {
      content: none;
      position: absolute;
      left: -9999px;
      opacity: 0;
    }
  }

  .cd-accordion--animated .cd-accordion__label::before {
    transition: transform .3s;
  }

  .cd-accordion__sub {
    display: none; // by default hide all sub menus
    //overflow: hidden;
  }

  .cd-accordion__sub--is-visible {
    display: block;
  }

  .cd-accordion__item {
    user-select: none;
  }

  .cd-accordion__input { // hide native checkbox
    position: absolute;
    opacity: 0;
  }

  .cd-accordion__label {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--space-sm) var(--space-md);
    //background: var(--cd-color-1);
    //--color-shadow: lightness(var(--cd-color-1), 1.2);
    box-shadow: inset 0 -1px var(--color-shadow);
    color: var(--color-white);

    span {
      order: 3;
    }

    &:hover {
      //background: lightness(var(--cd-color-1), 1.1);
    }
  }

  // icons
  .cd-accordion__label::after, .cd-accordion__label--icon-folder::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    //background-image: url('../img/cd-icons.svg');
    background-repeat: no-repeat;
    margin-right: var(--space-xxxs);
  }

  .cd-accordion__label--icon-folder::before {
    order: 1;
  }

  .cd-accordion__label::after {
    order: 2;
  }

  .cd-accordion__label--icon-folder {
    &::before { // arrow icon
      background-position: 0 0;
      transform: rotate(-90deg);
    }

    &::after { // folder icon
      background-position: -16px 0;
    }
  }

  .cd-accordion__label--icon-img::after { // image icon
    background-position: -48px 0;
  }

  .cd-accordion__input:checked + .cd-accordion__label::before { // rotate arrow
    transform: rotate(0);
  }

  .cd-accordion__input:checked + .cd-accordion__label {
    svg {
      transform: rotate(0deg);
    }
  }

  .cd-accordion__input:checked + .cd-accordion__label::after { // show open folder icon if item is checked
    background-position: -32px 0;
  }

  .cd-accordion__input:checked ~ .cd-accordion__sub { // show children when item is checked
    display: block;
  }

  //.cd-accordion__label .accordion-table-row .accordion-head-collapsible {
  //  display: none;
  //}

  //.cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-collapsible { // show children when item is checked
  //  display: block;

  //  .accordion-heading-main {
  //    border-radius: 4px 4px 0 0;
  //  }
  //}

  .cd-accordion__label .accordion-table-row .first-main-column .accordion-heading-main {
    border-radius: 4px;
  }
  .cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .first-main-column .accordion-heading-main {
    border-radius: 4px 4px 0 0;
  }


  // TO PREVENT OVERFLOW without any accordion open
  .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible-container {
    display: none;
  }
  .cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible-container { // show children when item is checked
    display: flex;
  }






  //.cd-accordion__label .accordion-table-row .accordion-head-uncollapsible {
  //  display: block;

  //  .accordion-heading-main {
  //    border-radius: 4px;
  //  }
  //}

  //.cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible { // show children when item is checked
  //  display: none;
  //}

  .cd-accordion__sub--l1 {
    .cd-accordion__label {
      //background: lightness(var(--cd-color-1), 0.65);
      //--color-shadow: lightness(var(--cd-color-1), 0.85);
      box-shadow: inset 0 -1px var(--color-shadow);
      padding-left: calc(var(--space-md) + 16px);

      &:hover {
        //background: lightness(var(--cd-color-1), 0.75);
      }

      .arrow-field {
        padding-left: 24px;
      }
    }
    .accordion-table-row {
      .accordion-table-data {
        color: #3b3b3b !important;
      }
      background: #ffffff !important;

      .can-hide-radio {
        display: none;
      }
      .can-hide-radio-input:checked+label:after {
        border: 1px solid #64696d !important;
        background-color: #2a2e34 !important;
        outline: 2px solid #ffffff;
        outline-offset: -3px;
      }
      .can-hide-radio-input:checked ~ .can-hide-radio {
        display: block;
      }

      &:hover {
        background: #fafbfc !important;

        .first-main-column {
          background: #fafbfc !important;
        }
        .can-hide-radio {
          display:block;
        }

      }
    }
  }

  .cd-accordion__item:last-child .cd-accordion__label {
    box-shadow: none;
  }

  .accordion-button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    font-weight: 400 !important;
  }

  .info-accordion-button {
    background: grey;
    color: white;
    transition: all 0.25s linear;
    &:hover {
      background: darkgrey;
    }
  }

  .inward-bottom-separator {
    //box-shadow: inset 0 0 10px #0000000d;

    border: 1px solid #fff;
    //box-shadow: 0 0 10px #0000000d;
    border-bottom: 1px solid #0000000d;
  }

  .accordion-button-container svg {
    transition: all 0.25s linear;
  }

  .accordion-button-container svg:hover {
    transform: scale(1.4);
  }

  .block-svg-container {
    position: relative;
    //overflow: hidden;
    height: 20px;
    padding: 0 2px;
    margin-left: 4px;
    display: inline-block;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #e9ebf0;
  }

  .first-main-column {
    position: sticky;
    left: 0;
    background: #fff !important;
    z-index: 999;
  }

  .grouped-item-info-title-pop-up {
    display: none;
  }

  .assigned-user:hover > .grouped-item-info-title-pop-up {
    display: flex;
    opacity: 0;
    animation: 0.5s ease-out 0.5s 1 growOutUserInfoTitle forwards;
  }

  @keyframes growOutUserInfoTitle {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .assigned-user {
    position: relative;
    .cross {
      position: absolute;
    }

    &:hover {
      .cross {
        cursor: pointer;
        height: 16px;
        width: 16px;
        right: -8px;
        top: -6px;
        background-color: white;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 99px;
        z-index: 1;
        &:hover {
          background-color: #ede4e4;
        }
      }
    }
  }
}

/*
  Modification: Added new class
  By: Masum Raja
  Date: 23/10/2023
*/

.grouped-items-accordion-v2 {
  //overflow: hidden;
  .accordion-table-main {
    //width: 100%;
    display: flex;
    font-size: 12px;
    flex-direction:row;
  }
  .accordion-table-row {
    //width: 100%;
    display: flex;
    font-size: 12px;
    flex-direction:row;
    padding: 4px 0;

    .accordion-table-header {
      //background: #DBDBDB;
      //font-weight: 600;
      padding: 4px;
      color: #adb3bd;
      font-size: 10px;
      text-transform: uppercase;
      padding-bottom: 0px !important;
    }

    .accordion-table-data {
      font-size: 13.5px;
      color: #65676acc;
      padding: 0 4px;
    }

    .accordion-table-header, .accordion-table-data {
      display: flex;
      //align-items: flex-end;
      //overflow: hidden;
    }

    .arrow-field span, .accordion-table-header span, .accordion-table-data span, .accordion-table-data .main-col span {
      width: 100%;
      white-space: nowrap;
      //overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 2px;
    }

    .arrow-field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .accordion-table-main > .down-arrow-field > .down-arrow-container > svg {
    transition: all .25s linear;
    transform: rotate(-90deg);
  }


  .clickable {
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }

  .accordion-heading-main {
    padding: 4px 8px;
    font-weight: 400;
  }

  .accordion-category-heading {
    padding: 4px;
  }

  .accordion-heading-main, .accordion-category-heading {
    //border-radius: 20px;
    color: white;
    font-size: 11px;
    font-wieght: 400;
  }

  .cd-container-container {
    //overflow: hidden !important;
    padding: 0px;
  }

  .cd-accordion {
    //background: var(--cd-color-1);
    @include fontSmooth;
    //box-shadow: var(--shadow-lg);
    [type="checkbox"]:checked+label:before {
      content: none;
    }
    [type="checkbox"],[type="checkbox"]+label:before, [type="checkbox"]:not(.filled-in)+label:after {
      content: none;
      position: absolute;
      left: -9999px;
      opacity: 0;
    }
  }

  .cd-accordion--animated .cd-accordion__label::before {
    transition: transform .3s;
  }

  .cd-accordion__sub {
    display: none; // by default hide all sub menus
    //overflow: hidden;
  }

  .cd-accordion__sub--is-visible {
    display: block;
  }

  .cd-accordion__item {
    user-select: none;
  }

  .cd-accordion__input { // hide native checkbox
    position: absolute;
    opacity: 0;
  }

  .cd-accordion__label {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--space-sm) var(--space-md);
    //background: var(--cd-color-1);
    //--color-shadow: lightness(var(--cd-color-1), 1.2);
    box-shadow: inset 0 -1px var(--color-shadow);
    color: var(--color-white);

    span {
      order: 3;
    }

    &:hover {
      //background: lightness(var(--cd-color-1), 1.1);
    }
  }

  // icons
  .cd-accordion__label::after, .cd-accordion__label--icon-folder::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    //background-image: url('../img/cd-icons.svg');
    background-repeat: no-repeat;
    margin-right: var(--space-xxxs);
  }

  .cd-accordion__label--icon-folder::before {
    order: 1;
  }

  .cd-accordion__label::after {
    order: 2;
  }

  .cd-accordion__label--icon-folder {
    &::before { // arrow icon
      background-position: 0 0;
      transform: rotate(-90deg);
    }

    &::after { // folder icon
      background-position: -16px 0;
    }
  }

  .cd-accordion__label--icon-img::after { // image icon
    background-position: -48px 0;
  }

  .cd-accordion__input:checked + .cd-accordion__label::before { // rotate arrow
    transform: rotate(0);
  }

  .cd-accordion__input:checked + .cd-accordion__label {
    svg {
      transform: rotate(0deg);
    }
  }

  .cd-accordion__input:checked + .cd-accordion__label::after { // show open folder icon if item is checked
    background-position: -32px 0;
  }

  .cd-accordion__input:checked ~ .cd-accordion__sub { // show children when item is checked
    display: block;
  }

  //.cd-accordion__label .accordion-table-row .accordion-head-collapsible {
  //  display: none;
  //}

  //.cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-collapsible { // show children when item is checked
  //  display: block;

  //  .accordion-heading-main {
  //    border-radius: 4px 4px 0 0;
  //  }
  //}

  .cd-accordion__label .accordion-table-row .first-main-column .accordion-heading-main {
    border-radius: 4px;
  }
  .cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .first-main-column .accordion-heading-main {
    border-radius: 4px 4px 0 0;
  }


  // TO PREVENT OVERFLOW without any accordion open
  .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible-container {
    display: none;
  }
  .cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible-container { // show children when item is checked
    display: flex;
  }






  //.cd-accordion__label .accordion-table-row .accordion-head-uncollapsible {
  //  display: block;

  //  .accordion-heading-main {
  //    border-radius: 4px;
  //  }
  //}

  //.cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible { // show children when item is checked
  //  display: none;
  //}

  .cd-accordion__sub--l1 {
    .cd-accordion__label {
      //background: lightness(var(--cd-color-1), 0.65);
      //--color-shadow: lightness(var(--cd-color-1), 0.85);
      box-shadow: inset 0 -1px var(--color-shadow);
      padding-left: calc(var(--space-md) + 16px);

      &:hover {
        //background: lightness(var(--cd-color-1), 0.75);
      }

      .arrow-field {
        padding-left: 24px;
      }
    }
    .accordion-table-row {
      .accordion-table-data {
        color: #3b3b3b !important;
      }
      background: #ffffff !important;

      .can-hide-radio {
        display: none;
      }
      .can-hide-radio-input:checked+label:after {
        border: 1px solid #64696d !important;
        background-color: #2a2e34 !important;
        outline: 2px solid #ffffff;
        outline-offset: -3px;
      }
      .can-hide-radio-input:checked ~ .can-hide-radio {
        display: block;
      }

      &:hover {
        background: #fafbfc !important;

        .first-main-column {
          background: #fafbfc !important;
        }
        .can-hide-radio {
          display:block;
        }

      }
    }
  }

  .cd-accordion__item:last-child .cd-accordion__label {
    box-shadow: none;
  }

  .accordion-button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    font-weight: 400 !important;
  }

  .info-accordion-button {
    background: grey;
    color: white;
    transition: all 0.25s linear;
    &:hover {
      background: darkgrey;
    }
  }

  .inward-bottom-separator {
    //box-shadow: inset 0 0 10px #0000000d;

    border: 1px solid #fff;
    //box-shadow: 0 0 10px #0000000d;
    border-bottom: 1px solid #0000000d;
  }

  .accordion-button-container svg {
    transition: all 0.25s linear;
  }

  .accordion-button-container svg:hover {
    transform: scale(1.4);
  }

  .block-svg-container {
    position: relative;
    //overflow: hidden;
    height: 20px;
    padding: 0 2px;
    margin-left: 4px;
    display: inline-block;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #e9ebf0;
  }

  .first-main-column {
    position: sticky;
    left: 0;
    background: #fff !important;
    z-index: 999;
  }

  .grouped-item-info-title-pop-up {
    display: none;
  }

  .assigned-user:hover > .grouped-item-info-title-pop-up {
    display: flex;
    opacity: 0;
    animation: 0.5s ease-out 0.5s 1 growOutUserInfoTitle forwards;
  }

  @keyframes growOutUserInfoTitle {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .assigned-user {
    position: relative;
    .cross {
      position: absolute;
    }

    &:hover {
      .cross {
        cursor: pointer;
        height: 16px;
        width: 16px;
        right: -8px;
        top: -6px;
        background-color: white;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 99px;
        z-index: 1;
        &:hover {
          background-color: #ede4e4;
        }
      }
    }
  }
}

.positive-accordion-button {
  background: #6bc950;
  border-radius: 2px;
  color: white;
  transition: all 0.25s linear;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.negative-accordion-button {
  background: #d3d3d3;
  border-radius: 2px;
  //color: white;
  color: #3b3b3b;
  transition: all 0.25s linear;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

/*
  Modification: Added for excel and nonExcelListTable
  By: Masum Raja
  Date: 17/10/2023
*/

.grouped-items-accordion-v2 {
  //overflow: hidden;
  .accordion-table-main {
    //width: 100%;
    display: flex;
    font-size: 12px;
    flex-direction:row;
  }
  .accordion-table-row {
    //width: 100%;
    display: flex;
    font-size: 12px;
    flex-direction:row;
    padding: 4px 0;

    .accordion-table-header {
      //background: #DBDBDB;
      //font-weight: 600;
      padding: 4px;
      color: #adb3bd;
      font-size: 10px;
      text-transform: uppercase;
      padding-bottom: 0px !important;
    }

    .accordion-table-data {
      font-size: 13.5px;
      color: #65676acc;
      padding: 0 4px;
    }

    .accordion-table-header, .accordion-table-data {
      display: flex;
      //align-items: flex-end;
      //overflow: hidden;
    }

    .arrow-field span, .accordion-table-header span, .accordion-table-data span, .accordion-table-data .main-col span {
      width: 100%;
      white-space: nowrap;
      //overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 2px;
    }

    .arrow-field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .accordion-table-main > .down-arrow-field > .down-arrow-container > svg {
    transition: all .25s linear;
    transform: rotate(-90deg);
  }


  .clickable {
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }

  .accordion-heading-main {
    padding: 4px 8px;
    font-weight: 400;
  }

  .accordion-category-heading {
    padding: 4px;
  }

  .accordion-heading-main, .accordion-category-heading {
    //border-radius: 20px;
    color: white;
    font-size: 11px;
    font-wieght: 400;
  }

  .cd-container-container {
    //overflow: hidden !important;
    padding: 0px;
  }

  .cd-accordion {
    //background: var(--cd-color-1);
    @include fontSmooth;
    //box-shadow: var(--shadow-lg);
    [type="checkbox"]:checked+label:before {
      content: none;
    }
    [type="checkbox"],[type="checkbox"]+label:before, [type="checkbox"]:not(.filled-in)+label:after {
      content: none;
      position: absolute;
      left: -9999px;
      opacity: 0;
    }
  }

  .cd-accordion--animated .cd-accordion__label::before {
    transition: transform .3s;
  }

  .cd-accordion__sub {
    display: none; // by default hide all sub menus
    //overflow: hidden;
  }

  .cd-accordion__sub--is-visible {
    display: block;
  }

  .cd-accordion__item {
    user-select: none;
  }

  .cd-accordion__input { // hide native checkbox
    position: absolute;
    opacity: 0;
  }

  .cd-accordion__label {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--space-sm) var(--space-md);
    //background: var(--cd-color-1);
    //--color-shadow: lightness(var(--cd-color-1), 1.2);
    box-shadow: inset 0 -1px var(--color-shadow);
    color: var(--color-white);

    span {
      order: 3;
    }

    &:hover {
      //background: lightness(var(--cd-color-1), 1.1);
    }
  }

  // icons
  .cd-accordion__label::after, .cd-accordion__label--icon-folder::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    //background-image: url('../img/cd-icons.svg');
    background-repeat: no-repeat;
    margin-right: var(--space-xxxs);
  }

  .cd-accordion__label--icon-folder::before {
    order: 1;
  }

  .cd-accordion__label::after {
    order: 2;
  }

  .cd-accordion__label--icon-folder {
    &::before { // arrow icon
      background-position: 0 0;
      transform: rotate(-90deg);
    }

    &::after { // folder icon
      background-position: -16px 0;
    }
  }

  .cd-accordion__label--icon-img::after { // image icon
    background-position: -48px 0;
  }

  .cd-accordion__input:checked + .cd-accordion__label::before { // rotate arrow
    transform: rotate(0);
  }

  .cd-accordion__input:checked + .cd-accordion__label {
    svg {
      transform: rotate(0deg);
    }
  }

  .cd-accordion__input:checked + .cd-accordion__label::after { // show open folder icon if item is checked
    background-position: -32px 0;
  }

  .cd-accordion__input:checked ~ .cd-accordion__sub { // show children when item is checked
    display: block;
  }

  //.cd-accordion__label .accordion-table-row .accordion-head-collapsible {
  //  display: none;
  //}

  //.cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-collapsible { // show children when item is checked
  //  display: block;

  //  .accordion-heading-main {
  //    border-radius: 4px 4px 0 0;
  //  }
  //}

  .cd-accordion__label .accordion-table-row .first-main-column .accordion-heading-main {
    border-radius: 4px;
  }
  .cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .first-main-column .accordion-heading-main {
    border-radius: 4px 4px 0 0;
  }


  // TO PREVENT OVERFLOW without any accordion open
  .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible-container {
    display: none;
  }
  .cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible-container { // show children when item is checked
    display: flex;
  }






  //.cd-accordion__label .accordion-table-row .accordion-head-uncollapsible {
  //  display: block;

  //  .accordion-heading-main {
  //    border-radius: 4px;
  //  }
  //}

  //.cd-accordion__input:checked ~ .cd-accordion__label .accordion-table-row .accordion-head-uncollapsible { // show children when item is checked
  //  display: none;
  //}

  .cd-accordion__sub--l1 {
    .cd-accordion__label {
      //background: lightness(var(--cd-color-1), 0.65);
      //--color-shadow: lightness(var(--cd-color-1), 0.85);
      box-shadow: inset 0 -1px var(--color-shadow);
      padding-left: calc(var(--space-md) + 16px);

      &:hover {
        //background: lightness(var(--cd-color-1), 0.75);
      }

      .arrow-field {
        padding-left: 24px;
      }
    }
    .accordion-table-row {
      .accordion-table-data {
        color: #3b3b3b !important;
      }
      background: #ffffff !important;

      .can-hide-radio {
        display: none;
      }
      .can-hide-radio-input:checked+label:after {
        border: 1px solid #64696d !important;
        background-color: #2a2e34 !important;
        outline: 2px solid #ffffff;
        outline-offset: -3px;
      }
      .can-hide-radio-input:checked ~ .can-hide-radio {
        display: block;
      }

      &:hover {
        background: #fafbfc !important;

        .first-main-column {
          background: #fafbfc !important;
        }
        .can-hide-radio {
          display:block;
        }

      }
    }
  }

  .cd-accordion__item:last-child .cd-accordion__label {
    box-shadow: none;
  }

  .accordion-button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    font-weight: 400 !important;
  }

  .info-accordion-button {
    background: grey;
    color: white;
    transition: all 0.25s linear;
    &:hover {
      background: darkgrey;
    }
  }

  .inward-bottom-separator {
    //box-shadow: inset 0 0 10px #0000000d;

    border: 1px solid #fff;
    //box-shadow: 0 0 10px #0000000d;
    border-bottom: 1px solid #0000000d;
  }

  .accordion-button-container svg {
    transition: all 0.25s linear;
  }

  .accordion-button-container svg:hover {
    transform: scale(1.4);
  }

  .block-svg-container {
    position: relative;
    //overflow: hidden;
    height: 20px;
    padding: 0 2px;
    margin-left: 4px;
    display: inline-block;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #e9ebf0;
  }

  .first-main-column {
    position: sticky;
    left: 0;
    background: #fff !important;
    z-index: 999;
  }

  .grouped-item-info-title-pop-up {
    display: none;
  }

  .assigned-user:hover > .grouped-item-info-title-pop-up {
    display: flex;
    opacity: 0;
    animation: 0.5s ease-out 0.5s 1 growOutUserInfoTitle forwards;
  }

  @keyframes growOutUserInfoTitle {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .assigned-user {
    position: relative;
    .cross {
      position: absolute;
    }

    &:hover {
      .cross {
        cursor: pointer;
        height: 16px;
        width: 16px;
        right: -8px;
        top: -6px;
        background-color: white;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 99px;
        z-index: 1;
        &:hover {
          background-color: #ede4e4;
        }
      }
    }
  }
}
