.multi-select-container {
  z-index:7;
  position:absolute;
  background-color:#fff;
  width: 405px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .multi-select-input {
    position: relative !important;
    left: 0 !important;
    opacity: unset !important;
    width:17px;
    height:17px !important;
  }
}
