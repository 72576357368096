.accountability-impact {
  position: relative;

  .list-impact-slider-off {
    //top: -1px;
    top: 0;
    //margin-left:-20px;
    width: 129px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    //z-index: 1;
    z-index: 10;
    position: absolute;
    border-radius: 5px;
    transition: 0.2s transform ease;
    transform-origin:left;
    transform: scaleX(0);
    text-align: center;
    padding:4px 2px;
    color:var(--theme-default-colour);
  }

  .list-impact-slider-on {
    //top: -1px;
    top: 0;
    //margin-left:-20px;
    width: 129px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    //z-index: 1;
    z-index: 10;
    position: absolute;
    border-radius: 5px;
    transition: 0.2s transform ease;
    transform-origin:left;
    transform: scaleX(1);
    text-align: center;
    padding:4px 2px;
    color:var(--theme-default-colour);
  }
}
