.single-card {
  transition: all ease-in-out .25s;
  position: relative;
  &:hover {
    border: 1px solid #41635e;
  }
  button {
    transition: all ease-in-out .25s;
    width: 100%;
    min-width: auto;
    padding: 4px 8px;
    background: none;

    &:hover {
      background: #e2e2e2;
    }
  }
  svg {
    transition: all ease-in-out .25s;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.single-card:after {
  content: attr(data-label);
  position: absolute;
  top: -7.5px;
  left: 24px;
  background: #fff;
  color: #696d76;
  font-size: 10px;
  padding-right: 5px;
  padding-left: 2px;
}
