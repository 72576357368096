.small-loader {
  .circle-4-loader {
  	height: 32px;
  	width: 32px;
  	-webkit-animation: circle-4-loader-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-1 {
  	0%   { -webkit-transform: rotate(0deg); }
  	100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes circle-4-loader-1 {
  	0%   { transform: rotate(0deg); }
  	100% { transform: rotate(360deg); }
  }
  .circle-4-loader::before {
  	content: "";
  	display: block;
  	position: absolute;
  	top: 0; left: 0;
  	bottom: 0; right: auto;
  	margin: auto;
  	width: 8px;
  	height: 8px;
  	background: #FFF;
  	border-radius: 50%;
  	-webkit-animation: circle-4-loader-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-2 {
  	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  	50%  { -webkit-transform: translate3d(24px, 0, 0) scale(.5); }
  	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  }
  @keyframes circle-4-loader-2 {
  	0%   { transform: translate3d(0, 0, 0) scale(1); }
  	50%  { transform: translate3d(24px, 0, 0) scale(.5); }
  	100% { transform: translate3d(0, 0, 0) scale(1); }
  }
  .circle-4-loader::after {
  	content: "";
  	display: block;
  	position: absolute;
  	top: 0; left: auto;
  	bottom: 0; right: 0;
  	margin: auto;
  	width: 8px;
  	height: 8px;
  	background: #FFF;
  	border-radius: 50%;
  	-webkit-animation: circle-4-loader-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-3 {
  	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  	50%  { -webkit-transform: translate3d(-24px, 0, 0) scale(.5); }
  	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  }
  @keyframes circle-4-loader-3 {
  	0%   { transform: translate3d(0, 0, 0) scale(1); }
  	50%  { transform: translate3d(-24px, 0, 0) scale(.5); }
  	100% { transform: translate3d(0, 0, 0) scale(1); }
  }
  .circle-4-loader span {
  	display: block;
  	position: absolute;
  	top: 0; left: 0;
  	bottom: 0; right: 0;
  	margin: auto;
  	height: 32px;
  	width: 32px;
  }
  .circle-4-loader span::before {
  	content: "";
  	display: block;
  	position: absolute;
  	top: 0; left: 0;
  	bottom: auto; right: 0;
  	margin: auto;
  	width: 8px;
  	height: 8px;
  	background: #FFF;
  	border-radius: 50%;
  	-webkit-animation: circle-4-loader-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-4 {
  	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  	50%  { -webkit-transform: translate3d(0, 24px, 0) scale(.5); }
  	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  }
  @keyframes circle-4-loader-4 {
  	0%   { transform: translate3d(0, 0, 0) scale(1); }
  	50%  { transform: translate3d(0, 24px, 0) scale(.5); }
  	100% { transform: translate3d(0, 0, 0) scale(1); }
  }
  .circle-4-loader span::after {
  	content: "";
  	display: block;
  	position: absolute;
  	top: auto; left: 0;
  	bottom: 0; right: 0;
  	margin: auto;
  	width: 8px;
  	height: 8px;
  	background: #FFF;
  	border-radius: 50%;
  	-webkit-animation: circle-4-loader-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-5 {
  	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  	50%  { -webkit-transform: translate3d(0, -24px, 0) scale(.5); }
  	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  }
  @keyframes circle-4-loader-5 {
  	0%   { transform: translate3d(0, 0, 0) scale(1); }
  	50%  { transform: translate3d(0, -24px, 0) scale(.5); }
  	100% { transform: translate3d(0, 0, 0) scale(1); }
  }
  .small-loader-center {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
  }

  /*
    Modification: Added new class for loader
    By: Masum Raja
    Date: 19/07/2023
  */

  .spinning-dots-loader {
     color: #ffffff;
     font-size: 14px;
     width: 16px;
     height: 16px;
     text-indent: -9999em;
     overflow: hidden;
     display: block;
     border-radius: 50%;
     position: relative;
     transform: translateZ(0);
     animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
   }

   @keyframes mltShdSpin {
     0% {
       box-shadow: 0 -0.83em 0 -0.4em,
       0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
       0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
     }
     5%,
     95% {
       box-shadow: 0 -0.83em 0 -0.4em,
       0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
       0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
     }
     10%,
     59% {
       box-shadow: 0 -0.83em 0 -0.4em,
       -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
       -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
     }
     20% {
       box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
     }
     38% {
       box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
     }
     100% {
       box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
       0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
     }
   }

   @keyframes round {
     0% { transform: rotate(0deg) }
     100% { transform: rotate(360deg) }
   }

   .half-spinning-loader {
     width: 24px;
     height: 24px;
     border-radius: 50%;
     display: block;
     border-top: 3px solid #FFF;
     border-right: 3px solid transparent;
     box-sizing: border-box;
     animation: rotation 1s linear infinite;
   }

   @keyframes rotation {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
   }

  .dots-wave {
    width: 12px;
    height: 12px;
    border: 2px dotted #F29339;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
/*
		modified by Arun Singh
		modified at 03/04/2024
		modification : Add classes for ldsSpinner
*/
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #002c5f;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
