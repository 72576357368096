.ripple-button {
  //border-radius: 4px;
  //border: none;
  //margin: 8px;
  //padding: 14px 24px;
  //background: #1976d2;
  color: #fff;
  height:51px;
  overflow: hidden;
  position: relative;
  //cursor: pointer;
}

.ripple-button > .ripple {
  width: 50px;
  height: 50px;
  position: absolute;
  background: #fff;
  display: block;
  content: "";
  border-radius: 50%;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.ripple-button > .content {
  position: relative;
  z-index: 2;
}
