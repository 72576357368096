.callInfoCardsContainer{}
.callAnalyticsContainer{}
/*
  Modification: Added Chart and modified UI completely after cards in CallingDashboard
  By: Devang
  Date: 18/5/2022
*/
.newCallWorkSpaceCard {
  background: var(--card-primary-color);
  border-radius: 4px;
  min-height: 40px;
  height: 50px;
  font-weight: 500;
  .newCallWorkSpaceCard-count {
    color: var(--card-primary-count);
    font-size: 32px;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .newCallWorkSpaceCard-header {
    color: var(--card-primary-header);
    font-size: 12px;
    margin-left: 4px;
    display: flex;
    align-items: center;
  }
}
.newCallWorkSpaceCard-icon {
  fill: var(--card-primary-header);
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  /*position: absolute;
  right: 0;*/
  /*svg {
    height: 24px;
    width: 24px;
  }*/
}

//Modification: Added primaryButtonActive to detect which filter is active
//By: Devang
//Date: 07/06/2022
.primaryButtonActive{
  box-shadow: 2px 3px 5px 0px #baabba;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
