.accordion-table {
  overflow: hidden;
  .accordion-table-row {
    width: 100%
    display: flex;
    font-size: 12px;
    flex-direction:row;

    .accordion-table-header {
      //background: #DBDBDB;
      //font-weight: 600;
      border-bottom: 4px #eef2f4 solid;
      color: #808080;
    }

    .accordion-table-header, .accordion-table-data {
      padding: 4px;
      color: #65676acc;
      display: flex;
      align-items: flex-end;
      overflow: hidden;
    }

    .arrow-field span, .accordion-table-header span, .accordion-table-data span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .arrow-field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      svg {
        transition: all .25s linear;
      }
    }
  }

  [type="checkbox"]+label {
    padding: 0 !important;
  }

  ul {
    margin: 0 !important;
  }

  .clickable {
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
  
  .cd-container-container {
    overflow: scroll !important;
  }

  .cd-accordion {
    //background: var(--cd-color-1);
    @include fontSmooth;
    //box-shadow: var(--shadow-lg);
    [type="checkbox"]:checked+label:before {
      content: none;
    }
    [type="checkbox"],[type="checkbox"]+label:before, [type="checkbox"]:not(.filled-in)+label:after {
      content: none;
      position: absolute;
      left: -9999px;
      opacity: 0;
    }
  }

  .cd-accordion--animated .cd-accordion__label::before {
    transition: transform .3s;
  }

  .cd-accordion__sub {
    display: none; // by default hide all sub menus
    overflow: hidden;
  }

  .cd-accordion__sub--is-visible {
    display: block;
  }

  .cd-accordion__item {
    user-select: none;
  }

  .cd-accordion__input { // hide native checkbox
    position: absolute;
    opacity: 0;
  }

  .cd-accordion__label {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--space-sm) var(--space-md);
    //background: var(--cd-color-1);
    //--color-shadow: lightness(var(--cd-color-1), 1.2);
    box-shadow: inset 0 -1px var(--color-shadow);
    color: var(--color-white);

    span {
      order: 3;
    }

    &:hover {
      //background: lightness(var(--cd-color-1), 1.1);
    }
  }

  // icons
  .cd-accordion__label::after, .cd-accordion__label--icon-folder::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    //background-image: url('../img/cd-icons.svg');
    background-repeat: no-repeat;
    margin-right: var(--space-xxxs);
  }

  .cd-accordion__label--icon-folder::before {
    order: 1;
  }

  .cd-accordion__label::after {
    order: 2;
  }

  .cd-accordion__label--icon-folder {
    &::before { // arrow icon
      background-position: 0 0;
      transform: rotate(-90deg);
    }

    &::after { // folder icon
      background-position: -16px 0;
    }
  }

  .cd-accordion__label--icon-img::after { // image icon
    background-position: -48px 0;
  }

  .cd-accordion__input:checked + .cd-accordion__label::before { // rotate arrow
    transform: rotate(0);
  }

  .cd-accordion__input:checked + .cd-accordion__label .arrow-field{
    svg {
      transform: rotate(-180deg);
    }
  }

  .cd-accordion__input:checked + .cd-accordion__label::after { // show open folder icon if item is checked
    background-position: -32px 0;
  }

  .cd-accordion__input:checked ~ .cd-accordion__sub { // show children when item is checked
    display: block;
  }

  .cd-accordion__sub--l1 {
    .cd-accordion__label {
      //background: lightness(var(--cd-color-1), 0.65);
      //--color-shadow: lightness(var(--cd-color-1), 0.85);
      box-shadow: inset 0 -1px var(--color-shadow);
      padding-left: calc(var(--space-md) + 16px);

      &:hover {
        //background: lightness(var(--cd-color-1), 0.75);
      }

      .arrow-field {
        padding-left: 24px;
      }
    }
    .accordion-table-row {
      background: #e4e4e4;
    }
  }

  .cd-accordion__item:last-child .cd-accordion__label {
    box-shadow: none;
  }

  .cd-accordion__sub--l2 {
    .cd-accordion__label {
     padding-left: calc(var(--space-md) + var(--space-xxxs) + 32px);
    }

    .arrow-field {
      padding-left: 36px !important;
    }
    .accordion-table-row {
      background: #cecece !important;
    }
  }

  .cd-accordion__sub--l3 .cd-accordion__label {
    padding-left: calc(var(--space-md) + var(--space-xxxs) + 48px);
  }

}
