.sub-card-component {
  height: 50%;
  align-content: center;
  padding: 0 16px 0 0;
  background: #fcebeb;
  //color: #fff;
  border-radius: 4px;
}

@media screen and (max-width: 425px) {
 .mobile-cardDetails-container {
    flex-direction:column;
    gap:1rem;
  }

 .mobile-cardDetails-nameCard{
    display:flex;
    align-items:flex-end;
    gap:1rem;
    max-width:100%;
    margin-left:1.2rem;
    //order:2;

  }
  .mobile-cardDetails-icons {
     justify-content:flex-start;
     gap:0.5rem;

   }

 .mobile-cardDetails-iconCard {
    max-width:98.5%;
    order:1;
  }
}

/*
  Modification: Add new class for mobile Responsiveness
  By: Masum Raja
  Date: 16/11/2023
*/
.sub-card-component-mobile-view {
  height: 50%;
  align-content: center;
  background: #fcebeb;
  border-radius: 4px;
}
