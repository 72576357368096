.rippleAnimation {
  position:relative;
}

.rippleAnimation:before {
  animation:ripple 2s ease-out infinite;
  //border:solid 5px #f00;
  border-radius:50%;
  bottom:0;
  content:"";
  left:0;
  position:absolute;
  right:0;
  top:0;
}

.rippleAnimation:after {
  animation:rippleAnimation 2s 1s ease-out infinite;
  //border:solid 5px #f00;
	border:solid 5px var(--text-green);
  border-radius:50%;
  bottom:0;
  content:"";
  left:0;
  position:absolute;
  right:0;
  top:0;
}

@keyframes rippleAnimation {
  0% {
    opacity:.25;
  }
  100% {
    border-radius:2em;
    opacity:0;
    transform:scale(3);
  }
}

.drag-drop-floating-button {
  display:flex;
  justify-content:center;
  align-items:center;
  right: 240px !important;
  bottom: 10px !important;
  background: red !important;
  color: white !important;
	width: 45px;
  height: 45px;
  border-radius: 50%;
  z-index: 999;
	position: fixed;
	cursor: pointer;
}


.drag-drop-floating-button-lead {
	display:flex;
	justify-content:center;
	align-items:center;
  width:120px;
  height:45px;
  border-radius: 25px;
  z-index:999;
  position: fixed;
  cursor: pointer;
	right: 100px !important;
  bottom: 10px !important;
	background: var(--theme-default-colour) !important;
  color: white !important;
}

.drag-drop-floating-button-case {
	display:flex;
	justify-content:center;
	align-items:center;
  width:120px;
  height:45px;
  border-radius: 25px;
  z-index:999;
  position: fixed;
  cursor: pointer;
	right: 100px !important;
  bottom: 10px !important;
  background: var(--theme-default-colour) !important;
  color: white !important;
}
