.select-user-container {
  .custom-search-select {
    position: absolute;
    width: 280px;
    border-radius: 4px;
    height: 200px;
    overflow: hidden;
    background: #fff;
    font-size: 14px;
    font-weight: 400;
    color: #2a2e34;
    z-index: 99;
    animation: 0.25s ease-out 0s 1 growOutCustomSearchSelect;

    box-shadow: 0 1px 2px rgba(0,0,0,0.07),
                  0 2px 4px rgba(0,0,0,0.07),
                  0 4px 8px rgba(0,0,0,0.07),
                  0 8px 16px rgba(0,0,0,0.07),
                  0 16px 32px rgba(0,0,0,0.07),
                  0 32px 64px rgba(0,0,0,0.07);

    #custom-search-select-input {
      outline: none;
      border: none;
      font-size: 14px;
      width: calc(100% - 34px) !important;
    }

    .custom-search-select-container {
      height: calc(100% - 54px);
    }
    .custom-search-select-item {
      background: #fff;
      cursor: pointer;
      &:hover {
        background: #f7f8f9;
      }
    }
  }


  @keyframes growOutCustomSearchSelect {
    0% {
      height: 0;
    }
    100% {
      height: 200px;
    }
  }

}
