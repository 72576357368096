.stage-summary-card {
  //box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow);
  //box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  background-color: #8aceeb;
  color: rgb(17, 24, 39);
  transition: all ease-in 0.25s;
  //box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  border-radius: 4px;

  &:hover {
    background-color: #cfcfcf;
  }

  stage-summary-card-text {
    margin: 0px;
    font-size: 1.3rem;
    font-family: "Inter var", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
  }

  .tab-dot {
    border-radius: 50%;
  }
  .tab-dot, .tab-tick {
    top: 13px;
    left: 10px;
  }

  .next-arrow {
    //transform: rotate(-90deg);
    top: 9px;
    right: 1px;
  }

  .live-dot {
    position: absolute;
    cursor: pointer;
    height: 10px;
    width: 10px;
    left: 4px;
    top: 4px;
    background-color: #000;
    border: 1px solid #fff;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
    border-radius: 99px;
  }
}

/*
        modified by Vipin
        modified at 10/12/2022
        modification : added task-stage-card class for different stage-summarycard properties for new task view.
*/

.task-stage-card {
  .stage-summary-card {
  background-color: var(--mdc-theme-primary);
  transition: all ease-in 0.25s;
  color:#fff;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  border-radius: 4px;

  .next-arrow {
    top: 10px !important;
  }

  &:hover {
    background-color: #cfcfcf;
    color: rgb(17, 24, 39);
  }

  .stage-summary-card-text {
    margin: 0px;
    font-size: 1.3rem;
    font-family: "Inter var", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
    padding: 7px 5px !important;
    text-align: left;
  }

  }
  .activeStage {
    background: aliceblue;
    color: black;
  }
  .stage-summary-card.active {
    color: black;
  }
}
.selectedStageTextName {
  font-size: 0.8rem;
  padding: 3px 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.stage-count {
  min-width: 8px;
  text-align: center;
  background-color: #8aceeb;
  background-clip: padding-box;
  color: #000;
  margin: 0;
  text-shadow: none;
  top: 4px;
  right: 20px;
  font-size: 0.75rem;
  letter-spacing: normal;
  border-radius: 10px;
  font-weight: bold;
  padding: 2px 4px;
  position: relative;
  z-index: 1;
}
.interaction-menu {
  min-width: 170px;
  padding: 8px;
  transform-style: unset;
  max-width: 170px;
}
.task-stage-width {
  width: 80px !important;
  max-width: 80px !important;
}
