.task-list-tags {
  flex-grow: 0;
  flex-basis: min-content;
  margin: 3px 4px 4px 0;
  display: flex;
  float: left;
  padding: 0 30px 0 13px;
  overflow: visible;
  height: 31px;
  white-space: nowrap;
  outline: 0;
  border-radius: 3px;
  background: var(--card-primary-header);
  border-left: 2px solid var(--card-primary-color);
  color: var(--text-grey);
  justify-content: space-around;

  .text {
    display: inline-block;
    max-width: 250px;
    color: var(--text-grey);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/*
    modified by Vihang
    modified at 04/05/2022
    modification : responsive changes for taskheader tag
*/

@media (min-width:320px) and (max-width:425px)  {
  .task-list-tags {
    padding: 0 13px 0 13px !important;
  }
  .task-list-tags-text {
    font-size:12px !important;
  }
}
@media (min-width:426px) and (max-width:768px)  {
  .task-list-tags {
    padding: 0 13px 0 13px !important;
  }
  .task-list-tags-text {
    font-size:12px !important;
  }
}
