table {
  width: 100%;
}
th, td {
  text-align: left;
  //border: 1px solid black;
}
th {
  //vertical-align: top;
  padding: 3px 10px;
  width: 150px;
  border: none;
  //background: #f2f2f2;
  background:	darken(#f0f8ff, 10%);
  font-weight: bold;
  color: #4c4a4a;
  font-size: 15px;
  word-wrap: break-word;
  //border: 1px solid black;
}
td {
  padding: 10px;
  vertical-align: top;
  width: 150px;
  border: none;
  color: #000;
  font-size: 13px;
  word-wrap: break-word;
  //border: 1px solid black;
}
.inner {
  overflow-x:scroll;
  overflow-y:scroll;
  margin-top: 10px;
  background: #ffffff26;
}
