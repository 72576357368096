.case-container{
  width:250px;
}




/* [Media Queries]
--------------------------------------------------------------------------------
*/

@media (max-width:425px){
	/* smartphones, iPhone, portrait 480x320 phones */

    .case-container{
      width:100%;
      padding-left:0;
    }
    //.mobile-card-transition1 {
    //transform: translate3d(0,-100%,0);
    //height: 0;
    //transition: height 0.2s ease-in;
    //position: relative;
    //z-index: -1;
    //}

    //.mobile-card-transition {
    //transform: translate3d(0,0,0);
    //transition: height 0.2s ease-in;
    //}

}

@media (min-width:429px) and (max-width:1024px){
	/* smartphones, iPhone, portrait 480x320 phones */

    .case-container{
      padding-inline:0.5rem;
    }
}

/* modified by Vipin
   modified on 07/11/2022
   modification : Added "case-container" class to cases container and setting width for case container to full for mobile devices(width below 428px)
*/
