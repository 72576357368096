.pill-tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  min-height: 0px;
  box-sizing: border-box;
  margin: 0 8px 5px 0;

  .pill-tab-container {
    display: flex;
    align-items: center;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    .pill-tab-text {
      display: inline-block;
      min-width: 0;
      padding: 4px 10px;
      //padding: 0 10px;
      //border: 1px solid #ddd;
      background: #efefef9e;
      //border-radius: 99px;
      border-radius: 4px;
      color: #808080 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
      font: 12px 'Roboto', sans-serif;
      height: fit-content;
      transition: all ease 0.3s;

      &:hover {
        background: #ddd;
      }
    }
    /*
      Modification: Added new background color
      By: Masum Raja
      Date: 19/10/2023
    */
    .pill-tab-text.active {
      //background: #d7ecec;
      //color: #408b8b !important;
      background: var(--theme-default-colour) !important;
      color: #fff !important;
      &:hover {
        background: #b8e6e6;
      }
    }
    .positive-pill-tab-text {
      background: #6bc950;
      border-radius: 2px;
      color: white;
      transition: all 0.25s linear;
      &:hover {
        opacity: 0.8;
      }
    }

    .negative-pill-tab-text {
      background: #d3d3d3;
      border-radius: 2px;
      color: white;
      transition: all 0.25s linear;
      &:hover {
        opacity: 0.8;
      }
    }
    .rounded-tab-text {
      border-radius: 99px;
      padding: 5px 10px;
      margin: 8px 8px 0px 0px;
    }
  }
}
