.toast1
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  max-width: 100%;
  transition: all 0.3s ease;
  background-color: white !important;
  top: 0;
  width: 100vw;
  height: 0;

.toast1 > .body
  font-size: initial;
  margin: 0;
  padding: 0.8rem 1.5rem;
  word-wrap: break-word;
  background: color-primary;
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;

.toast1 > .body.info
  border: none !important;
  background: #03A9F4;

.toast1 > .body.warning
	background: color-warning;

.toast1 > .body.error
	background: color-danger;

.toast1 > .body.message
	background: #e8f60ea6;

.toast1 > .body.nointernet
	background: rgba(191,0,0,.70);

.toast1 > .leftImg
  width: 20px;
  position: absolute;
  top: 10px;
  left: 7rem;

.toast1 > .rightImg
  position: absolute;
  width: 20px;
  right: 7rem;
  top: 10px;

@media screen and (max-device-width : 767px)
	.toast1
		width: 100%;
		> .body
			margin: 0;
