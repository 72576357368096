.notification-dashboard {
  .interaction-notification-container {
    margin-left: 14px;
    background: #F6F6F6;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    min-height: 47px;

    width: calc(100% - 22px - 14px);

    font-family: 'Roboto';
    font-style: normal;

    .interaction-notification-header {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #FFFFFF;
      border-radius: 5px 5px 0px 0px;
      padding: 0 0 15px 0;

      .interaction-notification-heading {
        font-size: 14px;
        line-height: 16px;
        color: #002C5F;

        /*
          Modification: Remove hover pink (CSS) and pointer cursor from taskName in notificationsDashboard
          By: Manohar
          Date: 21/06/2024
        */
        &:hover {
          //color: #DE5C6E;
        }
      }
      /*
        Modification: Made heading pink on hover if clickable
        By: Devang
        Date: 22/06/2024
      */
      .interaction-notification-heading.cursor-pointer {
        &:hover {
          color: #DE5C6E;
        }
      }
    }

    .notification-header-pill {
      border-radius: 5px;
      padding: 3px 8px;
    }
    .notification-category-pill {
      border-radius: 10px;
      padding: 3px 8px;
      font-size: 10px;
      line-height: 12px;
      border: 1px solid #002c5f;
      color: #002C5F;
      width: fit-content;
    }

    .notification-status-pill {
      border-radius: 5px;
      padding: 3px 4px;
      width: 72px;
      text-align: center;
      font-weight: 500;
      color: white;

    }

    .interaction-notification-body {
      padding: 0 27px 12px 16px;
      /*
        Modification: Updated min-height from 190 to 200px
        By: Devang
        Date: 16/07/2023
        By: Yashvi
        On: 18th July 2024
        Modification: Updated min-height from 200px to min-content
      */
      min-height: min-content;

      .notification-row {
        /*
          Modification: Updated padding from 12 to 10px
          By: Devang
          Date: 16/07/2023
        */
        padding: 10px 0;
        border-bottom: 1px solid #B8B8B8;
        color: #3C3C3C;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
