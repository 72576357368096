.scheduler-list {
  .execution-log-row {
    padding: 12px 0;
    border-bottom: 1px solid #B8B8B8;
    color: #3C3C3C;
    font-size: 12px;
    line-height: 14px;
  }
  .execution-log-category-pill {
    border-radius: 10px;
    padding: 3px 8px;
    font-size: 10px;
    line-height: 12px;
    border: 1px solid #002c5f;
    color: #002C5F;
    width: fit-content;
  }
  .execution-log-status-pill {
    border-radius: 5px;
    padding: 3px 4px;
    width: 72px;
    text-align: center;
    font-weight: 500;
    color: white;

  }
}
