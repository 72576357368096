.custom-table {
  th {
    z-index: 2;
    padding: 8px;
    font-size: 12px !important;
    color: #808080 !important;
  }
  td {
    padding: 8px;
    p {
      font-size: 12px !important;
    }
    select {
      font-size: 12px !important;
      height: fit-content;
      margin: 0 !important;
    }
    button {
      font-size: 12px !important;
      height: fit-content;
      padding: 4px;
      margin: 0 !important;
    }
  }
  [type="checkbox"]+label {
    height: 12px;
    line-height: 12px;
  }
}
