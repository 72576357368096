.loader
  content: " ";
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
  transition: box-shadow 280ms cubic-bezier(.4,0,.2,1),background-color 300ms ease;
  position: absolute;
  width:100%
  height:100%;
  top:0;
  bottom:0;
  overflow: hidden;
  transform: translate3d(0,0,0);
  background: rgba(209,209,209, 0.8);
  z-index: 99999;
  display: none;

.loader:before
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle,#000 10%,transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10,10);
  opacity: 0;
  transition: transform .5s,opacity 1s;

.loader.is-loading:after
  animation: spinAround 500ms infinite linear;
  border: 3px solid color-primary;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 3em;
  width: 3em;
  left: calc(50% - (1em/2));
  top: calc(50% - (1em/2));
  position: absolute !important;

@keyframes spinAround
  0%
    transform: rotate(0deg)
  to
    transform: rotate(359deg)
