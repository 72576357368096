/*
  BY: Prasannadatta Kawadkar
  ON: 13 March 2024
  Modification: added style for dropdown
*/
.select-menu-year {
  cursor: pointer;
  position: relative;
}

.selectYear {
  color: #212A3E;
  padding: 8px;
  font-weight: 500;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.options-list-year {
  position: absolute;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 6px;
  margin-top: 14px;
  padding: 0 20px;
  max-height: 180px;
  overflow-y: auto;
  -webkit-transition: .2s linear .1s;
  -o-transition: .2s linear .1s;
  transition: .2s linear .1s;
  height: 0;
}

.optionYear {
  padding: 8px 0;
  font-weight: 500;
  font-size: 14px;
  -webkit-transition: .1s ease-in-out;
  -o-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
}

.optionYear:hover,
.optionYear.selected {
  color: #00a8ff;
}

.options-list-year::-webkit-scrollbar {
  width: 4px;
  background-color: #eee;
}

.options-list-year::-webkit-scrollbar-thumb {
  background-color: #00a8ff;
}

.options-list-year.active {
  height: 180px;
  /*
    Bug: T1687 - 27 - Dashboard - Month and Year dropdowns | border should be given to the dropdowns
    Modification: Updated active class with border property
    By: Devang
    Date: 10/04/2024
  */
  border: 2px solid #ddd !important
}
