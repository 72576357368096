.selectMenu {
  position: absolute;
  width: auto;
  height: auto;
  overflow: hidden;
  background: #f9f9f9;
  font-size: 12px !important;
  font-weight: 400;
  color: #3b3b3b;
  z-index: 9999;
  border-radius: 2px;
  box-shadow: 0px 3px 8px 0px #9d9292;
  right: 0;
  top: 45px;
  left: 0;
  //min-width: -webkit-fill-available;
  .selected {
    background: #d3d3d3
  }
  ul {
    margin-left: 7px;
    padding: 0;
  }
}
