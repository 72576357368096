.loader-v2 {
  span {
    display: block;
  }

  .loader-bg {
    //background: rgba(0,0,0,0.75);
    background: tranparent;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
  }
  .loader-text {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300 !important;
    text-align: center;
  }

  .infinity-loader {
    width: calc(100px - 24px);
    height: 50px;
    position: relative;
    animation: flippx 2s infinite linear;
  }
  .infinity-loader:before {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #002c5f;
    transform-origin: -24px 50%;
    animation: spin 1s infinite linear;
  }
  .infinity-loader:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    background: #002c5f;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  @keyframes flippx {
    0%, 49% {
      transform: scaleX(1);
    }
    50%, 100% {
      transform: scaleX(-1);
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .dot-runner-loader {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: #002c5f;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRolling 2s linear infinite;
  }

  @keyframes shadowRolling {
    0% {
      box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    12% {
      box-shadow: 100px 0 #002c5f, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    25% {
      box-shadow: 110px 0 #002c5f, 100px 0 #002c5f, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    36% {
      box-shadow: 120px 0 #002c5f, 110px 0 #002c5f, 100px 0 #002c5f, 0px 0 rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 130px 0 #002c5f, 120px 0 #002c5f, 110px 0 #002c5f, 100px 0 #002c5f;
    }
    62% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 #002c5f, 120px 0 #002c5f, 110px 0 #002c5f;
    }
    75% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 #002c5f, 120px 0 #002c5f;
    }
    87% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 #002c5f;
    }
    100% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
    }
  }

  .round-rollers {
    animation: rotate 2s infinite;
    height: 50px;
    width: 50px;
    margin-left: 15px;
  }

  .round-rollers:before,
  .round-rollers:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
  }
  .round-rollers:before {
    animation: ball1 2s infinite;
    background-color: #2E8994;
    box-shadow: 30px 0 0 #79C6C1;
    margin-bottom: 10px;
  }
  .round-rollers:after {
    animation: ball2 2s infinite;
    background-color: #79C6C1;
    box-shadow: 30px 0 0 #2E8994;
  }

  @keyframes rotate {
    0% { transform: rotate(0deg) scale(0.8) }
    50% { transform: rotate(360deg) scale(1.2) }
    100% { transform: rotate(720deg) scale(0.8) }
  }

  @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 #79C6C1;
    }
    50% {
      box-shadow: 0 0 0 #79C6C1;
      margin-bottom: 0;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #79C6C1;
      margin-bottom: 10px;
    }
  }

  @keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #2E8994;
    }
    50% {
      box-shadow: 0 0 0 #2E8994;
      margin-top: -20px;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #2E8994;
      margin-top: 0;
    }
  }

}
