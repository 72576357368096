/*
  Modification: Added new background color
  By: Masum Raja
  Date: 23/10/2023
*/
.primary {
  background: var(--theme-default-colour);
  color: var(--text-white);
}

.secondary {
  background: var(--card-secondary-color);
  color: var(--card-secondary-header) !important;
}

.soft-red {
  background: #f08080;
  color: var(--text-white);
}

.grey-white {
  background: #333333;
  color: var(--text-white);
}
