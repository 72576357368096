.tabs {
  display: block;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  overflow: hidden;
}

.tabs [class^="tab"] label,
.tabs [class*=" tab"] label {
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1em;
  padding: 10px 0;
  text-align: center;
}

.tabs [class^="tab"] [type="radio"],
.tabs [class^="tab"] [class="tab-checkbox"],
.tabs [class*=" tab"] [type="radio"],
.tabs [class*=" tab"] [class="tab-checkbox"] {
  border-bottom: 1px solid rgba(239, 237, 239, 0.5);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabs [class^="tab"] [type="radio"]:hover, .tabs [class^="tab"] [type="radio"]:focus,
.tabs [class*=" tab"] [type="radio"]:hover,
.tabs [class*=" tab"] [type="radio"]:focus,
.tabs [class^="tab"] [class="tab-checkbox"]:hover, .tabs [class^="tab"] [class="tab-checkbox"]:focus,
.tabs [class*=" tab"] [class="tab-checkbox"]:hover,
.tabs [class*=" tab"] [class="tab-checkbox"]:focus {
  border-bottom: 1px solid #2c2d96;
}

.tabs [class^="tab"] [type="radio"]:checked,
.tabs [class*=" tab"] [type="radio"]:checked,
.tabs [class^="tab"] [class="tab-checkbox"]:checked,
.tabs [class*=" tab"] [class="tab-checkbox"]:checked {
  border-bottom: 2px solid #2c2d96;
  label {
    font-weight: bold;
  }
}

.tabs [class^="tab"] [type="radio"]:checked + div,
.tabs [class*=" tab"] [type="radio"]:checked + div,
.tabs [class^="tab"] [class="tab-checkbox"]:checked + div,
.tabs [class*=" tab"] [class="tab-checkbox"]:checked + div {
  opacity: 1;
}

.tabs [class^="tab"] [type="radio"] + div,
.tabs [class*=" tab"] [type="radio"] + div,
.tabs [class^="tab"] [class="tab-checkbox"] + div,
.tabs [class*=" tab"] [class="tab-checkbox"] + div {
  display: block;
  opacity: 0;
  padding: 10px 0;
  width: 90%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabs .tab-2 {
  width: 140px;
}

.tabs .tab-2 [type="radio"] + div,
.tabs .tab-2 [class="tab-checkbox"] + div {
  width: 250%;
  margin-left: 200%;
}
.tabs .tab-2 [type="radio"] + div.desktop-tab,
.tabs .tab-2 [class="tab-checkbox"] + div.desktop-tab {
  width: 880%;
  margin-left: 200%;
}
.tabs .tab-2 [type="radio"]:checked + div,
.tabs .tab-2 [class="tab-checkbox"]:checked + div {
  margin-left: 0;
}
.tabs .tab-2:last-child [type="radio"] + div,
.tabs .tab-2:last-child [class="tab-checkbox"] + div {
  margin-left: 100%;
}
.tabs .tab-2:last-child [type="radio"]:checked + div,
.tabs .tab-2:last-child [class="tab-checkbox"]:checked + div {
  margin-left: -100%;
}
