.socket-box {
  position: relative;
  .socket-box-card-div1{
    position: relative;
    top:5px;
    left:3px;
    width: 23.38px;
    height: 15.81px;
  }
  .socket-box-card-div2 {
    position: relative;
    top: 10px;
    left: 40px;
    width: 218px;
    height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #3C3C3C;
  }
  .socket-box-card-div3 {
    position: relative;
    line-height:1.1;
    left: 60px;
    top: 7px;
  }

}
.socket-alert-box-hide {
  display:none;
}

.socket-show {
  width: 394px;
  height: 55px;
  left: 699px;
  top: 625px;
  background: linear-gradient(90deg, rgba(237, 178, 178, 0.5) 18.75%, rgba(245, 234, 234, 0.5) 100%);
  border-radius: 2px;
}
.socket-alert-box {
  z-index:100;
  padding:4px;
  box-sizing: border-box;
  position: absolute;
  width: 405px;
  height: 66px;
  //left: 983px;
  //top: 641px;
  right: 50px;
  bottom: 10px;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  border-radius: 5px;
}
