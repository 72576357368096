/*
	By: Yahsvi
	On: 10 Feb 2021
	Modification: Pagination.
*/

.pagination-block {
	tbody {
		tr {
		border-bottom: 4px #eef2f4 solid;
		background-color: #fff;
		height: 42px;
		}
		th {
			background: #F6F8FD;
			border: 0px;
			padding: 0px 19px;
			width: 15%;
			&:nth-child(2) {
				width: 20%;
			}
			&:nth-child(3) {
				width: 40%;
			}
			&:nth-child(4) {
				width: 25%;
			}
		}
		.table-title {
			z-index: 7;
			//display: inline-block;
			display: flex;
			overflow: hidden;
			padding-right: 21px;
			max-width: 100%;
			color: #535c69;
			text-align: left;
			text-transform: uppercase;
			-ms-text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			white-space: nowrap;
			letter-spacing: .5px;
			font-weight: 600;
			font-size: 11px;
			line-height: 12px;
		}
		 .total {
		 	color: #2067b0;
			cursor: pointer;
		 }
	}
	background: #F6F8FD;
	//border-bottom: 1px #408b8b solid;
	padding: 0 16px;
	.table-title {
		z-index: 7;
		display: block;
		overflow: hidden;
		padding-right: 21px;
		max-width: 100%;
		width: fit-content;
		color: #535c69;
		text-align: left;
		text-transform: uppercase;
		-ms-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		white-space: nowrap;
		letter-spacing: .5px;
		font-weight: 600;
		font-size: 11px;
		line-height: 12px;
	}
	#records {
		width: fit-content !important;
	}
	button {
		background: none;
		min-width: auto;
		margin: 0 8px;
	}
	.bulk-actions {
		display: flex;
		align-items: center;
		.bulk-action-btn {
			//margin: 4px 4px 0 4px;
			padding: 4px
			border-radius: 4px;
			display: flex;
			align-items: center;
			width: fit-content;
			transition: all 0.25s ease-in-out;
			background: #cecece;
			&:hover {
				background: #b3b3b3;
			}
		}
	}
}

/*
        modified by Vipin
        modified at 10/12/2022
        modification : added listcard1-pagination class for different pagiantion-block properties for new task view.
*/
/*
  modified by komal
  modified at 10/02/2023
  modification : Comment z-index bcoz it overlaps on case page
*/


.listcard1-pagination {

	.pagination-block {
	//background: #F6F8FD;
	//background: #e5dcd3;
	background: #fff;
	//border-bottom: 1px #408b8b solid;
	border-top: 2px solid #e1e1e1;
	//border-top: 2px solid #cecece;
	padding: 0 16px;
	left: 0;
	right: 0;
	bottom:0;
	//z-index:999;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	.table-title {
		z-index: 7;
		display: block;
		overflow: hidden;
		padding-right: 21px;
		max-width: 100%;
		width: fit-content;
		color: #535c69;
		text-align: left;
		text-transform: uppercase;
		-ms-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		white-space: nowrap;
		letter-spacing: .5px;
		font-weight: 600;
		font-size: 11px;
		line-height: 12px;
	}
	#records {
		width: fit-content !important;
	}
	button {
		background: none;
		min-width: auto;
		margin: 0 8px;
	}
	.bulk-actions {
		display: flex;
		align-items: center;

		.bulk-action-btn {
			//margin: 4px 4px 0 4px;
			padding: 4px
			border-radius: 4px;
			display: flex;
			align-items: center;
			width: fit-content;
			transition: all 0.25s ease-in-out;
			background: #cecece;
				position:absolute;
				bottom:8px;
			&:hover {
				background: #b3b3b3;
			}
		}
	}
}




}
