.search-input-suggestion-container {
  position: absolute;
  background: #fff;
  left: -259px;
  top: 8px;
  width: 300px;
  transition: 0.2s width ease;
  min-height: fit-content;
  max-height: 100vh;
  border-radius: 12px;
  /*
    Modification: Updated z-index of container from 3 to 9999
    By: Devang
    Date: 22/03/2023
  */
  z-index: 9999;

  .search-input-container {
    width: 100%;
    background #fff;
    border: 1px solid #002C5F;
    border-radius: 12px;
    padding: 5px 11px;
  }
  .search-input {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #002C5F !important;
    margin-bottom: 0px;
    margin: 0 11px 0 0;

    outline: none !important;
    border: none !important;

    &:hover, &:active, &:focus {
      outline: none !important;
      border: none !important;
    }
  }

  .search-suggestion-list-container {
    margin: 11px 0;
    .search-suggestion {
      padding: 9px 15px;

      &:hover {
        background: #eeeeee;
      }

      .search-suggestion-text {
        color: rgba(0, 0, 0, 0.75);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        margin-left: 10px;
      }
    }
  }
}

.search-suggestion-letter-icon {
  //color: #00D8AA;
  //border: 0.5px solid #00D8AA;
  color: #404040;
  border: 0.5px solid #404040;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
