.tasks-tags {
  display: inline-flex !important;
  //padding: 0px 10px;
  padding: 4px 10px;
  //min-width: 30vw;
  //border-radius: 30px;
  //background: #d9e1e433;
  //background: #bcedfc;
  color: rgba(108,109,138,0.8);
  border-radius: 5px;
  background: transparent;
  //width: 50vw;
  .-title {
    //margin-right: 1rem;
    padding: 10px;
  }

  .-count {
    padding: 3px 8px;
    border-radius: 10px;
    background: #f0f8ff;
    color: rgba(108,109,138,0.8);
    display: inline;
    font-size: 0.8rem;
    margin-right: 1px
  }
  .-text {
    margin-right: 5px
  }
  .-tile {
    padding: 10px 5px;
    cursor:pointer;
    margin: 0 5px;
    font-size: 12px;
    &:hover {
      background: #7bdaf780;
    }
  }
   .-tileworkspacedayplan {
    cursor:pointer;
    margin: 0 4px;
    padding-left: 8px;
    font-size: 12px;
    flex-direction:column;
    &:hover {
      background: #7bdaf780;
    }
  }
}
.yellow {
  background: #f8b936 !important;
  padding: 5px !important;
  margin: 0 !important;
}
{/*
    ModifiedBy: Vihang Kale
    Date: 24/12/2021
    Modification: On line 47, added red class for deadline day tags
*/}
.red {
  background: #d50000 !important;
  padding: 5px !important;
  margin: 0 !important;
}

.bg-sunset-orange-tag {
  background-color:#ed553b !important;
  padding: 5px !important;
  margin: 0 !important;
}


.hot {
  background: #b31414 !important;
  padding: 5px !important;
  margin: 0 !important;
}
.task-priority-high {
  height: 16px;
  display: inline-block !important;
  vertical-align: middle;
  margin: 0 5px 0 0 !important;
}
.task-priority-high:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 8px;
  background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%228%22%20height%3D%2211%22%20viewBox%3D%220%200%208%2011%22%3E%0A%20%20%3Cpath%20fill%3D%22%23F16C0A%22%20fill-rule%3D%22evenodd%22%20d%3D%22M200.069979%2C24%20L200.14%2C24%20C200.14%2C24%20203%2C23.0560625%20203%2C20.390625%20C203%2C16.6348125%20199.346667%2C15.8813125%20199.666667%2C13%20C197.993333%2C13.8339375%20195%2C16.75375%20195%2C19.875%20C195.039359%2C21.7259245%20196.174362%2C23.3629491%20197.86%2C24%20L197.86%2C24%20L198.123417%2C24%20C197.460595%2C23.3218266%20197.059336%2C22.4251727%20197%2C21.4707569%20C197.092476%2C20.0452868%20197.868205%2C18.7524059%20199.082454%2C18%20C200.109798%2C20.1851885%20201.164908%2C19.9436239%20201.164908%2C21.4707569%20C201.162991%2C22.4339232%20200.76293%2C23.3471724%20200.069979%2C24%20Z%22%20transform%3D%22translate%28-195%20-13%29%22/%3E%0A%3C/svg%3E%0A') center 0 no-repeat;
}

.task-priority-high-details:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  width: 38px;
  background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%228%22%20height%3D%2211%22%20viewBox%3D%220%200%208%2011%22%3E%0A%20%20%3Cpath%20fill%3D%22%23F16C0A%22%20fill-rule%3D%22evenodd%22%20d%3D%22M200.069979%2C24%20L200.14%2C24%20C200.14%2C24%20203%2C23.0560625%20203%2C20.390625%20C203%2C16.6348125%20199.346667%2C15.8813125%20199.666667%2C13%20C197.993333%2C13.8339375%20195%2C16.75375%20195%2C19.875%20C195.039359%2C21.7259245%20196.174362%2C23.3629491%20197.86%2C24%20L197.86%2C24%20L198.123417%2C24%20C197.460595%2C23.3218266%20197.059336%2C22.4251727%20197%2C21.4707569%20C197.092476%2C20.0452868%20197.868205%2C18.7524059%20199.082454%2C18%20C200.109798%2C20.1851885%20201.164908%2C19.9436239%20201.164908%2C21.4707569%20C201.162991%2C22.4339232%20200.76293%2C23.3471724%20200.069979%2C24%20Z%22%20transform%3D%22translate%28-195%20-13%29%22/%3E%0A%3C/svg%3E%0A') center 0 no-repeat;
}
.task-detail-header {
  padding: 11px 13px 12px 20px;
  border-bottom: 1px solid rgb(233, 234, 236);
  .if-no {
    display: inline-block;
  }
}
.task-info-panel-important {
  float: right;
}
.task-detail-subtitle-status {
  color: rgb(82, 92, 105);
  vertical-align: middle;
}
.task-detail-sidebar-status {
  background: rgb(86, 209, 224);
  padding: 7px 15px 8px;
  position: relative;
  margin: 0px;
  min-height: 39px;
}
.task-detail-sidebar-status-content {
  position: absolute;
  background: rgb(86, 209, 224);
  left: 0px;
  right: 0px;
  top: 50%;
  padding: 0px 15px;
  transform: translateY(-50%);
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.task-detail-sidebar-status-text {
  margin-right: 8px;
}

.task-detail-sidebar-status-date {
  font-weight: normal;
  white-space: nowrap;
  font-size: 0.95rem;
}
.task-detail-sidebar-item {
  padding: 15px 0px 17px;
  border-bottom: 1px solid rgb(238, 242, 244);
  overflow: hidden;
}
.task-detail-sidebar-item-title {
  font-size: 13px;
  color: rgb(133, 140, 150);
  width: 98px;
  float: left;
  overflow-wrap: break-word;
}
.task-detail-sidebar-item-value {
  color: rgb(0, 0, 0);
  margin-left: 100px;
  padding-left: 5px;
}
.task-user-selector {
  margin: 0px 10px;
}
.task-detail-sidebar-info-title {
  line-height: 32px;
  margin-top: 10px;
  color: rgb(82, 92, 105);
  border-bottom: 1px solid rgb(237, 238, 240);
}
.task-detail-sidebar-info-user {
  position: relative;
  padding: 8px 0px;
  overflow: hidden;
}
.task-detail-sidebar-info-user-photo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 12px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0Ny4xODgiIGhlaWdodD0iNTQuMDYzIiB2aWV3Qm94PSIwIDAgNDcuMTg4IDU0LjA2MyI+PGRlZnM+PHN0eWxlPi5jbHMtMSB7IGZpbGw6ICNmZmY7IGZpbGwtcnVsZTogZXZlbm9kZDsgfTwvc3R5bGU+PC9kZWZzPjxwYXRoIGlkPSJTaGFwZV8yX2NvcHlfNCIgZGF0YS1uYW1lPSJTaGFwZSAyIGNvcHkgNCIgY2xhc3M9ImNscy0xIiBkPSJNNDcuMTggNTQuMDYyYzAtMy4yMTctMy42MS0xNi44MjYtMy42MS0xNi44MjYgMC0xLjk5LTIuNi00LjI2LTcuNzItNS41ODVhMTcuMzk0IDE3LjM5NCAwIDAgMS00Ljg4Ny0yLjIyM2MtLjMzLS4xODgtLjI4LTEuOTI1LS4yOC0xLjkyNWwtMS42NDgtLjI1YzAtLjE0Mi0uMTQtMi4yMjUtLjE0LTIuMjI1IDEuOTcyLS42NjMgMS43Ny00LjU3NCAxLjc3LTQuNTc0IDEuMjUyLjY5NSAyLjA2OC0yLjQgMi4wNjgtMi40IDEuNDgyLTQuMy0uNzM4LTQuMDQtLjczOC00LjA0YTI3LjA1IDI3LjA1IDAgMCAwIDAtNy45MThjLS45ODctOC43MDgtMTUuODQ3LTYuMzQ0LTE0LjA4NS0zLjUtNC4zNDMtLjgtMy4zNTIgOS4wODItMy4zNTIgOS4wODJsLjk0MiAyLjU2Yy0xLjg1IDEuMi0uNTY0IDIuNjUtLjUgNC4zMi4wOSAyLjQ2NiAxLjYgMS45NTUgMS42IDEuOTU1LjA5MyA0LjA3IDIuMSA0LjYgMi4xIDQuNi4zNzcgMi41NTYuMTQyIDIuMTIuMTQyIDIuMTJsLTEuNzg2LjIxN2E3LjEgNy4xIDAgMCAxLS4xNCAxLjczMmMtMi4xLjkzNi0yLjU1MyAxLjQ4NS00LjY0IDIuNC00LjAzMiAxLjc2Ny04LjQxNCA0LjA2NS05LjE5MyA3LjE2Uy0uMDEyIDU0LjA2LS4wMTIgNTQuMDZoNDcuMTl6Ii8+PC9zdmc+');
  background-color: rgb(83, 92, 106);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center center;
}
.task-detail-sidebar-info-user-title {
  overflow: hidden;
  overflow-wrap: break-word;
}

@media screen and (max-width: 480px) {
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 7px;
    background-color: rgba(0,0,0,0);
  }
  .lead-modal-container {
    .lead-modal {
      .lead-header-container {
        .lead-header {
          .header-right-buttons {
            margin: 0 !important;
          }
        }
      }
    }
  }

  .tasks-tags {
    font-size: 0.75rem;
    .-tile {
      text-align: center;
    }
    .-text {
      display: inline-block;
      font-size: 0.7rem !important;
      margin: 0;
      margin-top:5px;
    }
  }

}
.task-details-section-1 {
  //background-color: #a0a4af;
  border-right: 1px solid #ffffff;
  background-color: #fff;
}

.task-details-section-2 {
  //background-color: #a0a4af;
  padding: 0;
  background-color: #fff;
}

@media only screen and (max-width: 47em) {
  .task-details-section-1 {
    border-right: 0px solid #ffffff;
  }
  .task-details-section-2 {
    margin-bottom: 60px;
    padding-bottom: 20px;
  }
}
