.performance-modal-container {
  background-color: #f0f8ff;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  height: 100vh !important;
  width: auto !important;
  margin: 0 !important;
  min-width: auto !important;
  min-height: 100% !important;
  overflow: auto;

  .performance-modal {
    flex: 1;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .performance-header-container {
    min-height: 50px;
    box-sizing: border-box;
    position: relative;
  }

  .performance-sub-header-container {
    box-sizing: border-box;
    position: relative;
  }

	.performance-content-container {
		min-height: calc(100vh - 75px);
    background: #fff;
    overflow: hidden;
  }

  .warning-box {
    position: absolute;
    top: 0px;
    background: #ff7f00;
    border: 1px solid #d2691e;
    color: black;
    padding: 8px;
  }
}
