/* The Modal (background) */
.global-modal-container {
  .global-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    //padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: #eee;/* Fallback color */
  }

  /* Modal Content */
   .global-width {
      width: 62%;
   }
   .global-full-width {
      width: 98%;
   }

  .global-modal-content, .global-modal-content-task {
    //position: relative;
    //position: absolute;
    //top: 50%;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    position: relative;
    top: 0px;
    left: 0px;
    background-color: #fefefe;
    margin: auto;
    //padding: 0;
    //padding: 30px 30px;
    border: 1px solid #888;
    //width: 40%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    //-webkit-animation-name: animatetop;
    //-webkit-animation-duration: 0.4s;
    //animation-name: animatetop;
    //animation-duration: 0.4s;
    border-radius: 12px;
    .column-first {
      border-right: 1px solid #efeef0;
      height: 60px;
      border-bottom: 1px solid #efeef0;
    }
    .column-last {
      //border-left: 1px solid #efeef0;
      height: 60px;
      border-bottom: 1px solid #efeef0;
    }
    .form-column {
      border-right: 1px solid #efeef0;
    }
    .task-column {
      background: #fbfbfb;
      border-radius: 0 0 10px;
      display: flex;
      flex-direction: column;
      contain: layout paint style;
      //height: 74vh;
      border-left: 1px solid #efeef0;
      position:relative;
      z-index:1;
    }
    .task-history-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .task-history-item__content {
      word-break: break-word;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -webkit-hyphens: auto;
      hyphens: auto;
      flex-grow: 1;
      font-size: 13px;
      line-height: 140%;
      color: #34343466;
      max-width: calc(100% - 75px);
      min-width: 0;
    }
    .task-history-item__content>span.task-history-item__name_btn {
      margin-right: 0;
    }
    .task-history-item__name_btn {
      transition: background .2s cubic-bezier(.785,.135,.15,.86) 0s;
      border-radius: 3px;
      padding: 4px 0 4px 4px;
      margin: -4px 0 -4px -4px;
    }
    .task-history-item__name_btn-hover:hover {
      background-color: #f2f2f2;
    }
    .task-history-item__name {
      font-size: 13px;
      font-weight: 400;
      color: #544d61;
      line-height: 140%;
      flex-shrink: 0;
    }
    .task-history-item__content>span {
      color: inherit;
      margin-right: 4px;
    }
    .task-history-item__date {
      font-size: 11px;
      font-weight: 400;
      flex-shrink: 0;
      margin: 0 0 0 10px;
      padding: 4px 0 0;
      color: #abaeb0;
    }
    .task-footer {
      border-top: 1px solid #efeef0;
      height: 50px;
      position: absolute;
      width: 100%;
      overflow: hidden;
      bottom: 0;
    }
  }

  .global-modal-content-task {
    position: relative;
    transform: translate(0%, 0%);
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .global-modal-content-close {
    //-webkit-animation-name: animatebottom;
    //-webkit-animation-duration: 0.4s;
    //animation-name: animatebottom;
    //animation-duration: 0.4s
  }
  .show-formPopover {
    display:block !important;
  }

  .hide-formPopover {
    display:none !important;
  }

  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  /* Add Animation */
@-webkit-keyframes animatebottom {
  from {top:0; opacity:1}
  to {top:-300px; opacity:0}
}

@keyframes animatebottom {
  from {top:0px; opacity:1}
  to {top:-300px; opacity:0}
}

  /* The Close Button */
  .global-close {
    float: right;
    font-size: 26px;
    margin-top: -48px;
  }

  .global-close:hover,
  .global-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    //transform: rotate(90deg);
  }



  .global-modal-header {
    background-color: #E5DCD3;
    width: 100%;
    position: relative;
    border-radius: 12px 12px 0 0;
    height: 48px;
    min-height: 48px;
    align-items: center;
    //border-bottom: 1px solid #dcdfe4;
    -o-text-overflow: ellipsis;

    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 13px 20px;
    .text {
      background: #fff;
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
      color: #72828d;
    }
  }

  .global-modal-body {
    padding: 10px;
    //background-color: #eef2f4;
    //margin: 0px 10px;
    }

  .global-modal-footer {
    padding: 2px 16px;
    //background-color: #5cb85c;
    //color: white;
  }
  label {
    font-size: 0.9rem;
    font-weight: 600;
  }


/* Modified By: Vihang
Modified On:23 - 25 May 2022
Modification: all the forms modal mobile responsive changes
*/



  @media (max-width: 480px) {
  .flex-row-mobile {
    -ms-flex-direction: column;
    flex-direction: column;
  }
   .formModal {
      width:100% !important;
      height:100% !important;
      .right-drawer-label{
        top: 0;
        left: 0;
        transform: rotate(180deg);
      }
   }
    .enquiryForm {
      .formHeader , input , select {
        font-size:14px;
      }
      .formLabel {
        font-size:12px !important;
      }
      /*
        BY: Prasannadatta Kawadkar
        ON: 27 March 2024
        Modification: corrected the fontSize for mobile views
      */
      label {
        font-size: 13px !important;
        padding:0 5px;
      }
      input {
        margin:0;
      }
      input, select {
        height:40px;
      }
      input[type='month'], select {
        width:100%;
        border: 0.1rem solid #d1d1d1;
        border-radius: 0.4rem;
      }
      input[type='date'], input[type='time'] {
        width:50% !important;
      }
      .formRadioButtons, .formCheckbox {
          flex-direction:column;
      }
      .qty-input {
        input, button {
            width:2rem;
            height:2rem;
        }
        display:flex;
        align-items:center
      }
      .carCart {
        .viewCart {
          p {
            display:none;
          }
        }
      }
      .quotationCarSummary {
        font-size:12px;
        button {
          font-size:12px;
        }
        .whatsAppButton {
          padding:0;
        }
        .waitingPeriodText, .financeText {
          border-left:none !important;
          padding-left:0;
        }
        .lastGeneratedText {
          border-left:none !important;
        }
      }
    }
  }
  label {
    font-size: 0.9rem;
    font-weight: 600;
  }
  .global-buttons {
    text-align: center;
    padding: 20px 0 10px;
    position: relative;
  }
  .global-button-accept, .global-button-create {
    background: #bbed21 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #535c69;
  }
  .global-button {
    display: inline-block;
    height: 39px;
    line-height: 39px;
    margin: 0 12px 0 0;
    background: #ecedef;
    border-radius: 2px;
    cursor: pointer;
    color: #7a818a;
    font-size: 12px;
    font-weight: normal;
    outline: 0;
    padding: 0 18px;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    white-space: nowrap;
    -webkit-box-shadow: 0 0 0 1px #c6cdd3 inset;
    box-shadow: 0 0 0 1px #c6cdd3 inset;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: background-color .2s linear,color .2s linear;
    -o-transition: background-color .2s linear,color .2s linear;
    transition: background-color .2s linear,color .2s linear;
  }
  .global-button:last-child {
    margin-right: 0;
  }
  .global-button-link-cancel {
    color: #f1361b;
    border-bottom-color: #ffb4a9;
    background: #fff;
    border: 0 !important;
    box-shadow: none !important;
  }
  .global-button-link {
    border-bottom: 1px solid #c0c2c5;
    border-radius: 0;
    color: #535c69;
    cursor: pointer;
    height: 17px;
    line-height: 17px;
    -webkit-transition: border-bottom-color .15s linear;
    -o-transition: border-bottom-color .15s linear;
    transition: border-bottom-color .15s linear;
    padding: 0;
  }


  /*
        modified by Vihang
        modified at 26/05/2022
        modification : scroll button adjustment for desktop and inline styles added to the

  */
  .formModalContainer {
      position:relative;
      padding-left:30px;
      padding-right:30px;
      padding-top:10px;
      padding-bottom:10px;
      border-top:1px solid lightgrey;
      overflow:hidden auto;
      //height:75vh;
      height:72vh;
      //box-shadow: 0px -2px 3px 0px rgb(133 133 133 / 10%) inset;
  }

  .scrollButton {
    //bottom:120px;
    //bottom:35px;
    bottom: 60px;
    right:5px;
  }


  /* Modified By: Vihang
  Modified On: 24 May 2022
  Modification: all the forms modal mobile responsive changes
  */

  .formModal {
      .enquiryForm {
        select,input {
          height:40px;
        }
      }
  }

  /* Modified By: Manohar
     Modification On: 15-07-2022
     Modification: #1165 Bug | Quotation Task selected model cost strip overlaps Next and Previous button
  */
  /*
    Bug: T1984 - BG_FN: Accessories fitting task could not be completed.
    Modification: Updated padding and left-right
    By: Devang
    Date: 25/07/2024
  */
  .carCart {
    position: absolute;
    top: 0;
    background: rgb(247 247 247);
    //padding: 15px;
    padding: 8px;
    color: #828282;
    width: 60%;
    margin: 0 auto;
    //left: 30%;
    right: 0;

    .viewCart {
      font-size:15px;
      font-weight:500;
      margin-right:5px;
      display:flex;
      align-items:center
    }
    .carCost {
      font-size:15px;
      font-weight:500;
      margin-right:5px;
    }
    .cartValue {
      font-size:20px;
      font-weight:500;
      margin-right:5px;
      margin-left:5px;
    }
    .selectedText {
      font-size:15px;
      font-weight:500;
      margin-right:5px;
    }
    .selectedCarCount {
      font-size:20px;
      font-weight:500;
      margin-right:5px;
    }
  }
  .noteDiv {
    position: absolute;
    bottom: 33px;
    width: 100%;
    background: rgb(247 247 247);
    padding: 15px;
    color: #808080;
  }

  .assigned-user {
    position: relative;
    .cross {
      position: absolute;
    }

    .grouped-item-info-title-pop-up {
      display: none;
    }

    &:hover > .grouped-item-info-title-pop-up {
      display: flex;
      opacity: 0;
      animation: 0.5s ease-out 0.5s 1 growOutUserInfoTitle forwards;
    }

    &:hover {
      .cross {
        cursor: pointer;
        height: 16px;
        width: 16px;
        right: -8px;
        top: -6px;
        background-color: white;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 99px;
        z-index: 1;
        &:hover {
          background-color: #ede4e4;
        }
      }
    }
  }
}
/*
      modified by Vihang
      modified at 26/05/2022
      modification : scroll button adjustment for mobile
*/
@media screen and (max-width: 550px) {
  .global-width {
     width: 90% !important;
   }
   .global-product {
      padding: 10px 30px 10px 10px !important;
   }
   .global-modal-container {
     .scrollButton {
         bottom:30px
     }
   }
}

/*
      modified by Vipin
      modified at 10/11/2022
      modification : Added mobile view specific classes to the globalmodal's header.
*/
/*
      modified by Komal
      modified at 29/04/2023
      modification : Added mobile view specific classes to the globalmodal's header.
*/
@media screen and (max-width: 480px) {
  .mobile-position {
      padding-inline:0;
    }
  .mobile-global-close {
    margin-top:0px !important
    }
  .mobile-header {
      display:flex;
      gap: 1.5rem;
      padding-left:8px !important;
    }

  .mobile-padding {
      padding-top:8px;
      padding-left:1rem;
    }

  .mobile-formModalContainer {
      z-index:1 !important;
      height:100vh !important;
      padding-top:0 !important;
    }

  .mobile-formModalContainer .input-box input{
      width:100% !important;
    }

  .task-column {
      margin-top:1rem;
     }
  .mobile-task-column {

      position:fixed !important;
      top:78px;
      padding-top:0 !important;
    }
    /*
      Modification: change height 50px to 48px
      Developer: Masum Raja
      Date: 08/11/2023
    */
  .mobile-grid {
      //display:grid;
      //grid-template-columns: 89vw;
    }
    .global-modal-header-cases {
      background-color: #E5DCD3;
      position: relative;
      height: 48px;
      min-height: 48px;
      align-items: center;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 13px 20px;
      padding-left:8px;
      display:flex;
      .text {
        background: #fff;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        color: #72828d;
      }
    }
}

.global-close-2 {
  margin-top: -2px;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid red;
  margin-right: -4px;
  font-size: 16px;
  color: red;
  cursor: pointer;
}

/*
  Modification: Header color and style should be same as global modal
  Developer: Manohar sule
  Date: 21/08/2023
*/
.global-close-2-x {
  margin-top: -2px;
  margin-left: 4px;
  font-size: 17px;
  position: absolute;
}

.globalModal-btn-active {
   position: absolute;
   height: 28px;
   //background: #FFFFFF;
   //border: 1px solid #FF7A00;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   color:#FF7A00;
   top:-14px;
   padding: 4px 10px;
}

.globalModal-btn {
   position: absolute;
   height: 28px;
   //background: #FFFFFF;
   //border: 1px solid #002C5F;
   border-radius: 16px;
   color:#002C5F;
   top:-14px;
   padding: 4px 10px;
}

.globalModal-btn-container {
  position: absolute;
  left: 24px;
  display: flex;
  top: -14px;
  gap: 10px;
  z-index: 1;

  .globalModal-btn-sm-active {
     //position: absolute;
     height: 28px;
     //background: #FFFFFF;
     //border: 1px solid #FF7A00;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 16px;
     color:#FF7A00;
     top:-14px;
     padding: 4px 4px;
     font-size: 9px;
     letter-spacing: 0.5px;
     //background-color:#526F95 !important;
  }

  .globalModal-btn-sm {
     //position: absolute;
     height: 28px;
     //background: #FFFFFF;
     //border: 1px solid #002C5F;
     border-radius: 16px;
     color:#002C5F;
     top:-14px;
     padding: 4px 4px;
     font-size: 9px;
     letter-spacing: 0.5px;
     //background-color:#95B6A8 !important;
  }
}
.wizardDropDown-off {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.1s transform ease-in !important;
  padding: 4px 10px !important;
  position: absolute;
  z-index: 2;
  width: 132px;
  height: 156px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  border-radius: 5px;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;

}
/*
  BY: Prasannadatta Kawadkar
  ON: 26 March 2024
  Modification: changed width
*/
.wizardDropDown-on {
   transform: scaleY(1);
   transform-origin: top;
   transition: 0.2s transform ease-out !important;
   background-color: #fff !important;
   padding: 4px 10px !important;
   position: absolute;
   z-index: 2;
   width: 162px;
   height: 156px;
   background: #FFFFFF;
   border: 1px solid #BFBFBF;
   border-radius: 5px;
   right: 0;
   top: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: start;
   gap: 10px;

}

.wizard-navbar {
  position: absolute;
  top: 5px;
  right: 37px;
  width: 45%;
}
/*
  created by: Komal Wable
  created on: 25/04/2023
  Description: add css for media
*/
@media (max-width:425px){
  .wizard-navbar {
    position: absolute;
    top: 5px;
    right: 25px;
    width: 34%;
  }
  .global-modal-container {
    .global-modal-content, .global-modal-content-task {
      //position: relative;
      height:100vh !important;
      width:100% !important;
      position: absolute;
      //top: 46%;
      top: 0;
      left: 0;
      transform: none !important;
      background-color: #fefefe;
      margin: auto;
      //padding: 0;
      //padding: 30px 30px;
      border: 1px solid #888;
      //width: 40%;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      //-webkit-animation-name: animatetop;
      //-webkit-animation-duration: 0.4s;
      //animation-name: animatetop;
      //animation-duration: 0.4s;
      border-radius: 12px;
      .column-first {
        border-right: 1px solid #efeef0;
        height: 60px;
        border-bottom: 1px solid #efeef0;
      }
      .column-last {
        //border-left: 1px solid #efeef0;
        height: 60px;
        border-bottom: 1px solid #efeef0;
      }
      .form-column {
        border-right: 1px solid #efeef0;
      }
      .task-column {
        background: #fbfbfb;
        border-radius: 0 0 10px;
        display: flex;
        flex-direction: column;
        contain: layout paint style;
        //height: 74vh;
        border-left: 1px solid #efeef0;
        position:relative;
        z-index:1;
      }
      .task-history-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      .task-history-item__content {
        word-break: break-word;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        hyphens: auto;
        flex-grow: 1;
        font-size: 13px;
        line-height: 140%;
        color: #34343466;
        max-width: calc(100% - 75px);
        min-width: 0;
      }
      .task-history-item__content>span.task-history-item__name_btn {
        margin-right: 0;
      }
      .task-history-item__name_btn {
        transition: background .2s cubic-bezier(.785,.135,.15,.86) 0s;
        border-radius: 3px;
        padding: 4px 0 4px 4px;
        margin: -4px 0 -4px -4px;
      }
      .task-history-item__name_btn-hover:hover {
        background-color: #f2f2f2;
      }
      .task-history-item__name {
        font-size: 13px;
        font-weight: 400;
        color: #544d61;
        line-height: 140%;
        flex-shrink: 0;
      }
      .task-history-item__content>span {
        color: inherit;
        margin-right: 4px;
      }
      .task-history-item__date {
        font-size: 11px;
        font-weight: 400;
        flex-shrink: 0;
        margin: 0 0 0 10px;
        padding: 4px 0 0;
        color: #abaeb0;
      }
      .task-footer {
        border-top: 1px solid #efeef0;
        height: 50px;
        position: absolute;
        width: 100%;
        overflow: hidden;
        bottom: 0;
      }
    }}
}

/*
      modified by Masum
      modified at 14/08/2023
      modification : Added two class for water mark
*/

.WatermarkContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 999;
}

.WatermarkText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-size: 45px;
  color: gray;
  font-weight: 500;
}

/*
  BY: Prasannadatta Kawadkar
  ON: 26 March 2024
  Modification: added class
*/
.visitmodalslide {
  z-index: 1020;
  position: relative;
}
