/*
  modified by Komal
  modified at 15 April 2023
  modification: Add Calculator CSS
*/

.css-h2okw {
  height: 57vh !important;
  display: block;
}
.css-hayew9 {
	height: 16% !important;
	font-size: 6vmin !important;
	margin-bottom: 1px !important;
}
.css-1lqzgzw {
	height: 87% !important;
	margin: 10px !important;
  margin-top: 0 !important;
}
.calculator-modal-content {
	padding: 0px !important;
	width: 24% !important;
	bottom: 0;
	right: 52px;
	background-color: #fefefe;
	position: absolute;
	border: 1px solid #e2e2e2;
	border-radius: 12px;
}
.calculator-modal-body {
	height: 45vh;
	overflow: hidden;
	display: flex;
	justify-content: center;
	margin: 0px;
	width: 100%;
	padding: 0px !important;
}
.css-1txu9g8 {
  background-color: #434343 !important;
  color: white !important;
  width: 25% !important;
  height: 12% !important;
  font-size: 4vmin !important;
  border: 1px solid black !important;
}
.css-9iwzto {
  background-color: #4d97c4 !important;
  color: white !important;
  width: 25% !important;
  height: 12% !important;
  font-size: 4vmin !important;
  border: 1px solid black !important;
}
.css-1iypx3a {
  background-color: #5d5e5e !important;
  color: white !important;
  width: 25% !important;
  height: 12% !important;
  font-size: 4vmin !important;
  border: 1px solid black !important;
}
.css-166hzw5 {
  background-color: #5d5e5e !important;
  color: white !important;
  width: 50% !important;
  height: 12% !important;
  font-size: 4vmin !important;
  border: 1px solid black !important;
  padding-left: 12% !important;
  text-align: left !important;
}
.css-1uzr0i {
  background-color: #f2a23c !important;
  color: white !important;
  width: 25% !important;
  height: 12% !important;
  font-size: 4vmin !important;
  border: 2px solid black !important;
}
.drag-drop-floating-calculator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  border-radius: 25px;
  z-index: 9999;
  position: fixed;
  cursor: pointer;
  right: 0px !important;
  bottom: 10px !important;
}
