{/*
  Bug: T1847 - BG_UI: List View - Stage Action - Extra space is displayed in Action list
  Modification: Add max-height for dropdown-menu.
  By: Arun Singh
  Date: 28/05/2024
*/}
.dropdown-menu-action {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 9999;
  max-height: 350px;
  overflow: auto;
  max-width: 250px;
  border-radius:5px;
}

.dropdown-menu-action li {
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background: #e2e2e2
	}
}
/*
  Bug: T1687 - 46 - List view - Columns Columns width should be auto-adjust.
  Modification: Commented old height and added fit-content instead
  By: Devang
  Date: 13/04/2024
*/
.activeActionIcon {
  //height: 23px;
  height: fit-content;
  margin: 0;
  background: #e2e2e2;
}
.actionIcon {
  //height: 23px;
  height: fit-content;
  margin: 0;
}
