#progress {
  padding: 0;
  list-style-type: none;
  font-size: 12px;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
  text-align: center;
}

#progress li {
  float: left;
  padding: 10px 30px 10px 40px;
  background: #d5caea;
  color: #000;
  position: relative;
  border-top: 1px solid #d5caea;
  border-bottom: 1px solid #d5caea;
  width: auto;
  margin: 0 1px;
}

#progress li:before {
  content: '';
  border-left: 16px solid #fff;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 0;

}
#progress li:after {
  content: '';
  border-left: 16px solid #d5caea;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

#progress li.active {
  background: #2cb22c;
  color: #fff;
}

#progress li.active:after {
  border-left-color: #2cb22c;
}

progress {
  background-color: #e2e2e2;
  border: 0;
  height: 5px;
  border-radius: 9px;
  width: 100%;
  margin-right:10px;
}

progress::-webkit-progress-bar {
	background-color: #e2e2e2;
	border-radius: 9px;
}

progress::-webkit-progress-value {
	background: #2c2d96;
}

.progressbar-row {
	height: 100%;
  display: flex;
  align-content: space-between;
  padding-left: 0.5rem;
	justify-content: center;

	span {
		color: #444;
		font-size: 16px;
    font-weight: 400;
	}
}

.progressbar {
  border-radius: 10px;
  background: #219ebc;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
	margin: 0;
  display: flex;
  height: 10px;
  width: 100%;
}

.progressbar-value {
  //animation: load 3s normal forwards;
  -webkit-transition: width 0.5s linear;
  -moz-transition: width 0.5s linear;
  transition: width 0.5s linear;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 2px;
  background: #64b2d1;
  height: 8px
  width: 0;
  height: 5px;
  border-radius: 3px;
  background: #69ACAC;
}

.progressbar-percentage {
	position: absolute;
  right: 10px;
	font-weight: 600;
	font-size: 11px;
	color: var(--text-white);
}

@keyframes load {
  0% { width: 0; }
  100% { width: 68%; }
}
