.accountability-priority-toggler {
  width: 16px;
  border: 1px solid rgba(0,44,95,0.25);
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8a67b;

  .not-priority {
    display: none;
  }

  &:hover {
    .not-priority {
      display: block;
    }
  }
}
.accountability-list-custom-priority-toggler {
  height: 100%;
  top: 0px;
  left: 0;
  position: absolute;
}
