.semi-info-detail-card {
  min-height: 50px;
  background: #fff;
  border: 1px solid #ededed;

  &:hover {
    box-shadow: 0 3px 5px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
  }
}

.semi-info-detail-card-no-hover {
  min-height: 50px;
  background: #fff;
  border: 1px solid #ededed;
}

.semiInfoDetailCard-icon-container {
  display:flex;
  position:absolute;
  //bottom:10px;
  /*
    Bug: T1687 21. Add New Case Modal - Customer Name is overlapped with icons when added a new case
    22. Add New Case Modal - Cross icon alignment is not correct
    Modification: Commented top and added bottom
    By: Devang
    Date: 09/04/2024
  */
  //top: 14px;
  bottom: 4px;
  right:10px;
  gap:8px;

  .semiInfoDetailCard-icon  {
    width: 45px;
    height: 50px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  .semiInfoDetailCard-icon-single  {
    width: 44px;
    height: 44px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
}

.semiInfoDetailCard-icon-container-sm {
  display:flex;
  position:absolute;
  //bottom:10px;
  /*
    Bug: 10 KH_BG_80 All Roles All Modules Card Ribbon, Case name Card ribbon and case name are overlapped on the "Type of Case" icons
    Modification: updated top property to 18
    By: Devang
    Date: 06/07/2023
  */
  //top: 14px;
  /*
    Bug: T1687 21. Add New Case Modal - Customer Name is overlapped with icons when added a new case
    22. Add New Case Modal - Cross icon alignment is not correct
    Modification: Commented top and added bottom
    By: Devang
    Date: 09/04/2024
  */
  //top: 18px;
  bottom: 14px;
  right:8px;
  gap:8px;

  .semiInfoDetailCard-icon  {
    width: 32px;
    height: 32px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    justify-content: flex-start !important;
  }
  p {
    display: none;
  }
}

.semiInfo-userDetails-container {
  border-left: 1px solid #e1e1e1;
  width: 150px;
}
